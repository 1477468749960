import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Textfield from "../../Components/Textfield";
import SimpleDropdown from "../../Components/SimpleDropdown";
import { Switch } from "antd";
import { useFormik } from "formik";
import Commonservices from "../../service/Commonservices";
import { useDispatch, useSelector } from "react-redux";
import mysqlservice from "../../service/mySqlservice";
import { toast } from "react-toastify";

const RemarksDrawer = ({
  open,
  handledrawerClose,
  handleStudentData,
  selectedStudent,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      remarks: "",
    },
    onSubmit: (value) => {
      handleRemark();
    },
  });

  const dispatch = useDispatch();

  const handleRemark = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        id: selectedStudent,
        remarks: formik.values.remarks,
      };
      let apiResult = await mysqlservice.post("add_remark", req);
      // console.log(req);
      await handleStudentData();
      handledrawerClose();
      formik.resetForm();
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Drawer
      open={open}
      onClose={handledrawerClose}
      anchor="right"
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: { xs: "100%", md: "500px" },
          borderRadius: 0,
          backgroundColor: "#EFEFEF",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        {/* header */}
        <Box
          sx={{
            p: 2,
            borderBottom: "1px solid #ddd",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Add Remarks
            </Typography>
          </Box>
          <IconButton sx={{ ml: "auto" }} onClick={handledrawerClose}>
            <CloseIcon sx={{ height: "28px", width: "28px" }} />
          </IconButton>
        </Box>
        {/* content */}
        <Box
          sx={{
            flex: 1,
            p: 2,
            overflowY: "auto",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <Box display={"flex"} flexDirection={"column"}>
            <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
              Remarks
            </Typography>
            <textarea
              cols="30"
              rows="10"
              style={{
                outline: 0,
                borderColor: "#DFDFDF",
                padding: 5,
              }}
              value={formik.values.remarks}
              onChange={(e) => {
                formik.setFieldValue("remarks", e.target.value);
              }}
            ></textarea>
          </Box>
        </Box>
        {/* footer */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "auto",
            width: "100%",
          }}
        >
          <Divider />
          <Box p={2} display="flex" justifyContent="end" gap={2}>
            <Button sx={{ color: "#3BA5B9" }} onClick={handledrawerClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#3BA5B9", color: "#fff" }}
              onClick={() => formik.handleSubmit()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default RemarksDrawer;
