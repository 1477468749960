import {
  Box,
  Button,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrums from "../../Components/Breadcrums";
import Datefeild from "../../Components/Datefeild";
import SimpleDropdown from "../../Components/SimpleDropdown";
import Commonservices from "../../service/Commonservices";
import { useDispatch, useSelector } from "react-redux";
import mysqlservice from "../../service/mySqlservice";
import { useFormik } from "formik";
import dayjs from "dayjs";
import moment from "moment";

const SectionWiseReport = () => {
  useEffect(() => {
    handleSectionData();
  }, []);

  const userData = useSelector((state) => state.user);

  const [sectionData, setSectionData] = useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      from: "",
      to: "",
      section: "",
    },
    onSubmit: (value) => {
      handleSectionData();
    },
  });

  const dispatch = useDispatch();
  const theme = useTheme();

  const handleSectionData = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        institution_id: userData.institution_id,
        academic_year: userData.academic_year,
        section: formik.values.section,
        from: formik.values.from,
        to: formik.values.to,
      };

      let apiResult = await mysqlservice.post("get_section_visit_details", req);
      setSectionData(apiResult.data);
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handlefromDate = (date, dateString) => {
    if (date) {
      let newDate = dayjs(date);
      formik.setFieldValue("from", newDate);
    } else {
      formik.setFieldValue("from", "");
    }
  };

  const handletoDate = (date, dateString) => {
    if (date) {
      let newDate = dayjs(date);
      formik.setFieldValue("to", newDate);
    } else {
      formik.setFieldValue("to", "");
    }
  };

  const totals = sectionData.reduce(
    (acc, item) => {
      acc.totalInfectious += item.disease.infectious;
      acc.totalNonInfectious += item.disease.non_infectious;
      acc.totalBlunt += item.injury.blunt;
      acc.totalOpen += item.injury.open;
      acc.totalFainting += item.fainting;
      acc.alltotal += item.total;
      acc.refertohospitaltotal += item.referred_to_hospital;
      acc.othertotal += item.sent_to_isolation_or_home;
      return acc;
    },
    {
      totalInfectious: 0,
      totalNonInfectious: 0,
      totalBlunt: 0,
      totalOpen: 0,
      totalFainting: 0,
      alltotal: 0,
      refertohospitaltotal: 0,
      othertotal: 0,
    }
  );

  const sections = [
    { id: 1, value: "", name: "None" },
    { id: 2, value: "KG 1 - GRD 2", name: "KG 1 - GRD 2" },
    { id: 3, value: "GRD 3 - GRD 8 [AN]", name: "GRD 3 - GRD 8 [AN]" },
    { id: 4, value: "GRD 3 - GRD 8 [FN]", name: "GRD 3 - GRD 8 [FN]" },
    { id: 5, value: "GRD 9 - GRD 12 [AN]", name: "GRD 9 - GRD 12 [AN]" },
    { id: 6, value: "GRD 9 - GRD 12 [FN]", name: "GRD 9 - GRD 12 [FN]" },
  ];

  return (
    <>
      <Breadcrums
        title={[
          {
            title: "SECTIONWISE CLINIC VISIT REPORT",
            icon: "",
            link: true,
            // path: "/",
          },
        ]}
      />
      <Box sx={{ p: 2 }}>
        <Card sx={{ p: 2 }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            flexWrap={"wrap"}
            gap={{ xs: 0.5, xl: 2 }}
          >
            <Datefeild
              // label={"Date"}
              placeholder={"From"}
              style={{ minWidth: "126px" }}
              value={formik.values.from}
              onChange={handlefromDate}
            />

            <Datefeild
              // label={"Date"}
              placeholder={"To"}
              style={{ minWidth: "126px" }}
              value={formik.values.to}
              onChange={handletoDate}
            />

            <SimpleDropdown
              label={"Section"}
              style={{ minWidth: "126px" }}
              value={formik.values.section}
              menuitem={sections}
              onChange={(e) => {
                formik.setFieldValue("section", e.target.value);
              }}
            />

            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#3BA5B9",
                color: "#fff",
                fontSize: "14px",
                fontWeight: 500,
                textTransform: "capitalize",
                mt: "2px",
                // height: "38px",
              }}
              onClick={() => formik.handleSubmit()}
            >
              Apply
            </Button>

            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                ml: { xs: "", sm: "auto" },
                gap: 1,
              }}
            >
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#3BA5B9",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: 500,
                  textTransform: "uppercase",
                }}
              >
                Generate
              </Button>
            </Box> */}
          </Box>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow
                  sx={
                    theme.palette.mode == "dark"
                      ? { backgroundColor: "#212529" }
                      : { backgroundColor: "#F3F6F9" }
                  }
                >
                  <TableCell
                    align="center"
                    rowSpan={2}
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    SECTION
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={2}
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    DISEASE
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={2}
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    INJURY
                  </TableCell>
                  <TableCell
                    align="center"
                    rowSpan={2}
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    FAINTING
                  </TableCell>
                  <TableCell
                    align="center"
                    rowSpan={2}
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    TOTAL
                  </TableCell>
                  <TableCell
                    align="center"
                    rowSpan={2}
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    RFFERED TO HOSPITAL
                  </TableCell>
                  <TableCell
                    align="center"
                    rowSpan={2}
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    SENT TO ISOLATION ROOM / SENT HOME
                  </TableCell>
                  <TableCell
                    align="center"
                    rowSpan={2}
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    REMARK / NOTES
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={
                    theme.palette.mode == "dark"
                      ? { backgroundColor: "#212529" }
                      : { backgroundColor: "#F3F6F9" }
                  }
                >
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    Infectious
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    Non Infectious
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    Blunt
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    Open
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sectionData && sectionData.length
                  ? sectionData.map((val, index) => (
                      <TableRow key={index}>
                        <TableCell align="center" sx={{ color: "#915ECC" }}>
                          {val.section}
                        </TableCell>
                        <TableCell align="center">
                          {val.disease.infectious}
                        </TableCell>
                        <TableCell align="center">
                          {val.disease.non_infectious}
                        </TableCell>
                        <TableCell align="center">{val.injury.blunt}</TableCell>
                        <TableCell align="center">{val.injury.open}</TableCell>
                        <TableCell align="center">{val.fainting}</TableCell>
                        <TableCell align="center">{val.total}</TableCell>
                        <TableCell align="center">
                          {val.referred_to_hospital}
                        </TableCell>
                        <TableCell align="center">
                          {val.sent_to_isolation_or_home}
                        </TableCell>
                        <TableCell align="center">{val.remarks}</TableCell>
                      </TableRow>
                    ))
                  : ""}
                <TableRow>
                  <TableCell sx={{ fontWeight: 600 }}>TOTAL</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    {totals.totalInfectious}
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    {totals.totalNonInfectious}
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    {totals.totalBlunt}
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    {totals.totalOpen}
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    {totals.totalFainting}
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    {totals.alltotal}
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    {totals.refertohospitaltotal}
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    {totals.othertotal}
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    remarks
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    </>
  );
};

export default SectionWiseReport;
