import {
  Avatar,
  Box,
  Button,
  Card,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrums from "../../Components/Breadcrums";
import Datefeild from "../../Components/Datefeild";
import SimpleDropdown from "../../Components/SimpleDropdown";
import Commonservices from "../../service/Commonservices";
import { useDispatch, useSelector } from "react-redux";
import mysqlservice from "../../service/mySqlservice";
import { useFormik } from "formik";
import ApiService from "../../service/apiService";
import APPCONSTANTS from "../../config/constant";
import dayjs from "dayjs";

const GeneralconsentReport = () => {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user);

  const [generalData, setGeneralData] = useState([]);
  const [pages, setpages] = useState(1);
  const [rowsPerPage, setRawsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState("");
  const [classNbatchData, setClassNBatchData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [batchData, setBatchData] = useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      from: "",
      to: "",
      class: "",
      batch: "",
    },
    onSubmit: (values) => {
      handleGeneralData();
      formik.resetForm();
    },
  });

  const pageCount = Math.ceil(totalCount / rowsPerPage);

  useEffect(() => {
    handleClassNBatch();
    handleGeneralData();
  }, [pages]);

  const handleClassNBatch = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        inst_id: userData.institution_id,
        acdyr: userData.academic_year,
      };

      let apiResult = await ApiService.post(
        "bm-school/Docme-UI/registration/get_student_class",
        req
      );

      const combinedClasses = {};

      apiResult.forEach((item) => {
        const foundClass = APPCONSTANTS.classInRoman.find(
          (c) => c.roman === item.class
        );

        if (foundClass) {
          const classNumber = foundClass.number;

          if (combinedClasses[classNumber]) {
            combinedClasses[classNumber].batch = [
              ...new Set([
                ...combinedClasses[classNumber].batch,
                ...item.batch,
              ]),
            ];
          } else {
            combinedClasses[classNumber] = {
              class: classNumber,
              batch: item.batch,
            };
          }
          combinedClasses[classNumber].batch =
            combinedClasses[classNumber].batch.sort();
        }
      });

      const updatedClassNbatch = Object.values(combinedClasses);

      const sortedClassNbatch = updatedClassNbatch.sort((a, b) => {
        if (a.class === "KG 1") return -1;
        if (b.class === "KG 1") return 1;
        if (a.class === "KG 2") return -1;
        if (b.class === "KG 2") return 1;
        return a.class - b.class;
      });

      const classes = sortedClassNbatch.map((data) => {
        return {
          value: data.class,
          name: data.class,
        };
      });
      setClassNBatchData(sortedClassNbatch);
      setClassData(classes);

      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handleSelectClass = (value) => {
    formik.setFieldValue("class", value);
    const Allbatches =
      classNbatchData &&
      classNbatchData.find((data) => data.class === value)?.batch;

    const batches = Allbatches.map((data) => {
      return {
        value: data,
        name: data,
      };
    });

    setBatchData(batches);
  };

  const handleFromDate = (date, dateString) => {
    if (date) {
      let newDate = dayjs(date);
      formik.setFieldValue("from", newDate);
    } else {
      formik.setFieldValue("from", "");
    }
  };

  const handleToDate = (date, dateString) => {
    if (date) {
      let newDate = dayjs(date);
      formik.setFieldValue("to", newDate);
    } else {
      formik.setFieldValue("to", "");
    }
  };

  const handleGeneralData = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        institution_id: userData.institution_id,
        academic_year: userData.academic_year,
        page: pages,
        pageCount: rowsPerPage,
        class: formik.values.class,
        batch: formik.values.batch,
        from: formik.values.from,
        to: formik.values.to,
      };
      const apiResult = await mysqlservice.post("get_general_report", req);
      setGeneralData(apiResult.data);
      setTotalCount(apiResult.totalCount);
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handlePageChange = (event, value) => {
    setpages(value);
  };

  const theme = useTheme();

  return (
    <>
      <Breadcrums
        title={[
          {
            title: "GENERAL CONSENT NOTIFICATION SEND REPORT",
            icon: "",
            link: true,
            // path: "/",
          },
        ]}
      />
      <Box sx={{ p: 2 }}>
        <Card sx={{ p: 2 }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            flexWrap={"wrap"}
            gap={{ xs: 0.5, xl: 2 }}
          >
            <Datefeild
              // label={"Date"}
              value={formik.values.from}
              placeholder={"From"}
              style={{ minWidth: "126px" }}
              onChange={handleFromDate}
            />

            <Datefeild
              // label={"Date"}
              value={formik.values.to}
              placeholder={"To"}
              style={{ minWidth: "126px" }}
              onChange={handleToDate}
            />

            <SimpleDropdown
              label={"Class"}
              style={{ minWidth: "126px" }}
              value={formik.values.class}
              menuitem={classData}
              onChange={(e) => {
                handleSelectClass(e.target.value);
              }}
            />

            <SimpleDropdown
              label={"Batch"}
              style={{ minWidth: "126px" }}
              value={formik.values.batch}
              menuitem={batchData}
              onChange={(e) => {
                formik.setFieldValue("batch", e.target.value);
              }}
            />

            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#3BA5B9",
                color: "#fff",
                fontSize: "14px",
                fontWeight: 500,
                textTransform: "capitalize",
                mt: "2px",
                // height: "38px",
              }}
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Apply
            </Button>

            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                ml: { xs: "", sm: "auto" },
                gap: 1,
              }}
            >
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#3BA5B9",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: 500,
                  textTransform: "uppercase",
                }}
              >
                Generate
              </Button>
            </Box> */}
          </Box>

          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow
                  sx={
                    theme.palette.mode == "dark"
                      ? { backgroundColor: "#212529" }
                      : { backgroundColor: "#F3F6F9" }
                  }
                >
                  <TableCell align="center">SL</TableCell>
                  <TableCell align="center">NAME</TableCell>
                  <TableCell align="center">CLASS</TableCell>
                  <TableCell align="center">BATCH</TableCell>
                  <TableCell align="center">PARENT NAME</TableCell>
                  <TableCell align="center">PARENT EMAIL ID</TableCell>
                  <TableCell align="center">NOTIFICATION SEND</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {generalData && generalData.length ? (
                  generalData.map((val, ind) => (
                    <TableRow key={ind}>
                      <TableCell align="center">
                        {ind < 9 ? 0 : ""}
                        {ind + 1}
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Avatar
                            sx={{ height: "32px", width: "32px" }}
                            src={val && val.profile_pic ? val.profile_pic : ""}
                          />
                          <Typography
                            sx={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            {val.student_name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="center">Grade {val.class}</TableCell>
                      <TableCell align="center">
                        {val.class} - {val.batch}
                      </TableCell>
                      <TableCell align="center">{val.parent_name}</TableCell>
                      <TableCell align="center">{val.parent_email}</TableCell>
                      <TableCell align="center">
                        {val.no_of_notification}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No Data to Show
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {generalData && generalData.length > 0 ? (
            <Pagination
              sx={{
                m: 1,
                display: "flex",
                justifyContent: "end",
                "& .Mui-selected": {
                  color: "#fff",
                  backgroundColor: "#3BA5B9",
                },
                "& .MuiPaginationItem-page": {
                  color: "#3BA5B9",
                  "&.Mui-selected": {
                    backgroundColor: "#3BA5B9",
                    color: "#fff",
                  },
                },
              }}
              count={pageCount}
              page={pages}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          ) : (
            ""
          )}
        </Card>
      </Box>
    </>
  );
};

export default GeneralconsentReport;
