import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Textfield from "../../Components/Textfield";
import SimpleDropdown from "../../Components/SimpleDropdown";
import { Switch } from "antd";
import { useFormik } from "formik";
import Commonservices from "../../service/Commonservices";
import { useDispatch, useSelector } from "react-redux";
import mysqlservice from "../../service/mySqlservice";
import { toast } from "react-toastify";

const AddUserDrawer = ({
  open,
  handleClose,
  handleOpen,
  setrefresh,
  selectedData,
}) => {
  const userData = useSelector((state) => state.user);

  const dispatch = useDispatch();


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: selectedData ? selectedData.name : "",
      email: selectedData ? selectedData.email : "",
      mobile: selectedData ? selectedData.phone : "",
      role: selectedData ? selectedData.role : "",
    },
    onSubmit: async (value) => {
      if (selectedData && selectedData.id) {
        try {
          Commonservices.loaderON(dispatch);
          let req = {};
          req = value;
          req.id = selectedData.id;
          let apiResult = await mysqlservice.post("update_user", req);
          toast.success(apiResult.message);
          handledrawerClose();
          setrefresh(true);
          Commonservices.loaderOFF(dispatch);
        } catch (error) {
          Commonservices.loaderON(dispatch);
          console.log(error);
          Commonservices.loaderOFF(dispatch);
        }
      } else {
        try {
          Commonservices.loaderON(dispatch);
          let req = {};
          req = value;
          req.institution_id = userData.institution_id;
          req.user_id = userData.user_id;
          let apiResult = await mysqlservice.post("adduser", req);
          if (apiResult.status == 200) {
            toast.success(apiResult.message);
            handledrawerClose();
            setrefresh(true);
          } else {
            toast.error(apiResult.message);
          }
          Commonservices.loaderOFF(dispatch);
        } catch (error) {
          Commonservices.loaderON(dispatch);
          console.log(error);
          Commonservices.loaderOFF(dispatch);
        }
      }
    },
  });

  const handledrawerClose = () => {
    handleClose();
    formik.resetForm();
  };

  const userroles = [
    { id: 1, name: "", value: "" },
    { id: 2, name: "Admin", value: 10 },
    { id: 3, name: "Counselor", value: 20 },
    { id: 4, name: "Doctor", value: 30 },
    { id: 5, name: "Nurse", value: 40 },
  ];

  // const permissions = [
  //   { id: 1, name: "Edit", value: "Edit" },
  //   { id: 2, name: "View", value: "View" },
  // ];

  return (
    <Drawer
      open={open}
      onClose={handledrawerClose}
      anchor="right"
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: { xs: "100%", md: "598px" },
          borderRadius: 0,
          backgroundColor: "#EFEFEF",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        {/* header */}
        <Box
          sx={{
            p: 2,
            borderBottom: "1px solid #ddd",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Add User
            </Typography>
          </Box>
          <IconButton sx={{ ml: "auto" }} onClick={handledrawerClose}>
            <CloseIcon sx={{ height: "28px", width: "28px" }} />
          </IconButton>
        </Box>
        {/* content */}
        <Box
          sx={{
            flex: 1,
            p: 2,
            overflowY: "auto",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Textfield
              label={"Name"}
              placeholder={"Enter Name"}
              value={formik.values.name}
              onChange={(e) => {
                formik.setFieldValue("name", e.target.value);
              }}
            />
            <Textfield
              label={"Email"}
              placeholder={"Enter Email"}
              value={formik.values.email}
              onChange={(e) => {
                formik.setFieldValue("email", e.target.value);
              }}
            />
            <Textfield
              label={"Mobile Number"}
              placeholder={"Enter Number"}
              value={formik.values.mobile}
              onChange={(e) => {
                formik.setFieldValue("mobile", e.target.value);
              }}
            />
            {/* <Typography sx={{ fontSize: "12px", color: "#495057" }}>
              Your new user will get an email with a Benchmark Clinic link to
              create a username and password.
            </Typography> */}
            <Divider />

            <FormControl size="small">
              <Typography sx={{ fontSize: "14px" }}>Role</Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                placeholder="Select Role"
                value={formik.values.role}
                onChange={(e) => {
                  formik.setFieldValue("role", e.target.value);
                }}
                sx={{ backgroundColor: "#fff" }}
              >
                {userroles.map((data, index) => {
                  return (
                    <MenuItem value={data.value} key={index}>
                      {data.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            {/* <Divider /> */}
            {/* <Typography>Role’s Permissions</Typography>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                p: 1,
                pl: 2,
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Switch
                checked={formik.values.permissions.clinical_visit.enabled}
                onChange={() => {
                  formik.setFieldValue(
                    "permissions.clinical_visit.enabled",
                    !formik.values.permissions.clinical_visit.enabled
                  );
                }}
                style={{
                  backgroundColor: formik.values.permissions.clinical_visit
                    .enabled
                    ? "#3BA5B9"
                    : "",
                }}
              />
              <Typography fontSize={"14px"}>Clinic Visits</Typography>
              <Box ml={"auto"}>
                <SimpleDropdown
                  label={"Select"}
                  style={{ width: "86px" }}
                  menuitem={permissions}
                  value={
                    formik.values.permissions.clinical_visit.permission_level
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      "permissions.clinical_visit.permission_level",
                      e.target.value
                    );
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                p: 1,
                pl: 2,
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Switch
                checked={formik.values.permissions.student_tracking.enabled}
                onChange={() => {
                  formik.setFieldValue(
                    "permissions.student_tracking.enabled",
                    !formik.values.permissions.student_tracking.enabled
                  );
                }}
                style={{
                  backgroundColor: formik.values.permissions.student_tracking
                    .enabled
                    ? "#3BA5B9"
                    : "",
                }}
              />
              <Typography fontSize={"14px"}>Student Tracking</Typography>
              <Box ml={"auto"}>
                <SimpleDropdown
                  label={"Select"}
                  style={{ width: "86px" }}
                  menuitem={permissions}
                  value={
                    formik.values.permissions.student_tracking.permission_level
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      "permissions.student_tracking.permission_level",
                      e.target.value
                    );
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                pt: 2,
                pb: 1.5,
                pl: 2,
                pr: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box>
                <Box display={"flex"} gap={2}>
                  <Switch
                    checked={formik.values.permissions.parent_consent.enabled}
                    onChange={() => {
                      formik.setFieldValue(
                        "permissions.parent_consent.enabled",
                        !formik.values.permissions.parent_consent.enabled
                      );
                    }}
                    style={{
                      backgroundColor: formik.values.permissions.parent_consent
                        .enabled
                        ? "#3BA5B9"
                        : "",
                    }}
                  />
                  <Typography fontSize={"14px"}>Parent Consents</Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          "&.Mui-checked .MuiSvgIcon-root": {
                            color: "#3BA5B9",
                          },
                        }}
                        checked={
                          formik.values.permissions.general_consent.enabled
                        }
                        onChange={() => {
                          formik.setFieldValue(
                            "permissions.general_consent.enabled",
                            !formik.values.permissions.general_consent.enabled
                          );
                        }}
                      />
                    }
                    label="General Consents"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          "&.Mui-checked .MuiSvgIcon-root": {
                            color: "#3BA5B9",
                          },
                        }}
                        checked={
                          formik.values.permissions.vaccination_consent.enabled
                        }
                        onChange={() => {
                          formik.setFieldValue(
                            "permissions.vaccination_consent.enabled",
                            !formik.values.permissions.vaccination_consent
                              .enabled
                          );
                        }}
                      />
                    }
                    label="Vaccination Consents"
                  />
                </Box>
              </Box>

              <Box ml={"auto"}>
                <SimpleDropdown
                  label={"Select"}
                  style={{ width: "86px" }}
                  menuitem={permissions}
                  value={
                    formik.values.permissions.parent_consent.permission_level
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      "permissions.parent_consent.permission_level",
                      e.target.value
                    );
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                p: 2,
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Switch
                checked={formik.values.permissions.reports.enabled}
                onChange={() => {
                  formik.setFieldValue(
                    "permissions.reports.enabled",
                    !formik.values.permissions.reports.enabled
                  );
                }}
                style={{
                  backgroundColor: formik.values.permissions.reports.enabled
                    ? "#3BA5B9"
                    : "",
                }}
              />
              <Typography fontSize={"14px"}>Reports</Typography>
            </Box> */}
          </Box>
        </Box>
        {/* footer */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "auto",
            width: "100%",
          }}
        >
          <Divider />
          <Box p={2} display="flex" justifyContent="end" gap={2}>
            <Button sx={{ color: "#3BA5B9" }} onClick={handledrawerClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#3BA5B9", color: "#fff" }}
              onClick={formik.handleSubmit}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default AddUserDrawer;
