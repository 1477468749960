import axios from "axios";
import APPCONSTANTS from "../config/constant";

const baseUrl = APPCONSTANTS.NODE_SQL_SERVER;

const customHeader = {
  "Content-Type": "application/json",
  "X-Auth-Token": APPCONSTANTS.X_AUTH_TOKEN,
};

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: customHeader,
});

const mysqlservice = {
  get: (url, params) => {
    return axiosInstance.get(url, params);
  },
  post: (url, params) => {
    return axiosInstance.post(url, params);
  },
};

axiosInstance.interceptors.response.use(
  (res) => {
    console.log("RESPONCE...");
    if (res.data && res.data.data) {
      return res.data.data;
    }
    return res;
  },
  (err) => {
    console.error("API error...", err);
    return Promise.reject(err);
  }
);
export default mysqlservice;
