import {
  Box,
  ClickAwayListener,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { BiLocationPlus, BiCategory } from "react-icons/bi";
import { CgFileDocument } from "react-icons/cg";
import { CiRoute } from "react-icons/ci";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IoSettingsOutline } from "react-icons/io5";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSideBar } from "../../state/index";
import IconLogo from "../../Components/IconLogo";
import DocumentMedicine from "../../Asset/Document Medicine.png";
import DocumentMedicineUnselected from "../../Asset/Document Medicine unselect.png";
import DocumentTwo from "../../Asset/Document Two.png";
import DocumentTwoUnSelected from "../../Asset/Document two unselect.png";
import sectional_report from "../../Asset/sectional_report.png";
import sectional_report_inactive from "../../Asset/sectional_report_inactive.png";
import class_report from "../../Asset/class_report.png";
import class_report_inactive from "../../Asset/class_report_inactive.png";
import general_report from "../../Asset/general_report.png";
import general_report_inactive from "../../Asset/general_report_inactive.png";
import vaccination_report from "../../Asset/vaccination_report.png";
import vaccination_report_inactive from "../../Asset/vaccination_report_inactive.png";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { TbReport } from "react-icons/tb";


const Sidebar = ({ sideBarOpen, setSideBarOpen }) => {
  const toggleDrawer = (newOpen) => () => {
    setSideBarOpen(newOpen);
  };
  const isNonMobileScreens = useMediaQuery("(max-width: 900px)");
  const dispatch = useDispatch();
  const selectedMenuState = useSelector((state) => state.listItem);
  const userData = useSelector((state) => state.user);
  const subMenuLocal = localStorage.getItem("subMenuLocal");
  const theme = useTheme();

  const today = new Date();
  const formattedDate = today.toISOString().slice(0, 10);

  const [selectedMenu, setSelectedMenu] = useState(1);
  const [selectedSubMenu, setSelectedSubMenu] = useState(1);
  const [open, setopen] = useState(false);

  const [expandedMenus, setExpandedMenus] = useState({});

  const handleMenuClick = (menuItem) => {
    setSelectedMenu(menuItem.id);
    dispatch(setSideBar({ listItem: menuItem.id }));

    setExpandedMenus((prevState) => {
      const newState = {};
      data.forEach((item) => {
        newState[item.id] =
          item.id === menuItem.id ? !prevState[menuItem.id] : false;
      });
      return newState;
    });
  };

  const handleSubMenuClick = (id) => {
    setSelectedSubMenu(id);
    localStorage.setItem("subMenuLocal", id);
  };

  const data = [
    {
      id: 1,
      title: "Dashboard",
      value: "dashboard",
      path: "/home",
      icon: (
        <BiCategory
          style={
            (selectedMenuState ? selectedMenuState == 1 : selectedMenu == 1)
              ? { height: "20px", width: "20px", color: "#3BA5B9" }
              : { height: "20px", width: "20px", color: "#666666" }
          }
        />
      ),
    },
    {
      id: 2,
      title: "Clinical Visits",
      value: "clinical_visit",
      path: `/details/${formattedDate}`,
      icon: (
        <BiLocationPlus
          style={
            (selectedMenuState ? selectedMenuState == 2 : selectedMenu == 2)
              ? { height: "20px", width: "20px", color: "#3BA5B9" }
              : { height: "20px", width: "20px", color: "#666666" }
          }
        />
      ),
    },
    {
      id: 3,
      title: "Student Tracking",
      value: "student_tracking",
      path: "/track",
      icon: (
        <CiRoute
          style={
            (selectedMenuState ? selectedMenuState == 3 : selectedMenu == 3)
              ? { height: "20px", width: "20px", color: "#3BA5B9" }
              : { height: "20px", width: "20px", color: "#666666" }
          }
        />
      ),
    },
    {
      id: 4,
      title: "Student Tracking (Counsellor)",
      value: "counsellor_tracking",
      path: "/counsel_track",
      icon: (
        <CiRoute
          style={
            (selectedMenuState ? selectedMenuState == 4 : selectedMenu == 4)
              ? { height: "20px", width: "20px", color: "#3BA5B9" }
              : { height: "20px", width: "20px", color: "#666666" }
          }
        />
      ),
    },
    {
      id: 5,
      title: "Parent Consents",
      value: "parent_consent",
      path: "",
      icon: (
        <CgFileDocument
          style={
            (selectedMenuState ? selectedMenuState == 5 : selectedMenu == 5)
              ? { height: "20px", width: "20px", color: "#3BA5B9" }
              : { height: "20px", width: "20px", color: "#666666" }
          }
        />
      ),
      isExpand: true,
      subnav: [
        {
          id: 5.1,
          title: "General Consents",
          value: "general_consent",
          path: "/generalconsent",
          icon: (subMenuLocal ? subMenuLocal == 5.1 : selectedSubMenu == 5.1)
            ? DocumentMedicine
            : DocumentMedicineUnselected,
        },
        {
          id: 5.2,
          title: "Vaccination Consents",
          value: "vaccination_consent",
          path: "/vaccineconsent",
          icon: (subMenuLocal ? subMenuLocal == 5.2 : selectedSubMenu == 5.2)
            ? DocumentTwo
            : DocumentTwoUnSelected,
        },
      ],
    },
    {
      id: 6,
      title: "Settings",
      path: "/settings",
      value: "settings",
      icon: (
        <IoSettingsOutline
          style={
            (selectedMenuState ? selectedMenuState == 6 : selectedMenu == 6)
              ? { height: "20px", width: "20px", color: "#3BA5B9" }
              : { height: "20px", width: "20px", color: "#666666" }
          }
        />
      ),
    },
    {
      id: 7,
      title: "Reports",
      value: "reports",
      path: "",
      // path: "/reports",
      icon: (
        <TbReport
          style={
            (selectedMenuState ? selectedMenuState == 7 : selectedMenu == 7)
              ? { height: "20px", width: "20px", color: "#3BA5B9" }
              : { height: "20px", width: "20px", color: "#666666" }
          }
        />
      ),
      isExpand: true,
      subnav: [
        {
          id: 7.1,
          title: "Section Wise Clinic Visit Report",
          path: "/section_report",
          icon: (subMenuLocal ? subMenuLocal == 7.1 : selectedSubMenu == 7.1)
            ? sectional_report
            : sectional_report_inactive,
        },
        {
          id: 7.2,
          title: "Class Wise Clinic Visit Report",
          path: "/class_report",
          icon: (subMenuLocal ? subMenuLocal == 7.2 : selectedSubMenu == 7.2)
            ? class_report
            : class_report_inactive,
        },
        {
          id: 7.3,
          title: "General Concern  Report",
          path: "/general_report",
          icon: (subMenuLocal ? subMenuLocal == 7.3 : selectedSubMenu == 7.3)
            ? general_report
            : general_report_inactive,
        },
        {
          id: 7.4,
          title: "Vaccination Report",
          path: "/vaccine_report",
          icon: (subMenuLocal ? subMenuLocal == 7.4 : selectedSubMenu == 7.4)
            ? vaccination_report
            : vaccination_report_inactive,
        },
        {
          id: 7.5,
          title: "Incident Report",
          path: "/incident_report",
          icon: (subMenuLocal ? subMenuLocal == 7.5 : selectedSubMenu == 7.5)
            ? vaccination_report
            : vaccination_report_inactive,
        },
      ],
    },
    {
      id: 8,
      title: "Incidents",
      value: "incidents",
      path: "/incident",
      icon: (
        <CgFileDocument
          style={
            (selectedMenuState ? selectedMenuState == 8 : selectedMenu == 8)
              ? { height: "20px", width: "20px", color: "#3BA5B9" }
              : { height: "20px", width: "20px", color: "#666666" }
          }
        />
      ),
    },
    {
      id: 9,
      title: "Send Notification",
      value: "send_notification",
      path: "/send_notification",
      icon: (
        <NotificationsActiveOutlinedIcon
          style={
            (selectedMenuState ? selectedMenuState == 9 : selectedMenu == 9)
              ? { height: "20px", width: "20px", color: "#3BA5B9" }
              : { height: "20px", width: "20px", color: "#666666" }
          }
        />
      ),
    },
  ];

  const privilege = userData && userData.privileges ? userData.privileges : [];
  // let allowedMenus = data;
  let allowedMenus = data
    .map((menu) => {
      if (menu.subnav && menu.value != "reports") {
        const allowedSubnav = menu.subnav.filter((sub) =>
          privilege.some((priv) => priv.module === sub.value)
        );

        return {
          ...menu,
          subnav: allowedSubnav.length > 0 ? allowedSubnav : [],
        };
      }

      if (privilege.some((priv) => priv.module === menu.value)) {
        return menu;
      }

      return null;
    })
    .filter(
      (menu) => menu !== null && (menu.subnav ? menu.subnav.length > 0 : true)
    );

  return (
    <>
      {!isNonMobileScreens ? (
        <ClickAwayListener onClickAway={() => setSideBarOpen(false)}>
          <Box
            sx={{
              minWidth: 220,
              height: "100vh",
              // backgroundColor: "#113D3A",
              // color: "#fff",
              borderRight: "1px solid #DEDEDE",
            }}
            role="presentation"
          >
            {/* <Drawer open={sideBarOpen} onClose={toggleDrawer(false)}> */}
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 700,
                textAlign: "center",
                mt: "30px",
              }}
            >
              NIMS CLINIC
            </Typography>

            <Box sx={{ mt: "37px", p: "12px" }}>
              <Typography sx={{ fontSize: "11px", fontWeight: 500 }}>
                MENU
              </Typography>
              {allowedMenus &&
                allowedMenus.map((menuItem) => {
                  const isSelected = selectedMenuState
                    ? selectedMenuState === menuItem.id
                    : selectedMenu === menuItem.id;

                  const listItemStyle = isSelected
                    ? theme.palette.mode === "dark"
                      ? { color: "#fff" }
                      : { color: "#000" }
                    : { color: "#666666" };

                  return (
                    <Link
                      to={menuItem.path}
                      key={menuItem.id}
                      style={{ textDecoration: "none" }}
                    >
                      <List sx={{ paddingBottom: 0 }}>
                        <ListItem
                          disablePadding
                          onClick={() => handleMenuClick(menuItem)}
                          sx={listItemStyle}
                        >
                          <ListItemButton
                            sx={{ display: "flex", gap: 1, px: 0 }}
                          >
                            {menuItem.icon}
                            <Typography sx={{ fontSize: "15px" }}>
                              {menuItem.title}
                            </Typography>
                            {menuItem.isExpand &&
                              (expandedMenus[menuItem.id] ? (
                                <KeyboardArrowUpIcon
                                  sx={{
                                    ml: "auto",
                                    height: "20px",
                                    width: "20px",
                                  }}
                                />
                              ) : (
                                <KeyboardArrowDownIcon
                                  sx={{
                                    ml: "auto",
                                    height: "20px",
                                    width: "20px",
                                  }}
                                />
                              ))}
                          </ListItemButton>
                        </ListItem>
                        {menuItem.isExpand && menuItem.subnav && (
                          <Collapse
                            in={expandedMenus[menuItem.id]}
                            timeout="auto"
                            unmountOnExit
                          >
                            {menuItem.subnav.map((subMenuItem) => {
                              const isSubMenuSelected = subMenuLocal
                                ? subMenuLocal === subMenuItem.id
                                : selectedSubMenu === subMenuItem.id;

                              return (
                                <Link
                                  to={subMenuItem.path}
                                  key={subMenuItem.id}
                                >
                                  <List component="div" disablePadding>
                                    <ListItemButton
                                      sx={{
                                        display: "flex",
                                        gap: 1,
                                        px: 0,
                                        pl: 3,
                                        color: isSubMenuSelected
                                          ? theme.palette.mode === "dark"
                                            ? "#fff"
                                            : "#000"
                                          : "#666666",
                                      }}
                                      onClick={() =>
                                        handleSubMenuClick(subMenuItem.id)
                                      }
                                    >
                                      <IconLogo
                                        image={subMenuItem.icon}
                                        size="20"
                                        radius="0"
                                        right="12px"
                                      />
                                      <Typography sx={{ fontSize: "13px" }}>
                                        {subMenuItem.title}
                                      </Typography>
                                    </ListItemButton>
                                  </List>
                                </Link>
                              );
                            })}
                          </Collapse>
                        )}
                      </List>
                    </Link>
                  );
                })}
            </Box>

            {/* </Drawer> */}
          </Box>
        </ClickAwayListener>
      ) : (
        <Drawer open={sideBarOpen} onClose={toggleDrawer(false)}>
          <Box
            sx={{
              width: 250,
              height: "100vh",
              // color: "#fff",
              // backgroundColor: "#113D3A",
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 700,
                textAlign: "center",
                mt: "30px",
              }}
            >
              NIMS CLINIC
            </Typography>

            <Box sx={{ mt: "37px", p: "12px" }}>
              <Typography sx={{ fontSize: "11px", fontWeight: 500 }}>
                MENU
              </Typography>
              {allowedMenus &&
                allowedMenus.map((menuItem) => {
                  const isSelected = selectedMenuState
                    ? selectedMenuState === menuItem.id
                    : selectedMenu === menuItem.id;

                  const listItemStyle = isSelected
                    ? theme.palette.mode === "dark"
                      ? { color: "#fff" }
                      : { color: "#000" }
                    : { color: "#666666" };

                  return (
                    <Link
                      to={menuItem.path}
                      key={menuItem.id}
                      style={{ textDecoration: "none" }}
                    >
                      <List sx={{ paddingBottom: 0 }}>
                        <ListItem
                          disablePadding
                          onClick={() => handleMenuClick(menuItem)}
                          sx={listItemStyle}
                        >
                          <ListItemButton
                            sx={{ display: "flex", gap: 1, px: 0 }}
                          >
                            {menuItem.icon}
                            <Typography sx={{ fontSize: "15px" }}>
                              {menuItem.title}
                            </Typography>
                            {menuItem.isExpand &&
                              (expandedMenus[menuItem.id] ? (
                                <KeyboardArrowUpIcon
                                  sx={{
                                    ml: "auto",
                                    height: "20px",
                                    width: "20px",
                                  }}
                                />
                              ) : (
                                <KeyboardArrowDownIcon
                                  sx={{
                                    ml: "auto",
                                    height: "20px",
                                    width: "20px",
                                  }}
                                />
                              ))}
                          </ListItemButton>
                        </ListItem>
                        {menuItem.isExpand && menuItem.subnav && (
                          <Collapse
                            in={expandedMenus[menuItem.id]}
                            timeout="auto"
                            unmountOnExit
                          >
                            {menuItem.subnav.map((subMenuItem) => {
                              const isSubMenuSelected = subMenuLocal
                                ? subMenuLocal === subMenuItem.id
                                : selectedSubMenu === subMenuItem.id;

                              return (
                                <Link
                                  to={subMenuItem.path}
                                  key={subMenuItem.id}
                                >
                                  <List component="div" disablePadding>
                                    <ListItemButton
                                      sx={{
                                        display: "flex",
                                        gap: 1,
                                        px: 0,
                                        pl: 3,
                                        color: isSubMenuSelected
                                          ? theme.palette.mode === "dark"
                                            ? "#fff"
                                            : "#000"
                                          : "#666666",
                                      }}
                                      onClick={() =>
                                        handleSubMenuClick(subMenuItem.id)
                                      }
                                    >
                                      <IconLogo
                                        image={subMenuItem.icon}
                                        size="20"
                                        radius="0"
                                        right="12px"
                                      />
                                      <Typography sx={{ fontSize: "13px" }}>
                                        {subMenuItem.title}
                                      </Typography>
                                    </ListItemButton>
                                  </List>
                                </Link>
                              );
                            })}
                          </Collapse>
                        )}
                      </List>
                    </Link>
                  );
                })}
            </Box>
          </Box>
        </Drawer>
      )}
    </>
  );
};

export default Sidebar;
