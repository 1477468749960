import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";

const StudentMedicalHistory = ({ activeStep, studentMedicalHistory }) => {
  const diseaseList = [
    {
      id: 1,
      title: "Does the student suffer from any Cardiovascular problem? ",
      value: "have_Cardiovascular_problem",
    },
    {
      id: 2,
      title: "Does the student suffer from Diabetes?",
      value: "have_Diabetes",
    },
    {
      id: 3,
      title: "Does the student suffer from Hypertension? ",
      value: "have_Hypertension",
    },
    {
      id: 4,
      title: "Does the student suffer from Bronchial Asthma?",
      value: "have_Bronchial_Asthma",
    },
    {
      id: 5,
      title: "Does the student suffer from any Renal problem?",
      value: "have_Renal_problem",
    },
    {
      id: 6,
      title: "Does the student suffer from Epilepsy or Convulsion seizures?",
      value: "have_Epilepsy",
    },
    {
      id: 7,
      title: "Does the student suffer from Hemolytic Anemia, type G6PD?",
      value: "have_Hemolytic_Anemia",
    },
    {
      id: 8,
      title:
        "Did the student suffer from Poliomyelitis (infantile paralysis infection)?",
      value: "have_Poliomyelitis",
    },
    {
      id: 9,
      title:
        "Did student get any blood, antibodies or plasma transfusion in the past?",
      value: "have_plasma_transfusion",
    },
    {
      id: 10,
      title: "Does the student suffer from any Skin Problem?",
      value: "have_Skin_Problem?",
    },
    {
      id: 11,
      title: "Does the student suffer from Epistaxis??",
      value: "have_Epistaxis",
    },
    {
      id: 12,
      title: "Did the student suffer from Viral Hepatitis?",
      value: "have_Viral_Hepatitis",
    },
  ];

  const enquires = [
    {
      id: 1,
      title:
        "Does the student suffer from any allergy to food, dust, medicine etc.? If yes, please specify in comments",
      value: "have_allergy",
      comment: "have_allergy_comment",
    },
    {
      id: 2,
      title:
        "Does the student suffer from any Hereditary Blood Disease & (e.g. Thalassemia,sickle cell anemia, Hemophilia)?If yes, specify in comments.",
      value: "have_bloodDisease",
      comment: "have_bloodDisease_comment",
    },
    {
      id: 3,
      title:
        "Dose the student use any medical aid device?If yes, specify the device details comments.",
      value: "have_medicalAid_device",
      comment: "have_medicalAid_device_comment",
    },
    {
      id: 4,
      title:
        "Did the student undergo any surgery in the past?If yes, specify the details in comments.",
      value: "have_surgery",
      comment: "have_surgery_comment",
    },
    {
      id: 5,
      title:
        "Was the student ever hospitalized? If yes, please specify the reasons in comments",
      value: "if_Hospitalizes",
      comment: "if_Hospitalizes_comment",
    },
    {
      id: 6,
      title:
        "Does the student have any health condition that could weaken the immune system such as Cancer (Blood cancer, Lymphoma), or an organ transplant? If yes, please specify in comments",
      value: "have_cancer",
      comment: "have_cancer_comment",
    },
    {
      id: 7,
      title:
        "Did the student suffer from any of the following diseases: (Mumps, Measles, Diphtheria, Pertussis, Chickenpox, Tuberculosis),If yes, please specify details in comments",
      value: "suffer_disease",
      comment: "suffer_disease_comment",
    },
    {
      id: 8,
      title:
        "Does the student suffer from any Mental or Behavioral Problem? If yes, please specify in comments",
      value: "have_Behavioral_Problem",
      comment: "have_Behavioral_Problem_comment",
    },
    {
      id: 9,
      title:
        "Does the student suffer from any other Problem or disease not mentioned here? If ease specify in comments",
      value: "have_other_problem",
      comment: "have_other_problem_comment",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Typography sx={{ fontSize: { md: "12px", lg: "13px", xl: "14px" }, fontWeight: 600 }}>
          Student Medical History
        </Typography>
        <Divider sx={{ ml: 2, flexGrow: 1 }} />
      </Box>

      <Grid container spacing={2} mt={1}>
        {diseaseList.map((val, index) => {
          return (
            <Grid item xs={12} sm={6} md={3} lg={2.4} key={index}>
              <Box>
                <Typography sx={{ fontSize: { md: "12px", lg: "13px", xl: "14px" } }}>{val.title}</Typography>
                <Box display={"flex"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={studentMedicalHistory.values[val.value]}
                        onChange={() => {
                          studentMedicalHistory.setFieldValue(val.value, true);
                        }}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={!studentMedicalHistory.values[val.value]}
                        onChange={(value) => {
                          studentMedicalHistory.setFieldValue(
                            val.value,
                            false
                          );
                        }}
                      />
                    }
                    label="No"
                  />
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>

      <Divider sx={{ mt: 2 }} />

      <Grid container spacing={2} mt={1}>
        {enquires.map((val, index) => {
          return (
            <Grid item xs={12} sm={6} md={3} lg={2.4} key={index}>
              <Box height={{ xs: "auto", md: "150px", xl: "110px" }}>
                <Typography sx={{ fontSize: { md: "12px", lg: "13px", xl: "14px" } }}>{val.title}</Typography>
                <Box display={"flex"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={studentMedicalHistory.values[val.value]}
                        onChange={() => {
                          studentMedicalHistory.setFieldValue(val.value, true);
                        }}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={!studentMedicalHistory.values[val.value]}
                        onChange={() => {
                          studentMedicalHistory.setFieldValue(val.value, false);
                        }}
                      />
                    }
                    label="No"
                  />
                </Box>
              </Box>
              <TextField
                id={`${index}-enquiry`}
                placeholder="Add Comment"
                sx={{
                  backgroundColor: "#fff",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#DFDFDF",
                    },
                    "&:hover fieldset": {
                      borderColor: "#DFDFDF",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#DFDFDF",
                    },
                    "& input::placeholder": {
                      color: "#71717A",
                    },
                  },
                }}
                value={studentMedicalHistory.values[val.comment]}
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  studentMedicalHistory.setFieldValue(
                    val.comment,
                    e.target.value
                  );
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default StudentMedicalHistory;
