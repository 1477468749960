import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import IconLogo from "../../Components/IconLogo";
import Scan from "../../Asset/scan 1.png";
import menuSearch from "../../Asset/menu-search-line.png";
import apiService from "../../service/apiService";
import Commonservices from "../../service/Commonservices";
import { useDispatch, useSelector } from "react-redux";
import { PiVirus, PiSpiralBold, PiBandaidsLight } from "react-icons/pi";
import { useParams } from "react-router-dom";
import SimpleDropdown from "../../Components/SimpleDropdown";
import APPCONSTANTS from "../../config/constant";
import mysqlservice from "../../service/mySqlservice";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DrawerComp = ({
  open,
  onClose,
  setStudentData,
  studentData,
  setUpdateId,
}) => {
  const url_params = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const userData = useSelector((state) => state.user);

  const isNonMobileScreen = useMediaQuery("(min-width:900px)");
  const [input, setinput] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [selecteddStudent, setSelectedStudent] = useState("");
  const [page, setPage] = useState(1);
  const [visitButtonId, setVisitButtonId] = useState(1);
  const [reasonButtonId, setReasonButtonId] = useState(1);
  const [statusButtonId, setStatusButtonId] = useState(
    "Sent to Isolation Room"
  );
  const [informButtonId, setInformButtonId] = useState(1);

  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [statusData, setstatusData] = useState("");

  const [addStudentData, setAddStudentData] = useState({
    institution_id: "",
    academic_year: "",
    student_name: "",
    profile_pic: "",
    admission_no: "",
    age: "",
    dob: "",
    gender: "",
    class: "",
    batch: "",
    section: "",
    batch_details: "",
    visit_date: "",
    visit_type: "By teacher",
    reason: "Disease",
    situation: "Infectious",
    visit_status: "Sent to Isolation Room",
    informed_parent: "Yes",
    remarks: "",
    contact_status: "",
    parent_name: "",
    parent_email: "",
    parent_mobile: "",
    user: "",
    user_id: "",
    user_token: "",
  });
  const [error, setError] = useState({ remarks: "" });
  const [studentClass, setStudentClass] = useState("");
  const [studentBatch, setStudentBatch] = useState("");

  const classInRoman = APPCONSTANTS.classInRoman;

  const handleSearch = (value) => {
    setinput(value);
    fetchStudentData(value);
  };

  const visitButtons = [
    {
      id: 1,
      title: "By Teacher",
      value: "By Teacher",
    },
    { id: 2, title: "Direct Visit", value: "Direct_visit" },
  ];

  const reasonButton = [
    {
      id: 1,
      title: "Disease",
      icon: (
        <PiVirus
          style={
            reasonButtonId === 1
              ? { height: "22px", width: "22px" }
              : { height: "22px", width: "22px", color: "#3BA5B9" }
          }
        />
      ),
      value: "Disease",
    },
    {
      id: 2,
      title: "Injury",
      icon: (
        <PiBandaidsLight
          style={
            reasonButtonId === 2
              ? { height: "22px", width: "22px" }
              : { height: "22px", width: "22px", color: "#3BA5B9" }
          }
        />
      ),
      value: "Injury",
    },
    {
      id: 3,
      title: "Fainting",
      icon: (
        <PiSpiralBold
          style={
            reasonButtonId === 3
              ? { height: "22px", width: "22px" }
              : { height: "22px", width: "22px", color: "#3BA5B9" }
          }
        />
      ),
      value: "Fainting",
    },
  ];

  const statusButton = [
    {
      id: 1,
      title: "Sent to Isolation Room",
      value: "Sent to Isolation Room",
    },
    {
      id: 2,
      title: "Sent to Hospital",
      value: "Sent to Hospital",
    },
    {
      id: 3,
      title: "Sent Home",
      value: "Sent Home",
    },
    {
      id: 4,
      title: "Back to Class",
      value: "Back to Class",
    },
  ];

  const informeButtons = [
    {
      id: 1,
      title: "Yes",
      value: "Yes",
    },
    { id: 2, title: "No", value: "No" },
  ];

  const informStatus = [
    { id: 1, title: "No Answer" },
    { id: 2, title: "Busy" },
    { id: 3, title: "Not Reached" },
    { id: 4, title: "Callback Requested" },
    { id: 5, title: "Wrong Number" },
  ];

  const fetchStudentData = async (value) => {
    Commonservices.loaderON(dispatch);
    try {
      if (value.length > 3) {
        const req = {
          searchkey: value,
          inst_id: userData.institution_id,
        };

        let apiResult = await apiService.post(
          "bm-school/Docme-UI/registration/get_student_search_data",
          req
        );

        setSearchData(apiResult);
        console.log(apiResult);

        if (value == "") {
          setSearchData([]);
        }
        if (apiResult === "No data found" || apiResult === "no data found") {
          setSearchData([]);
        }
      }
    } catch (error) {
      setSearchData([]);
      console.log(error);
    }
    Commonservices.loaderOFF(dispatch);
  };

  const handleSelectStudent = async (value) => {
    let parts = value.batch.split("/");

    let romanClass = parts[0];
    let partBatch = parts[1];
    let partSection = parts[3];

    // if (romanClass.length > 3) {
    //   romanClass = romanClass.slice(0, 3);
    // }

    let partClass = await classInRoman.find((val) => val.roman == romanClass);
    // console.log(partClass);

    setAddStudentData((prev) => ({
      ...prev,
      institution_id: userData.institution_id,
      academic_year: userData.academic_year,
      student_name: value.student_name,
      profile_pic: value.profile_image,
      admission_no: value.Admn_No,
      age: value.age,
      dob: value.dob,
      gender: value.gender,
      class: partClass.number,
      batch: partBatch,
      section: partSection,
      batch_details: value.batch,
      visit_date: url_params.date ? url_params.date : Date.now(),
      parent_name: value.father_name,
      parent_email: value.father_email,
      parent_mobile: value.father_phone,
      user: userData.user_id,
      user_id: userData.name,
      user_token: "",
    }));
    setSelectedStudent({
      student_name: value.student_name,
      class: partClass.number,
      batch: partBatch,
      profile_image: value.profile_image,
      father_name: value.father_name,
      father_phone: value.father_phone,
    });
    setPage(2);
  };

  const handleVisitButtonClick = (value, id) => {
    setVisitButtonId(id);
  };

  const handleReasonButtonClick = (value, id) => {
    setReasonButtonId(id);
  };

  const handleStatusButtonClick = (value, id) => {
    setstatusData(value);
    // setStatusButtonId(value);
  };

  const handleInformButtonClick = (value, id) => {
    setInformButtonId(id);
  };

  const handleDataInput = (key, value) => {
    setAddStudentData((prev) => ({
      ...prev,
      [key]: value,
    }));
    if (key === "informed_parent" && value === "Yes") {
      setAddStudentData((prev) => ({
        ...prev,
        contact_status: "",
      }));
    }
  };

  const handleAddStudent = async () => {
    Commonservices.loaderON(dispatch);
    try {
      if (
        addStudentData.informed_parent === "No" &&
        addStudentData.contact_status === ""
      ) {
        toast.error("Status is Complusory");
      } else {
        const apiResult = await mysqlservice.post("add_visit", addStudentData);
        onClose();
        setSelectedStudent("");
        setPage(1);
        setinput("");
        setSearchData("");
        setVisitButtonId(1);
        setInformButtonId(1);
        setReasonButtonId(1);
        setStatusButtonId(1);
        setUpdateId(null);
        setAddStudentData({
          institution_id: "",
          academic_year: "",
          student_name: "",
          profile_pic: "",
          admission_no: "",
          age: "",
          dob: "",
          gender: "",
          class: "",
          batch: "",
          section: "",
          batch_details: "",
          visit_date: "",
          visit_type: "By teacher",
          reason: "Disease",
          situation: "Infectious",
          visit_status: "Sent to Isolation Room",
          informed_parent: true,
          remarks: "",
          contact_status: "",
          parent_name: "",
          parent_email: "",
          parent_mobile: "",
          user: "",
          user_id: "",
          user_token: "",
        });
      }
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handleCancel = () => {
    onClose();
    setSelectedStudent("");
    setPage(1);
    setinput("");
    setSearchData("");
    setVisitButtonId(1);
    setInformButtonId(1);
    setReasonButtonId(1);
    setStatusButtonId(1);
    setAddStudentData({
      institution_id: "",
      academic_year: "",
      student_name: "",
      profile_pic: "",
      admission_no: "",
      age: "",
      dob: "",
      gender: "",
      class: "",
      batch: "",
      section: "",
      batch_details: "",
      visit_date: "",
      visit_type: "By teacher",
      reason: "Disease",
      situation: "Infectious",
      visit_status: "Sent to Isolation Room",
      informed_parent: "Yes",
      remarks: "",
      contact_status: "",
      parent_name: "",
      parent_email: "",
      parent_mobile: "",
      user: "",
      user_id: "",
      user_token: "",
    });
  };

  const handleStatusModalOpen = (value, id) => {
    if (id != 1) {
      setStatusModalOpen(true);
    } else {
      handleDataInput("visit_status", value);
      setStatusButtonId(value);
    }
  };

  const handleStatusModalClose = () => {
    setStatusModalOpen(false);
  };

  const handleStatusSelect = () => {
    handleDataInput("visit_status", statusData);
    setStatusButtonId(statusData);
    handleStatusModalClose();
  };

  return (
    <Drawer
      open={open}
      onClose={handleCancel}
      anchor="right"
      sx={
        theme.palette.mode == "dark"
          ? {
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { xs: "100%", md: "598px" },
                backgroundColor: "#212529",
              },
            }
          : {
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { xs: "100%", md: "598px" },
                backgroundColor: "#EFEFEF",
              },
            }
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            p: 2,
            borderBottom: "1px solid #ddd",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Add Student
            </Typography>
            <Typography fontSize="12px">Enter updates about student</Typography>
          </Box>
          <IconButton sx={{ ml: "auto" }} onClick={handleCancel}>
            <CloseIcon sx={{ height: "28px", width: "28px" }} />
          </IconButton>
        </Box>

        {/* Content */}
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          {page == 1 ? (
            <Box height={"100%"}>
              <Box p={2}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  placeholder="Search student ID"
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ fontSize: 20, color: "#8D9DA4" }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconLogo image={Scan} size="24" bottom="-10" />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    color: "#8D9DA4",
                    fontSize: "13px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        fontSize: "13px",
                        color: "#8D9DA4",
                      },
                      "& input": {
                        color: "black",
                      },
                    },
                    "& input::placeholder": {
                      color: "#000",
                      fontSize: "13px",
                    },
                  }}
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                />
              </Box>
              {searchData && searchData.length ? (
                <Box px={2}>
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                  >
                    {searchData && searchData.length
                      ? searchData.map((val, index) => {
                          return (
                            <ListItem
                              sx={{
                                display: { xs: "block", md: "flex" },
                                alignItems: "start",
                                justifyContent: "space-between",
                              }}
                              key={index}
                              onClick={() => {
                                handleSelectStudent(val);
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <Avatar
                                  src=""
                                  sx={{
                                    height: "34px",
                                    width: "34px",
                                    display: { xs: "none", md: "flex" },
                                  }}
                                />
                                <Box>
                                  <Typography
                                    sx={{ fontSize: "12px", fontWeight: 600 }}
                                  >
                                    {val.student_name}
                                  </Typography>
                                  <Typography sx={{ fontSize: "12px" }}>
                                    {val.Class_code}
                                  </Typography>
                                </Box>
                              </Box>
                              <Typography sx={{ fontSize: "12px" }}>
                                Admn_no : {val.Admn_No}
                              </Typography>
                            </ListItem>
                          );
                        })
                      : ""}
                  </List>
                </Box>
              ) : input && searchData.length == 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    p: isNonMobileScreen ? 4 : 2,
                  }}
                >
                  <Typography sx={{ fontSize: "30px", fontWeight: 700 }}>
                    No Data Found...
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    p: isNonMobileScreen ? 4 : 2,
                  }}
                >
                  <IconLogo image={menuSearch} />
                  <Typography sx={{ fontSize: "30px", fontWeight: 700 }}>
                    Search or Scan
                  </Typography>
                  <Typography fontSize="16px">
                    Enter student ID or scan QR code to view student details
                  </Typography>
                </Box>
              )}
            </Box>
          ) : page === 2 ? (
            <Box p={2}>
              <Grid container>
                <Grid item xs={12} sm={7} display={"flex"} gap={1}>
                  <Avatar
                    src={selecteddStudent.profile_image}
                    sx={{
                      height: "34px",
                      width: "34px",
                    }}
                  />
                  <Box>
                    <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                      {selecteddStudent.student_name}
                    </Typography>
                    <Typography sx={{ fontSize: "12px" }}>
                      Grade {selecteddStudent.class} - {"  "}
                      {selecteddStudent.batch}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  textAlign={{ xs: "left", sm: "right" }}
                  mt={{ xs: 2, sm: 0 }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                    Parents Details
                  </Typography>
                  {/* <Box
                    display={"flex"}
                    justifyContent={{ xs: "start", sm: "end" }}
                    mt={"8px"}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        textTransform: "capitalize",
                        color: "#8D949C",
                      }}
                    >
                      Name &nbsp;-
                    </Typography>
                    <Typography
                      sx={{ fontSize: "12px", textTransform: "capitalize" }}
                    >
                      &nbsp;{selecteddStudent.father_name}
                    </Typography>
                  </Box> */}
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          textTransform: "capitalize",
                          color: "#8D949C",
                        }}
                      >
                        Name &nbsp;-
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ fontSize: "12px", textTransform: "capitalize" }}
                      >
                        &nbsp;{selecteddStudent.father_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          textTransform: "capitalize",
                          color: "#8D949C",
                        }}
                      >
                        Mobile &nbsp;-
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ fontSize: "12px", textTransform: "capitalize" }}
                      >
                        &nbsp;{selecteddStudent.father_phone}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* <Box
                    display={"flex"}
                    justifyContent={{ xs: "start", sm: "end" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        textTransform: "capitalize",
                        color: "#8D949C",
                      }}
                    >
                      Mobile &nbsp;-
                    </Typography>
                    <Typography
                      sx={{ fontSize: "12px", textTransform: "capitalize" }}
                    >
                      &nbsp;{selecteddStudent.father_phone}
                    </Typography>
                  </Box> */}
                </Grid>
              </Grid>
              <Divider sx={{ mt: 1 }} />
              <Box mt={2}>
                <Box>
                  <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                    Visits
                  </Typography>
                  <Box sx={{ display: "flex", mt: 1 }}>
                    {visitButtons.map((val, index) => {
                      return (
                        <Button
                          key={index}
                          variant="contained"
                          fullWidth
                          sx={{
                            ...(visitButtonId === val.id
                              ? {
                                  borderRadius: 0,
                                  backgroundColor: "#3BA5B9",
                                  color: "#fff",
                                  textTransform: "capitalize",
                                  fontSize: "14px",
                                  boxShadow: 0,
                                  border: "1px solid #C7C7C7",
                                  "&:hover": {
                                    boxShadow: 0,
                                    color: "#fff",
                                    backgroundColor: "#3BA5B9",
                                  },
                                }
                              : {
                                  borderRadius: 0,
                                  backgroundColor: "#fff",
                                  color: "#000",
                                  textTransform: "capitalize",
                                  fontSize: "14px",
                                  boxShadow: 0,
                                  border: "1px solid #C7C7C7",
                                  "&:hover": {
                                    boxShadow: 0,
                                    color: "#000",
                                    backgroundColor: "#fff",
                                  },
                                }),
                            ...(index === 0
                              ? {
                                  borderTopLeftRadius: "8px",
                                  borderBottomLeftRadius: "8px",
                                }
                              : {}),
                            ...(index === visitButtons.length - 1
                              ? {
                                  borderTopRightRadius: "8px",
                                  borderBottomRightRadius: "8px",
                                }
                              : {}),
                          }}
                          onClick={() => {
                            handleVisitButtonClick(val.value, val.id);
                            handleDataInput("visit_type", val.value);
                          }}
                        >
                          {val.title}
                        </Button>
                      );
                    })}
                  </Box>
                </Box>
                <Divider sx={{ mt: 2 }} />

                <Box mt={2}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                    Reason
                  </Typography>
                  <Box sx={{ display: "flex", mt: 1 }}>
                    {reasonButton.map((data, index) => {
                      return (
                        <Button
                          key={index}
                          variant="contained"
                          fullWidth
                          sx={{
                            ...(reasonButtonId === data.id
                              ? {
                                  borderRadius: 0,
                                  backgroundColor: "#3BA5B9",
                                  color: "#fff",
                                  textTransform: "capitalize",
                                  fontSize: "14px",
                                  boxShadow: 0,
                                  border: "1px solid #C7C7C7",
                                  "&:hover": {
                                    boxShadow: 0,
                                    color: "#fff",
                                    backgroundColor: "#3BA5B9",
                                  },
                                }
                              : {
                                  borderRadius: 0,
                                  backgroundColor: "#fff",
                                  color: "#000",
                                  textTransform: "capitalize",
                                  fontSize: "14px",
                                  boxShadow: 0,
                                  border: "1px solid #C7C7C7",
                                  "&:hover": {
                                    boxShadow: 0,
                                    color: "#000",
                                    backgroundColor: "#fff",
                                  },
                                }),
                            ...(index === 0
                              ? {
                                  borderTopLeftRadius: "8px",
                                  borderBottomLeftRadius: "8px",
                                }
                              : {}),
                            ...(index === reasonButton.length - 1
                              ? {
                                  borderTopRightRadius: "8px",
                                  borderBottomRightRadius: "8px",
                                }
                              : {}),
                          }}
                          onClick={() => {
                            handleReasonButtonClick(data.value, data.id);
                            handleDataInput("reason", data.value);
                          }}
                        >
                          {data.title} {data.icon}
                        </Button>
                      );
                    })}
                  </Box>
                  {reasonButtonId === 1 ? (
                    <Box>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={addStudentData.situation}
                          onChange={(e) => {
                            handleDataInput("situation", e.target.value);
                          }}
                        >
                          <FormControlLabel
                            value="Infectious"
                            control={<Radio />}
                            label="Infectious"
                            defaultChecked
                          />
                          <FormControlLabel
                            value="Non-Infectious"
                            control={<Radio />}
                            label="Non Infectious"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  ) : reasonButtonId === 2 ? (
                    <Box>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={addStudentData.situation}
                          onChange={(e) => {
                            handleDataInput("situation", e.target.value);
                          }}
                        >
                          <FormControlLabel
                            value="Blunt"
                            control={<Radio />}
                            label="Blunt"
                          />
                          <FormControlLabel
                            value="Open"
                            control={<Radio />}
                            label="Open"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  ) : (
                    <Box>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={addStudentData.situation}
                          onChange={(e) => {
                            handleDataInput("situation", e.target.value);
                          }}
                        >
                          <FormControlLabel
                            value="Fainting"
                            control={<Radio />}
                            label="Fainting"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  )}
                </Box>
                <Divider sx={{ mt: 1 }} />

                <Box mt={2}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                    visit_status
                  </Typography>
                  <Box sx={{ display: "flex", mt: 1 }}>
                    {statusButton.map((data, index) => {
                      return (
                        <Button
                          key={index}
                          variant="contained"
                          fullWidth
                          sx={{
                            ...(statusButtonId === data.value
                              ? {
                                  borderRadius: 0,
                                  backgroundColor: "#3BA5B9",
                                  color: "#fff",
                                  textTransform: "capitalize",
                                  fontSize: "14px",
                                  boxShadow: 0,
                                  border: "1px solid #C7C7C7",
                                  // whiteSpace:'nowrap',
                                  "&:hover": {
                                    boxShadow: 0,
                                    color: "#fff",
                                    backgroundColor: "#3BA5B9",
                                  },
                                }
                              : {
                                  borderRadius: 0,
                                  backgroundColor: "#fff",
                                  color: "#000",
                                  textTransform: "capitalize",
                                  fontSize: "14px",
                                  boxShadow: 0,
                                  border: "1px solid #C7C7C7",
                                  // whiteSpace:'nowrap',
                                  "&:hover": {
                                    boxShadow: 0,
                                    color: "#000",
                                    backgroundColor: "#fff",
                                  },
                                }),
                            ...(index === 0
                              ? {
                                  borderTopLeftRadius: "8px",
                                  borderBottomLeftRadius: "8px",
                                }
                              : {}),
                            ...(index === statusButton.length - 1
                              ? {
                                  borderTopRightRadius: "8px",
                                  borderBottomRightRadius: "8px",
                                }
                              : {}),
                          }}
                          onClick={() => {
                            handleStatusButtonClick(data.value, data.id);
                            handleStatusModalOpen(data.value, data.id);
                            // handleDataInput("visit_status", data.value);
                          }}
                        >
                          {data.title}
                        </Button>
                      );
                    })}
                  </Box>
                </Box>
                <Divider sx={{ mt: 2 }} />

                <Box mt={2}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                    Informed Parents
                  </Typography>
                  <Box sx={{ display: "flex", mt: 1 }}>
                    {informeButtons.map((data, index) => {
                      return (
                        <Button
                          key={index}
                          variant="contained"
                          fullWidth
                          sx={{
                            ...(informButtonId === data.id
                              ? {
                                  borderRadius: 0,
                                  backgroundColor: "#3BA5B9",
                                  color: "#fff",
                                  textTransform: "capitalize",
                                  boxShadow: 0,
                                  border: "1px solid #C7C7C7",
                                  fontSize: "14px",
                                  "&:hover": {
                                    color: "#fff",
                                    backgroundColor: "#3BA5B9",
                                    boxShadow: 0,
                                  },
                                }
                              : {
                                  boxShadow: 0,
                                  border: "1px solid #C7C7C7",
                                  borderRadius: 0,
                                  backgroundColor: "#fff",
                                  color: "#000",
                                  textTransform: "capitalize",
                                  fontSize: "14px",
                                  "&:hover": {
                                    color: "#000",
                                    backgroundColor: "#fff",
                                    boxShadow: 0,
                                  },
                                }),
                            ...(index === 0
                              ? {
                                  borderTopLeftRadius: "8px",
                                  borderBottomLeftRadius: "8px",
                                }
                              : {}),
                            ...(index === informeButtons.length - 1
                              ? {
                                  borderTopRightRadius: "8px",
                                  borderBottomRightRadius: "8px",
                                }
                              : {}),
                          }}
                          onClick={() => {
                            handleInformButtonClick(data.value, data.id);
                            handleDataInput("informed_parent", data.value);
                          }}
                        >
                          {data.title}
                        </Button>
                      );
                    })}
                  </Box>
                  {informButtonId === 2 ? (
                    <Box sx={{ mt: 2 }}>
                      <FormControl size="small">
                        <Box sx={{ display: "flex", gap: 0.5 }}>
                          <Typography
                            sx={{ fontSize: "14px", fontWeight: 600 }}
                          >
                            Status
                          </Typography>
                          <Typography sx={{ color: "red" }}> *</Typography>
                        </Box>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={addStudentData.contact_status}
                          onChange={(e) => {
                            handleDataInput("contact_status", e.target.value);
                          }}
                          sx={{ width: 200, backgroundColor: "#fff" }}
                        >
                          {informStatus.map((val, index) => {
                            return (
                              <MenuItem key={index} value={val.title}>
                                {val.title}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>

                <Box mt={2}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                    Remarks
                  </Typography>
                  <TextField
                    autoComplete="off"
                    sx={{
                      mt: 1,
                      width: "100%",
                      height: "72px",
                      backgroundColor: "#fff",
                      color: "#000",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#DFDFDF",
                          height: "79px",
                        },
                        "&:hover fieldset": {
                          borderColor: "#DFDFDF",
                          height: "79px",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#DFDFDF",
                          height: "79px",
                        },
                        "& input::placeholder": {
                          color: "#878A99",
                        },
                        "& input": {
                          color: "black",
                        },
                      },
                    }}
                    value={addStudentData.remarks}
                    onChange={(e) => {
                      let newvalue = e.target.value;
                      if (newvalue.length < 1500) {
                        handleDataInput("remarks", e.target.value);
                        setError({ remarks: "" });
                      } else {
                        setError({ remarks: "only 1500 characters allowed" });
                      }
                    }}
                    error={Boolean(error.remarks)}
                    helperText={error.remarks}
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            ""
          )}
        </Box>

        {/* Footer */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "auto",
            width: "100%",
          }}
        >
          <Divider />
          <Box p={2} display="flex" justifyContent="end" gap={2}>
            <Button sx={{ color: "#3BA5B9" }} onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#3BA5B9", color: "#fff" }}
              onClick={handleAddStudent}
            >
              Add
            </Button>
          </Box>
        </Box>
      </Box>
      <Modal
        open={statusModalOpen}
        onClose={handleStatusModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} textAlign={"center"}>
          <Typography id="modal-modal-title" variant="h4" component="h2">
            Are you sure you want to mark this status ?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Once confirmed, it cannot be changed.
          </Typography>
          <Box display={"flex"} gap={1} justifyContent={"end"} mt={2}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#3BA5B9",
                color: "#fff",
                fontSize: "13px",
                fontWeight: 500,
                textTransform: "capitalize",
              }}
              onClick={handleStatusSelect}
            >
              Proceed
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#3BA5B9",
                color: "#fff",
                fontSize: "13px",
                fontWeight: 500,
                textTransform: "capitalize",
              }}
              onClick={handleStatusModalClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Drawer>
  );
};

export default DrawerComp;
