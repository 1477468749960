import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrums from "../../Components/Breadcrums";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ReorderIcon from "@mui/icons-material/Reorder";
import { LuLayoutGrid } from "react-icons/lu";
import image from "../../Asset/User.png";
import Textfield from "../../Components/Textfield";
import Datefeild from "../../Components/Datefeild";
import { useParams } from "react-router-dom";
import moment from "moment";
import TableView from "./TableView";
import CardView from "./CardView";
import { ImUserPlus } from "react-icons/im";
import DrawerComp from "./DrawerComp";
import UpdateDrawer from "./UpdateDrawer";
import mySqlservice from "../../service/mySqlservice";
import Commonservices from "../../service/Commonservices";
import { useDispatch, useSelector } from "react-redux";
import SimpleDropdown from "../../Components/SimpleDropdown";
import dayjs from "dayjs";

const Index = ({ privilege }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);

  const [updateId, setUpdateId] = useState("");
  const [studentData, setStudentData] = useState();
  const [checked, setChecked] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [updateDrawerOpen, setUpdateDrawerOpen] = useState(false);
  const [viewType, setViewtype] = useState(1);
  const [viewTypeOption, setviewTypeOption] = useState("list");
  const [pages, setpages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [totalCount, setTotalCount] = useState();

  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const url_params = useParams();
  const today = { Date: url_params.date };

  console.log(privilege);

  useEffect(() => {
    handleFetchData();
    window.scrollTo(0, 0);
  }, [updateId, pages]);

  const [formData, setFormData] = useState({
    Disease: "",
    Injury: "",
    Fainting: "",
    Status: "",
    Date: "",
    filter: true,
  });

  const handleFetchData = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        Disease: formData.Disease,
        Injury: formData.Injury,
        Fainting: formData.Fainting,
        Status: formData.Status,
        Date: formData.Date ? formData.Date : url_params.date,
        page: pages,
        pageCount: rowsPerPage,
        institution_id: userData.institution_id,
        academic_year: userData.academic_year,
      };

      const apiResult = await mySqlservice.post("get_visits", req);
      setStudentData(apiResult.data);
      setTotalCount(apiResult.totalCount);
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handledrawerOpen = () => {
    setDrawerOpen(true);
    setUpdateId("");
  };

  const handledrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleUpdatedrawerOpen = () => {
    setUpdateDrawerOpen(true);
  };

  const handleUpdatedrawerClose = () => {
    setUpdateDrawerOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInputChage = (feild, e) => {
    if (e.target) {
      setFormData((prev) => ({
        ...prev,
        [feild]: e.target.value,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [feild]: e,
      }));
    }
  };

  const handleDateChange = (date, dateString) => {
    if (date) {
      let newDate = dayjs(date);
      let timeStamp = newDate.valueOf();
      let formattedDate = moment(timeStamp).format("YYYY-MM-DD");
      handleInputChage("Date", newDate);
    } else {
      handleInputChage("Date", "");
    }
  };

  const handleCheckBoxselect = (id, isChecked) => {
    let checkedlist = [...checked];
    if (isChecked) {
      checkedlist = [...checked, id];
    } else {
      setAllChecked(false);
      checkedlist.splice(checked.indexOf(id), 1);
    }
    setChecked(checkedlist);
  };

  const handleAllChecked = (ischecked) => {
    let checkedList = [];
    if (ischecked) {
      setAllChecked(true);
      for (let index = 0; index < studentData.length; index++) {
        let element = studentData[index];
        checkedList.push(element.id);
      }
    } else {
      setAllChecked(false);
    }
    setChecked(checkedList);
  };

  const handleSubmitfilter = () => {
    handleFetchData();
  };

  const handlePageChange = (event, value) => {
    setpages(value);
  };

  const diseaseList = [
    { value: "", name: "None" },
    { value: "Infectious", name: "Infectious" },
    { value: "Non-Infectious", name: "Non-Infectious" },
  ];
  const injuryList = [
    { value: "", name: "None" },
    { value: "Blunt", name: "Blunt" },
    { value: "Open", name: "Open" },
  ];
  const faintingList = [
    { value: "", name: "None" },
    { value: "Fainting", name: "Fainting" },
  ];
  const statusList = [
    { value: "", name: "None" },
    { value: "Sent to Isolation Room", name: "Sent to Isolation Room" },
    { value: "Sent to Hospital", name: "Sent to Hospital" },
    { value: "Sent Home", name: "Sent Home" },
    { value: "Back to Class", name: "Back to Class" },
  ];

  const buttonView = [
    {
      id: 1,
      icon: (
        <ReorderIcon
          sx={
            viewType == 1
              ? { height: "20px", width: "20px", color: "#FFFFFF" }
              : { height: "20px", width: "20px", color: "#3BA5B9" }
          }
        />
      ),
    },
    {
      id: 2,
      icon: (
        <LuLayoutGrid
          style={
            viewType == 2
              ? { height: "20px", width: "20px", color: "#FFFFFF" }
              : { height: "20px", width: "20px", color: "#3BA5B9" }
          }
        />
      ),
    },
  ];

  const handleViewtype = (id) => {
    setViewtype(id);
    if (id == 1) {
      setviewTypeOption("list");
    } else {
      setviewTypeOption("card");
    }
  };

  const handleUpdatelist = (data) => {
    setUpdateId(data);
    handleUpdatedrawerOpen();
  };

  return (
    <>
      <Breadcrums
        title={[
          {
            title: "CLINIC VISITS",
            icon: "",
            link: true,
          },
        ]}
      />
      <Box p={2}>
        {viewTypeOption === "card" ? (
          <Box
            display={"flex"}
            alignItems={"center"}
            flexWrap={"wrap"}
            gap={{ xs: 0.5, xl: 2 }}
          >
            <SimpleDropdown
              label={"Select Disease"}
              value={formData.Disease}
              placeholder={"Select Disease"}
              onChange={(value) => {
                handleInputChage("Disease", value);
              }}
              style={{ minWidth: "180px" }}
              menuitem={diseaseList}
            />
            <SimpleDropdown
              // label={"Injury"}
              value={formData.Injury}
              label={"Select Injury"}
              onChange={(value) => {
                handleInputChage("Injury", value);
              }}
              style={{ minWidth: "180px" }}
              menuitem={injuryList}
            />

            <SimpleDropdown
              // label={"Fainting"}
              value={formData.Fainting}
              label={"Select Fainting"}
              onChange={(value) => {
                handleInputChage("Fainting", value);
              }}
              style={{ minWidth: "180px" }}
              menuitem={faintingList}
            />
            <SimpleDropdown
              // label={"Status"}
              value={formData.Status}
              label={"Enter Status"}
              onChange={(value) => {
                handleInputChage("Status", value);
              }}
              style={{ minWidth: "180px" }}
              menuitem={statusList}
            />

            <Datefeild
              // label={"Date"}
              placeholder={"Enter Date"}
              value={formData.Date}
              onChange={handleDateChange}
              style={{ minWidth: "180px" }}
            />

            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#3BA5B9",
                color: "#fff",
                fontSize: "14px",
                fontWeight: 500,
                textTransform: "capitalize",
                mt: "2px",
                // height: "38px",
              }}
              onClick={handleSubmitfilter}
            >
              Apply
            </Button>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                // justifyContent: "end",
                ml: { xs: "", sm: "auto" },
                // mt: 2,
                gap: 1,
              }}
            >
              {buttonView &&
                buttonView.map((val, index) => {
                  return (
                    <IconButton
                      key={index}
                      sx={
                        val.id == viewType
                          ? {
                              backgroundColor: "#3BA5B9",
                              borderRadius: "5px",
                              // height: "38px",
                              // width: "38px",
                              "&:hover": {
                                backgroundColor: "#3BA5B9",
                              },
                            }
                          : {
                              backgroundColor: "#fff",
                              borderRadius: "5px",
                              // height: "38px",
                              // width: "38px",
                              border: "1px solid #DFDFDF",
                              "&:hover": {
                                backgroundColor: "#fff",
                              },
                            }
                      }
                      onClick={() => {
                        handleViewtype(val.id);
                      }}
                    >
                      {val.icon}
                    </IconButton>
                  );
                })}
              {privilege.permission == "Edit" ? (
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: "#3BA5B9",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: 500,
                    height: "48px",
                    textTransform: "uppercase",
                    // mt: 0.5,
                    display: "flex",
                    ml: "auto",
                  }}
                  onClick={handledrawerOpen}
                >
                  <ImUserPlus style={{ height: "20px", width: "20px" }} />{" "}
                  &nbsp; Add Student
                </Button>
              ) : (
                ""
              )}
            </Box>
          </Box>
        ) : (
          ""
        )}

        {viewTypeOption == "list" ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              // ml: "auto",
              // mt: 2,
              gap: 1,
            }}
          >
            {buttonView &&
              buttonView.map((val, index) => {
                return (
                  <IconButton
                    key={index}
                    sx={
                      val.id == viewType
                        ? {
                            backgroundColor: "#3BA5B9",
                            borderRadius: "5px",
                            // height: "38px",
                            // width: "38px",
                            "&:hover": {
                              backgroundColor: "#3BA5B9",
                            },
                          }
                        : {
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                            // height: "38px",
                            // width: "38px",
                            border: "1px solid #DFDFDF",
                            "&:hover": {
                              backgroundColor: "#fff",
                            },
                          }
                    }
                    onClick={() => {
                      handleViewtype(val.id);
                    }}
                  >
                    {val.icon}
                  </IconButton>
                );
              })}
            {privilege.permission == "Edit" ? (
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#3BA5B9",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: 500,
                  height: "48px",
                  textTransform: "uppercase",
                  display: "flex",
                }}
                onClick={handledrawerOpen}
              >
                <ImUserPlus style={{ height: "20px", width: "20px" }} /> &nbsp;
                Add Student
              </Button>
            ) : (
              ""
            )}
          </Box>
        ) : (
          ""
        )}

        {viewTypeOption == "list" ? (
          <Card sx={{ p: 2, mt: 2 }}>
            <Box
              sx={{
                mb: 2,
                display: { xs: "block", md: "flex" },
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 500,
                    whiteSpace: "nowrap",
                  }}
                >
                  Clinic Visits{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px", color: "#495057" }}>
                  on {moment.utc(url_params.date).format("MMMM D, YYYY")}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={{ md: "end" }}
                flexWrap={"wrap"}
                gap={{ xs: 0.5, xl: 2 }}
              >
                <SimpleDropdown
                  label={"Select Disease"}
                  value={formData.Disease}
                  onChange={(value) => {
                    handleInputChage("Disease", value);
                  }}
                  style={{ minWidth: "180px" }}
                  menuitem={diseaseList}
                />
                <SimpleDropdown
                  // label={"Injury"}
                  value={formData.Injury}
                  label={"Select Injury"}
                  onChange={(value) => {
                    handleInputChage("Injury", value);
                  }}
                  style={{ minWidth: "180px" }}
                  menuitem={injuryList}
                />

                <SimpleDropdown
                  // label={"Fainting"}
                  value={formData.Fainting}
                  label={"Select Fainting"}
                  onChange={(value) => {
                    handleInputChage("Fainting", value);
                  }}
                  style={{ minWidth: "180px" }}
                  menuitem={faintingList}
                />
                <SimpleDropdown
                  // label={"Status"}
                  value={formData.Status}
                  label={"Select Status"}
                  onChange={(value) => {
                    handleInputChage("Status", value);
                  }}
                  style={{ minWidth: "180px" }}
                  menuitem={statusList}
                />

                <Datefeild
                  // label={"Date"}
                  placeholder={"Enter Date"}
                  value={formData.Date}
                  onChange={handleDateChange}
                  style={{ minWidth: "180px" }}
                />

                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: "#3BA5B9",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: 500,
                    textTransform: "capitalize",
                    // mt: "22px",
                    // height: "38px",
                  }}
                  onClick={handleSubmitfilter}
                >
                  Apply
                </Button>
              </Box>
            </Box>
            <TableView
              studentData={studentData}
              allChecked={allChecked}
              handleAllChecked={handleAllChecked}
              checked={checked}
              handleCheckBoxselect={handleCheckBoxselect}
              handleClick={handleClick}
              MoreHorizIcon={MoreHorizIcon}
              pages={pages}
              pageCount={pageCount}
              handlePageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              handleUpdatelist={handleUpdatelist}
              privilege={privilege}
            />
          </Card>
        ) : (
          <Box p={1} mt={2}>
            <CardView
              studentData={studentData}
              handleClick={handleClick}
              MoreHorizIcon={MoreHorizIcon}
              pages={pages}
              pageCount={pageCount}
              handlePageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              handleUpdatelist={handleUpdatelist}
              privilege={privilege}
            />
          </Box>
        )}

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>Update</MenuItem>
          <MenuItem onClick={handleClose}>Delete</MenuItem>
        </Menu>
      </Box>
      <DrawerComp
        open={drawerOpen}
        onClose={handledrawerClose}
        setStudentData={setStudentData}
        studentData={studentData}
        setUpdateId={setUpdateId}
      />
      <UpdateDrawer
        open={updateDrawerOpen}
        onClose={handleUpdatedrawerClose}
        setStudentData={setStudentData}
        studentData={studentData}
        updateId={updateId}
        setUpdateId={setUpdateId}
      />
    </>
  );
};

export default Index;
