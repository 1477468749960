import React, { useEffect, useState } from "react";
import Breadcrums from "../../Components/Breadcrums";
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  InputAdornment,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Datefeild from "../../Components/Datefeild";
import Textfield from "../../Components/Textfield";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import mysqlservice from "../../service/mySqlservice";
import Commonservices from "../../service/Commonservices";
import dayjs from "dayjs";
import { toast } from "react-toastify";

const CounselIndex = () => {
  const tablehead = [
    "Sl",
    "Name & Batch",
    "Sent By",
    "Date",
    "Off to Clinic",
    "Reached Time",
    "Status",
    "Off to Class",
    "Reached Time",
    "Action",
  ];

  const dispatch = useDispatch();
  const theme = useTheme();
  const userData = useSelector((state) => state.user);

  const [counselData, setcounselData] = useState([]);
  const [pages, setpages] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState("");
  const [search, setSearch] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const pageCount = Math.ceil(totalCount / rowsPerPage);

  useEffect(() => {
    handlesendStudent();
  }, [pages]);

  const handlesendStudent = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        institution_id: userData.institution_id,
        academic_year: userData.academic_year,
        searchKey: search,
        date: selectedDate,
        page: pages,
        pageCount: rowsPerPage,
      };

      const apiResult = await mysqlservice.post("get_counsel_visit", req);
      setcounselData(apiResult.data);
      setTotalCount(apiResult.totalCount);
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handleSearchData = (value) => {
    let searchTerm = value;
    setSearch(searchTerm);
  };

  const handleDateSelect = (date, dateString) => {
    if (date) {
      const newDate = dayjs(date);
      setSelectedDate(newDate);
    } else {
      setSelectedDate("");
    }
  };

  const handleFilter = () => {
    handlesendStudent();
  };

  const handlePageChange = (event, value) => {
    setpages(value);
  };

  const handleReachedStudent = async (value) => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        id: value.id,
        sent_by: userData.name,
        sent_by_id: userData.user_id,
        // sent_by_token: userData.token,
        app_type: "councellor",
      };
      const apiResult = await mysqlservice.post("markvisits", req);
      toast.success("Student marked as reached");
      handlesendStudent();
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handleBacktoClass = async (value) => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        id: value.id,
        sent_by: userData.name,
        sent_by_id: userData.user_id,
        // sent_by_token: userData.token,
      };
      const apiResult = await mysqlservice.post("return_class", req);
      toast.success("Student Sent Back to Class");
      handlesendStudent();
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  return (
    <>
      <Breadcrums
        title={[
          {
            title: "COUNSEL TRACKING",
            icon: "",
            link: true,
            // path: "/",
          },
        ]}
      />
      <Box p={2}>
        <Card sx={{ mt: 1, p: 1 }}>
          <Box
            sx={{ display: { xs: "block", md: "flex" }, alignItems: "center" }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: 500, pl: 1 }}>
              Counsel Tracking List
            </Typography>
            <Box
              sx={{
                display: { xs: "block", md: "flex" },
                alignItems: "center",
                ml: "auto",
                gap: 1,
              }}
            >
              <Textfield
                id="outlined-basic"
                variant="outlined"
                size="small"
                placeholder="Search..."
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ fontSize: 20, color: "#878A99" }} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: { xs: "100%", md: "auto" },
                  backgroundColor: "white",
                  borderRadius: "5px",
                  border: "1px solid #DFDFDF",
                  color: "#878A99",
                  mt: { xs: 1, md: 0 },
                  mb: { xs: 1, md: 0 },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                  "& input::placeholder": {
                    color: "#878A99",
                  },
                }}
                onChange={(e) => {
                  handleSearchData(e.target.value);
                }}
              />

              <Datefeild
                value={selectedDate}
                onChange={handleDateSelect}
                style={{
                  width: { xs: "100%", md: "auto" },
                }}
              />

              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#3BA5B9",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: 500,
                  //   height: "48px",
                  textTransform: "capitalize",
                  mt: { xs: 1, md: 0 },
                  width: { xs: "100%", md: "auto" },
                }}
                onClick={handleFilter}
              >
                Apply
              </Button>
            </Box>
          </Box>
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: "0px 0px 2px black",
              display: "flex",
              flexDirection: "column",
              mt: 2,
            }}
          >
            <Table>
              <TableHead>
                <TableRow
                  sx={
                    theme.palette.mode == "dark"
                      ? { backgroundColor: "#212529" }
                      : { backgroundColor: "#F3F6F9" }
                  }
                >
                  {/* <TableCell>
                    <Checkbox
                      size="small"
                      //   checked={allChecked}
                      //   onChange={(e) => {
                      //     handleAllChecked(e.target.checked);
                      //   }}
                    />
                  </TableCell> */}
                  {tablehead.map((data, index) => {
                    return (
                      <TableCell
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          whiteSpace: "nowrap",
                        }}
                        key={index}
                      >
                        {data}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {counselData && counselData.length ? (
                  counselData.map((val, index) => {
                    const offToClinic =
                      val.status &&
                      val.status.find(
                        (item) => item.visit_status === "Sent to Counsellor"
                      );
                    const reachedClinic =
                      val.status &&
                      val.status.find(
                        (item) => item.visit_status === "Reached Counsellor"
                      );
                    const offToClass =
                      val.status &&
                      val.status.find(
                        (item) => item.visit_status === "Back to Class"
                      );
                    const reachedClass =
                      val.status &&
                      val.status.find(
                        (item) => item.visit_status === "Reached Class"
                      );

                    return (
                      <TableRow key={index}>
                        <TableCell align="left" sx={{ color: "#915ECC" }}>
                          {index < 9 ? 0 : ""}
                          {index + 1}
                        </TableCell>
                        {/* <TableCell>
                          <Checkbox
                            size="small"
                            //   checked={allChecked}
                            //   onChange={(e) => {
                            //     handleAllChecked(e.target.checked);
                            //   }}
                          />
                        </TableCell> */}
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <Avatar
                              src={val.profile_pic}
                              sx={{ height: "32px", width: "32px" }}
                            />
                            <Box>
                              <Typography
                                sx={{ fontSize: "14px", fontWeight: 500 }}
                              >
                                {val.student_name}
                              </Typography>
                              <Typography sx={{ fontSize: "13px" }}>
                                Grade {val.class}-{val.batch}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
                        >
                          {offToClinic && offToClinic.sent_by
                            ? offToClinic.sent_by
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
                        >
                          {offToClinic && offToClinic.Added_on
                            ? moment(offToClinic.Added_on).format(
                                "MMMM D, YYYY"
                              )
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
                        >
                          {offToClinic && offToClinic.Added_on
                            ? moment(offToClinic.Added_on).format("h:mm a")
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
                        >
                          {reachedClinic && reachedClinic.Added_on
                            ? moment(reachedClinic.Added_on).format("h:mm a")
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
                        >
                          {reachedClass && reachedClass.visit_status
                            ? reachedClass.visit_status
                            : val.visit_status
                            ? val.visit_status
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
                        >
                          {offToClass && offToClass.Added_on
                            ? moment(offToClass.Added_on).format("h:mm a")
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "14px", whiteSpace: "nowrap" }}
                        >
                          {reachedClass && reachedClass.Added_on
                            ? moment(reachedClass.Added_on).format("h:mm a")
                            : "--"}
                        </TableCell>
                        <TableCell>
                          {val.visit_status === "Sent to Counsellor" ? (
                            <Button
                              variant="contained"
                              size="small"
                              sx={{
                                backgroundColor: "#3BA5B9",
                                color: "#fff",
                                fontSize: "13px",
                                fontWeight: 500,
                                // height: "48px",
                                width: "127px",
                                textTransform: "capitalize",
                                whiteSpace: "nowrap",
                              }}
                              onClick={() => {
                                handleReachedStudent(val);
                              }}
                            >
                              Mark as Reached
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              size="small"
                              sx={{
                                backgroundColor: "#3BA5B9",
                                color: "#fff",
                                fontSize: "13px",
                                fontWeight: 500,
                                // height: "48px",
                                width: "127px",
                                textTransform: "capitalize",
                                whiteSpace: "nowrap",
                              }}
                              onClick={() => {
                                handleBacktoClass(val);
                              }}
                              disabled={
                                val.visit_status === "Back to Class"
                                  ? true
                                  : false
                              }
                            >
                              Back To Class
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={10}>
                      No data to show
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Pagination
              sx={{
                m: 1,
                display: "flex",
                justifyContent: "end",
                "& .Mui-selected": {
                  color: "#fff",
                  backgroundColor: "#3BA5B9",
                },
                "& .MuiPaginationItem-page": {
                  color: "#3BA5B9",
                  "&.Mui-selected": {
                    backgroundColor: "#3BA5B9",
                    color: "#fff",
                  },
                },
              }}
              count={pageCount}
              page={pages}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </TableContainer>
        </Card>
      </Box>
    </>
  );
};

export default CounselIndex;
