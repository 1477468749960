import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AppRoute from "./Modules/Routes/AppRoute";
import { themeSettings } from "./theme";
import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { createTheme } from "@mui/material/styles";
import { CssBaseline, ThemeProvider } from "@mui/material";
import BackDrop from "./Components/Backdrop";
import { useTheme } from "@mui/material";
import FullScreenWrapper from "./Components/FullScreenWrapper";
import { ToastContainer } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  const divRef = useRef(null);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      divRef.current.requestFullscreen().catch((err) => {
        console.error(
          `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
        );
      });
    } else {
      document.exitFullscreen();
    }
  };

  const CloseButton = ({ closeToast }) => (
    <CloseIcon
      onClick={closeToast}
      style={{ transform: "translate(0px,10px)" }}
    />
  );

  return (
    <div>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BackDrop />
          <ToastContainer autoClose={1000} closeButton={CloseButton} style={{minWidth:'300px'}}/>
          <AppRoute />
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
