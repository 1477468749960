import { createSlice } from "@reduxjs/toolkit";
import { Navigate, Route } from "react-router-dom";

const initialState = {
  mode: "light",
  listItem: 1,
  user: null,
  token: null,
  class: null,
  batch: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setSideBar: (state, action) => {
      state.listItem = action.payload.listItem;
    },
    setBackDropOn: (state, action) => {
      state.back_drop = action.payload.back_drop;
    },
    getBackDropOFF: (state, action) => {
      state.back_drop = action.payload.back_drop;
    },
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      localStorage.setItem("authUser", JSON.stringify(action.payload.user));
    },
    setLogout: (state) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem("authUser");
      localStorage.removeItem('subMenuLocal')
    },
    setClass: (state, action) => {
      state.class = action.payload.class;
    },
    setBatch: (state, action) => {
      state.batch = action.payload.batch;
    },
  },
});

export const {
  setMode,
  setSideBar,
  setBackDropOn,
  getBackDropOFF,
  setLogin,
  setLogout,
  setClass,
  setBatch,
} = authSlice.actions;
export default authSlice.reducer;
