import React from "react";
import { Skeleton, Box, Typography } from "@mui/material";

const Skeltoncomp = () => {
  return (
    <Box position="relative" width={"auto"} height={"100%"}>
      <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
      <Box
        position="absolute"
        top="50%"
        left="50%"
        sx={{ transform: "translate(-50%, -50%)" }}
      >
        <Typography variant="h6" color="textSecondary">
          Under Development
        </Typography>
      </Box>
    </Box>
  );
};

export default Skeltoncomp;
