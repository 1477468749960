import { Box, Grid } from "@mui/material";
import React from "react";
import Form from "./Form";
import Brand from "../../Asset/brand.png";

const Index = () => {
  return (
    <Grid container>
      <Grid item xs={12} lg={7} sx={{ backgroundColor: "#fff" }}>
        <Form />
      </Grid>
      <Grid item xs={12} lg={5} sx={{ display: { xs: "none", md: "flex" } }}>
        <img src={Brand} alt="" style={{ height: "100vh", width: "100%" }} />
      </Grid>
    </Grid>
  );
};

export default Index;
