import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import Textfield from "../../../Components/Textfield";
import Datefeild from "../../../Components/Datefeild";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import moment from "moment";

const FamilyMedicalHistory = ({ familyMedicalHistory }) => {
  const DiseaseList = [
    { id: 1, title: "Diabetes", value: "family_diabetes" },
    { id: 2, title: "Any type of Cancer", value: "family_cancer" },
    {
      id: 3,
      title: "Any immune System problem",
      value: "family_immune_problem",
    },
    {
      id: 4,
      title: "Any Mental Health problem",
      value: "family_mental_problem",
    },
    {
      id: 5,
      title: "Others, please specify in comments",
      value: "family_other_problem",
    },
  ];

  const sufferings = [
    {
      id: 1,
      title:
        "Does the student suffer from any Hereditary Blood Disease & (e.g. Thalassemia, sickle cell anemia, Hemophilia)? If yes, specify in comments.",
      value: "blood_disease",
      comment: "blood_disease_comment",
    },
    {
      id: 2,
      title:
        "Does the student suffer from any allergy to food, dust, medicine etc.? If yes, please specify in comments",
      value: "allergy_to_food",
      comment: "allergy_to_food_comment",
    },
  ];

  const parentDeclarationList = [
    {
      id: 2,
      title: "",
      value: "",
    },
    {
      id: 3,
      title: "",
      value: "agree_health_services",
    },
  ];

  const parentDetailList = [
    {
      id: 2,
      title: "Parent / Legal Guardian Name",
      value: "declare_parent_name",
    },
    {
      id: 2,
      title: "Relationship",
      value: "declare_Relationship",
    },
    // {
    //   id: 3,
    //   title: "Parent / Legal Guardian Signature",
    //   value: "declare_Parent_Signature",
    // },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChange = (date, dateString) => {
    familyMedicalHistory.setFieldValue("declare_Date", dateString);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Typography
          sx={{
            fontSize: { md: "12px", lg: "13px", xl: "14px" },
            fontWeight: 600,
          }}
        >
          Family Medical History
        </Typography>
        <Divider sx={{ ml: 2, flexGrow: 1 }} />
      </Box>

      <Grid container spacing={2} mt={1}>
        {DiseaseList.map((val, index) => {
          return (
            <Grid item xs={12} sm={6} md={3} lg={2.4} key={index}>
              <Box>
                <Typography
                  sx={{ fontSize: { md: "12px", lg: "13px", xl: "14px" } }}
                >
                  {val.title}
                </Typography>
                <Box display={"flex"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={familyMedicalHistory.values[val.value]}
                        onChange={() => {
                          familyMedicalHistory.setFieldValue(val.value, true);
                        }}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={!familyMedicalHistory.values[val.value]}
                        onChange={(value) => {
                          familyMedicalHistory.setFieldValue(val.value, false);
                        }}
                      />
                    }
                    label="No"
                  />
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Divider sx={{ mt: 3 }} />
      <Grid container spacing={2} mt={1}>
        {sufferings.map((val, index) => {
          return (
            <Grid item xs={12} sm={6} md={3} lg={2.4} key={index}>
              <Box height={{ xs: "auto", md: "130px", xl: "110px" }}>
                <Typography
                  sx={{ fontSize: { md: "12px", lg: "13px", xl: "14px" } }}
                >
                  {val.title}
                </Typography>
                <Box display={"flex"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={familyMedicalHistory.values[val.value]}
                        onChange={() => {
                          familyMedicalHistory.setFieldValue(val.value, true);
                        }}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={!familyMedicalHistory.values[val.value]}
                        onChange={(value) => {
                          familyMedicalHistory.setFieldValue(val.value, false);
                        }}
                      />
                    }
                    label="No"
                  />
                </Box>
              </Box>
              <TextField
                id={`${index}-enquiry`}
                placeholder="Add Comment"
                sx={{
                  backgroundColor: "#fff",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#DFDFDF",
                    },
                    "&:hover fieldset": {
                      borderColor: "#DFDFDF",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#DFDFDF",
                    },
                    "& input::placeholder": {
                      color: "#71717A",
                    },
                  },
                }}
                value={familyMedicalHistory.values[val.comment]}
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  familyMedicalHistory.setFieldValue(
                    val.comment,
                    e.target.value
                  );
                }}
              />
            </Grid>
          );
        })}
      </Grid>
      <Divider sx={{ mt: 3 }} />

      <Box mt={2}>
        <Typography>
          I agree for my child to have curative and/or preventive services that
          may include first aid, screening for height, weight, vision acuity,
          hearing test, dental checkup, Comprehensive Medical Examination,
          referral to emergency room when necessary, administer emergency
          medications when needed, and applying the Healthcare Management plan
          which is planned for based on the instructions of the treating doctor
          and parents.
        </Typography>
      </Box>
      <Divider sx={{ mt: 2 }} />

      <Box mt={2}>
        <Typography
          sx={{
            fontSize: { md: "12px", lg: "13px", xl: "14px" },
            fontWeight: 600,
          }}
        >
          Parent/ Guardian approval and verification for the above mentioned
          information
        </Typography>

        <Grid container spacing={2} mt={0.5}>
          <Grid item xs={12} sm={6} md={3}>
            <Box display={"flex"} alignItems={"start"}>
              <Checkbox
                size="small"
                checked={familyMedicalHistory.values.declare_valid_info}
                onChange={(value) => {
                  familyMedicalHistory.setFieldValue(
                    "declare_valid_info",
                    value.target.checked
                  );
                }}
              />
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  transform: "translate(0px,10px)",
                }}
              >
                I certify that the above provided information are valid.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3} >
            <Box display={"flex"} alignItems={"start"}>
              <Checkbox
                size="small"
                checked={familyMedicalHistory.values.agree_health_services}
                onChange={(value) => {
                  familyMedicalHistory.setFieldValue(
                    "agree_health_services",
                    true
                  );
                }}
              />
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  transform: "translate(0px,10px)",
                }}
              >
                I agree for my child to be provided with the above mentioned
                health services according to the need.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Box display={"flex"} alignItems={"start"}>
              <Checkbox
                size="small"
                checked={!familyMedicalHistory.values.agree_health_services}
                onChange={(value) => {
                  familyMedicalHistory.setFieldValue(
                    "agree_health_services",
                    false
                  );
                }}
              />
              <Typography
                sx={{
                  fontSize: { md: "12px", lg: "13px", xl: "14px" },
                  transform: "translate(0px,10px)",
                }}
              >
                I disagree for my child to be provided with the above mentioned
                health services (in case of refusal, the above services will not
                to be offered except in emergency situations which require
                immediate intervention)
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={2}>
          {parentDetailList.map((val, index) => {
            return (
              <Grid item xs={12} sm={6} md={2.4} key={index}>
                <Textfield
                  label={val.title}
                  value={familyMedicalHistory.values[val.value] || ""}
                  onChange={(e) => {
                    familyMedicalHistory.setFieldValue(
                      val.value,
                      e.target.value
                    );
                  }}
                  placeholder={"Enter"}
                  // helperText={familyMedicalHistory.errors.school_name}
                  // error={familyMedicalHistory.errors.school_name}
                />
              </Grid>
            );
          })}
          <Grid item xs={12} sm={6} md={2.4}>
            <Datefeild
              label={"Date"}
              // isRequired={true}
              // value={familyMedicalHistory.values.declare_Date}
              onChange={onChange}
              placeholder={"Select"}
              // helperText={familyMedicalHistory.errors.school_name}
              // error={familyMedicalHistory.errors.school_name}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }} mt={3}>
        <Typography
          sx={{
            fontSize: { md: "12px", lg: "13px", xl: "14px" },
            fontWeight: 600,
          }}
        >
          Notes
        </Typography>
        <Divider sx={{ ml: 2, flexGrow: 1 }} />
      </Box>

      <Box mt={2}>
        <Typography
          sx={{
            fontSize: { md: "12px", lg: "13px", xl: "14px" },
            fontWeight: 500,
          }}
        >
          <FiberManualRecordIcon sx={{ height: "8px", width: "8px" }} /> Please
          attach medical reports about the students health problem, if any
        </Typography>

        <Typography
          sx={{
            fontSize: { md: "12px", lg: "13px", xl: "14px" },
            fontWeight: 500,
          }}
        >
          <FiberManualRecordIcon sx={{ height: "8px", width: "8px" }} /> It is
          the responsibility of the Student's Parent/ Guardian to inform the
          school clinic of any changes in the student's health status and submit
          medical reports accordingly to update the Student's Medical Record at
          School.
        </Typography>
      </Box>

      <Box mt={6}>
        <Typography
          sx={{
            fontSize: { md: "12px", lg: "13px", xl: "14px" },
            fontWeight: 600,
          }}
        >
          **Please contact the School Doctor/Nurse if there are any queries**
        </Typography>
      </Box>
    </Box>
  );
};

export default FamilyMedicalHistory;
