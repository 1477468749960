import {
  AppBar,
  Box,
  Button,
  Grid,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Gov_Dubai from "../../Asset/Gov_Dubai.png";
import DubaiHealth from "../../Asset/DubaiHealth.png";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepIcon from "@mui/material/StepIcon";
import { useFormik } from "formik";
import * as Yup from "yup";
import { styled } from "@mui/material/styles";
import PersonalDetailpage from "./VaccinationConsentForm/PersonalDetailpage";
import VaccineDetailpage from "./VaccinationConsentForm/VaccineDetailpage";
import Commonservices from "../../service/Commonservices";
import { useDispatch } from "react-redux";
import mysqlservice from "../../service/mySqlservice";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

const CustomStepIcon = styled(StepIcon)(({ theme, active, completed }) => ({
  color: active || completed ? "green" : "white",
  border: "2px solid #A1AEBE",
  borderRadius: "50%",
  height: "32px",
  width: "32px",
  "& .MuiStepIcon-text": {
    fill: active || completed ? "#3BA5B9" : "#000",
    fontSize: "14px",
  },
  "&.Mui-active": {
    color: "#fff",
    border: "2px solid #3BA5B9",
    borderRadius: "50%",
  },
  "&.Mui-completed": {
    color: "#fff",
    border: "2px solid #A1AEBE",
    borderRadius: "50%",
    fill: "#3BA5B9",
  },
}));

const steps = ["Step 1", "Step 2"];

const VaccineFormIndex = () => {
  const ismobilescreen = useMediaQuery("(max-width: 900px)");
  const [activeStep, setActiveStep] = React.useState(0);
  const [fullData, setFullData] = useState({});
  const [vaccineData, setvaccineData] = useState({});

  const dispatch = useDispatch();
  const url_params = useParams();

  useEffect(() => {
    getconsentData();
  }, []);

  const getconsentData = async () => {
    try {
      Commonservices.loaderON(dispatch);

      let req = {
        id: url_params.id,
        token: url_params.token,
      };
      const apiResult = await mysqlservice.post("get_consent", req);
      if (apiResult.status == 200) {
        setvaccineData(apiResult.result);
      } else {
        setvaccineData({});
      }
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  let combinedGrade = `${vaccineData.class} - ${vaccineData.batch}`;

  const studentValidation = Yup.object().shape({
    student_full_name: Yup.string().required("Required"),
    gender: Yup.string().required("Required"),
    emirates_id: Yup.string().required("Required"),
    dob: Yup.string().required("Required"),
    school_name: Yup.string().required("Required"),
    grade: Yup.string().required("Required"),
    year: Yup.string().required("Required"),
  });

  const studentData = useFormik({
    enableReinitialize: true,
    // validationSchema: studentValidation,
    initialValues: {
      student_full_name: vaccineData.student_name || "",
      gender:
        vaccineData.gender === "M" || vaccineData.gender === "m"
          ? "Male"
          : "Female" || "",
      emirates_id: "",
      dob: vaccineData.dob || "",
      school_name: vaccineData.institution_name || "",
      grade: combinedGrade || "",
      year: "",

      reaction_for_vaccine: false,
      reaction_for_vaccine_comment: "",
      have_any_allergy: false,
      have_any_allergy_comment: "",
      have_health_condition: false,
      have_health_condition_comment: "",
      have_taken_steroids: false,
      have_taken_steroids_comment: "",
      have_blood_transfusion: false,
      have_blood_transfusion_comment: "",
      have_medication: false,
      have_medication_comment: "",
      have_infection: false,
      have_infection_comment: "",

      have_vaccince: false,
      prev_vaccine_name: "",
      prev_vaccine_date: "",

      have_seizure: false,
      family_disesase: false,

      parent_name: vaccineData.parent_name,
      relationship: "",
      // parent_signature: "",
      declare_Date: "",

      agree_consent: true,

      vaccination_date: moment(vaccineData.vaccination_date).format(
        "YYYY-MM-DD"
      ),
      vaccination_type: vaccineData.vaccination_type,
      vaccination_dose: vaccineData.dose,

      administered_previously: "",
      condition_for_disagree: "",
      other_reason: "",
      other_reason_comment: "",
      update_immunisation: "",
    },
    onSubmit: (values) => {
      let result = {};
      result = { ...values };
      result.consent_id = url_params.id;
      handleSaveConsent(result);
      handleNext();
    },
  });

  const handleSaveConsent = async (values) => {
    try {
      Commonservices.loaderON(dispatch);
      let apiResult = await mysqlservice.post("save_vaccine_form", values);
      if (apiResult.status == 200) {
        toast.success(apiResult.message);
      } else {
        toast.error(apiResult.message);
      }
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handleResetForm = () => {
    studentData.resetForm();
  };

  return (
    <Box>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#fff",
          minHeight: "128px",
          boxShadow: "0px 5px 5px #C4C4C417",
        }}
      >
        <Box
          sx={{
            display: { xs: "block", md: "flex" },
            alignItems: "center",
            justifyContent: { xs: "center", md: "space-between" },
            px: 2,
            pt: 3,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <img
              src={Gov_Dubai}
              alt=""
              style={
                ismobilescreen
                  ? { height: "46px", width: "104px" }
                  : { height: "76px", width: "172px" }
              }
            />
            {ismobilescreen ? (
              <img
                src={DubaiHealth}
                alt=""
                style={
                  ismobilescreen
                    ? { height: "46px", width: "104px" }
                    : { height: "76px", width: "172px" }
                }
              />
            ) : (
              ""
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              mt: { xs: 3, md: 0 },
            }}
          >
            <Typography
              sx={{ fontSize: { xs: "14px", md: "20px" }, fontWeight: 600 }}
            >
              PUBLIC HEALTH PROTECTION DEPARTMNET SCHOOL HEALTH SECTION
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "14px", md: "18px" },
                color: "#4B4B5B",
                textTransform: "uppercase",
                mt: { xs: "8px", md: "0px" },
              }}
            >
              Student Medical Form & General Consent
            </Typography>
          </Box>
          {ismobilescreen ? (
            ""
          ) : (
            <img
              src={DubaiHealth}
              alt=""
              style={
                ismobilescreen
                  ? { height: "46px", width: "104px" }
                  : { height: "76px", width: "172px" }
              }
            />
          )}
        </Box>
      </AppBar>
      <Box>
        <Box sx={{ width: "100%", p: 4 }}>
          <Stepper
            activeStep={activeStep}
            sx={
              ismobilescreen
                ? { width: "100%", margin: "auto" }
                : { width: "40%", margin: "auto" }
            }
            alternativeLabel
          >
            {steps.map((label) => (
              <Step sx={{ color: "#3BA5B9" }} key={label}>
                <StepLabel StepIconComponent={CustomStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === steps.length ? (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              height={"50vh"}
            >
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you're finished
              </Typography>
            </Box>
          ) : (
            <Box mt={6}>
              {activeStep === 0 && (
                <Box sx={{ mt: 2, mb: 1 }}>
                  <PersonalDetailpage studentData={studentData} />
                </Box>
              )}
              {activeStep === 1 && (
                <Box sx={{ mt: 2, mb: 1 }}>
                  <VaccineDetailpage studentData={studentData} />
                </Box>
              )}

              <Box sx={{ display: "flex", pt: 2, justifyContent: "end" }}>
                <Button
                  color="inherit"
                  onClick={handleResetForm}
                  sx={{ mr: 1 }}
                >
                  Clear Form
                </Button>

                {activeStep === 0 ? (
                  ""
                ) : (
                  <Button
                    sx={{
                      backgroundColor: "#fff",
                      color: "#000",
                      border: "1px solid #E4E4E7",
                      mr: 1,
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#000",
                        border: "1px solid #E4E4E7",
                      },
                    }}
                    variant="outlined"
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                )}

                <Button
                  onClick={() => {
                    if (activeStep === 0) {
                      handleNext();
                    } else if (activeStep === 1) {
                      studentData.handleSubmit();
                    }
                    //  else {
                    //   familyMedicalHistory.handleSubmit();
                    // }
                  }}
                  // onClick={handleNext}
                  variant="outlined"
                  sx={{
                    backgroundColor: "#3BA5B9",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#3BA5B9", color: "#fff" },
                  }}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default VaccineFormIndex;
