import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Pagination,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";
import IconLogo from "../../Components/IconLogo";
import Update from "../../Asset/Update.png";
import Trash from "../../Asset/Trash.png";

const CardView = ({
  studentData,
  pages,
  pageCount,
  handlePageChange,
  rowsPerPage,
  handleUpdatelist,
  privilege,
}) => {
  const url_params = useParams();
  const theme = useTheme();
  return (
    <>
      <Grid container spacing={1}>
        {studentData && studentData.length
          ? studentData.map((val, index) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={index}>
                  <Card sx={{ height: "280px" }}>
                    <CardContent>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Avatar
                          src={val.profile_pic}
                          sx={{ height: "32px", width: "32px" }}
                        />
                        <Box>
                          <Typography
                            sx={
                              theme.palette.mode === "dark"
                                ? { fontSize: "15px", color: "#fff" }
                                : { fontSize: "15px", color: "#000000" }
                            }
                          >
                            {val.student_name}
                          </Typography>
                          {val.situation ? (
                            <Chip
                              sx={
                                val.situation === "Non-Infectious" ||
                                val.situation === "Blunt"
                                  ? {
                                      borderRadius: "5px",
                                      backgroundColor: "#DEEFFF",
                                      color: "#0071DA",
                                    }
                                  : {
                                      borderRadius: "5px",
                                      backgroundColor: "#FCD6D2",
                                      color: "#E03B26",
                                    }
                              }
                              size="small"
                              label={val.situation}
                            />
                          ) : (
                            "--"
                          )}
                        </Box>
                        {privilege == "Edit" ? (
                          <Box display={"flex"} ml={"auto"} gap={0.5}>
                            <Tooltip title="Update">
                              <IconButton
                                sx={{
                                  backgroundColor: "#D0F2F9",
                                  borderRadius: "5px",
                                  height: "30px",
                                  width: "30px",
                                  "&:hover": {
                                    backgroundColor: "#D0F2F9",
                                  },
                                }}
                                onClick={() => {
                                  handleUpdatelist(val);
                                }}
                              >
                                <IconLogo
                                  image={Update}
                                  size="22"
                                  radius="0"
                                  right="12px"
                                  top="-4px"
                                />
                              </IconButton>
                            </Tooltip>
                            {/* <Tooltip title="Delete">
                          <IconButton
                            sx={{
                              backgroundColor: "#EE6352",
                              borderRadius: "5px",
                              height: "30px",
                              width: "30px",
                              "&:hover": {
                                backgroundColor: "#EE6352",
                              },
                            }}
                          >
                            <IconLogo
                              image={Trash}
                              size="22"
                              radius="0"
                              right="12px"
                              top="-4px"
                            />
                          </IconButton>
                        </Tooltip> */}
                          </Box>
                        ) : (
                          ""
                        )}
                      </Box>
                      <Grid container mt={2}>
                        <Grid item xs={4}>
                          <Typography sx={{ fontSize: "12px" }}>
                            Class & Batch
                          </Typography>
                          <Typography sx={{ fontSize: "14px" }}>
                            Grade {val.class}-{val.batch}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography sx={{ fontSize: "12px" }}>
                            Reason
                          </Typography>
                          <Typography sx={{ fontSize: "14px" }}>
                            {val.reason ? val.reason : "--"}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography sx={{ fontSize: "12px" }}>
                            Date
                          </Typography>
                          <Typography sx={{ fontSize: "14px" }}>
                            {moment.utc(url_params.date).format("MMMM D, YYYY")}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container mt={1}>
                        <Grid item xs={5}>
                          <Typography sx={{ fontSize: "12px" }}>
                            Visits
                          </Typography>
                          <Typography sx={{ fontSize: "14px" }}>
                            {val.visit_type ? val.visit_type : "--"}
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography sx={{ fontSize: "12px" }}>
                            Status
                          </Typography>
                          {val.visit_status ? (
                            <Chip
                              sx={{ borderRadius: "5px" }}
                              size="small"
                              label={val.visit_status}
                            />
                          ) : (
                            "--"
                          )}
                        </Grid>
                      </Grid>

                      <Grid container mt={1}>
                        <Grid item xs={6}>
                          <Typography sx={{ fontSize: "12px" }}>
                            Remarks
                          </Typography>
                          <Typography sx={{ fontSize: "14px" }}>
                            {val.remarks
                              ? val.remarks.length > 10
                                ? `${val.remarks.substring(0, 10)}...`
                                : val.remarks
                              : "--"}
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography sx={{ fontSize: "12px" }}>
                            Informed Parents
                          </Typography>
                          <Typography sx={{ fontSize: "14px" }}>
                            {val.informed_parent ? "Yes" : "No"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })
          : ""}
      </Grid>
      {studentData && studentData.length > 0 ? (
        <Pagination
          sx={{
            m: 1,
            display: "flex",
            justifyContent: "end",
            "& .Mui-selected": {
              color: "#fff",
              backgroundColor: "#3BA5B9",
            },
            "& .MuiPaginationItem-page": {
              color: "#3BA5B9",
              "&.Mui-selected": {
                backgroundColor: "#3BA5B9",
                color: "#fff",
              },
            },
          }}
          count={pageCount}
          page={pages}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
        />
      ) : (
        ""
      )}
    </>
  );
};

export default CardView;
