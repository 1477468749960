import {
  Box,
  Button,
  Card,
  Chip,
  Drawer,
  Modal,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrums from "../../Components/Breadcrums";
import PropTypes from "prop-types";
import UserManagementTable from "./UserManagementTable";
import { ImUserPlus } from "react-icons/im";
import RolesnPermission from "./RolesnPermission";
import AddUserDrawer from "./AddUserDrawer";
import { useFormik } from "formik";
import Commonservices from "../../service/Commonservices";
import { useDispatch, useSelector } from "react-redux";
import mysqlservice from "../../service/mySqlservice";
import AddRoleDrawer from "./AddRoleDrawer";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SettingsIndex = () => {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [roleOpen, setRoleOpen] = useState(false);
  const [refresh, setrefresh] = useState(false);

  const userData = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRoleOpen = () => setRoleOpen(true);
  const handleRoleClose = () => setRoleOpen(false);

  useEffect(() => {
    handleuserDetails();
    handleUserRoles();
    setrefresh(false);
  }, [refresh]);

  const handleuserDetails = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        institution_id: userData.institution_id,
      };

      const apiResults = await mysqlservice.post("get_user_details", req);
      setUsers(apiResults.data.userdata);
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handleUserRoles = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        institution_id: userData.institution_id,
      };
      const apiResult = await mysqlservice.post("get_role", req);
      setRoleData(apiResult.data);
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  console.log(roleData);

  return (
    <>
      <Breadcrums
        title={[
          {
            title: "USER MANAGEMENT",
            icon: "",
            link: true,
            path: "/home",
          },
        ]}
      />
      <Box mt={1} mb={2}>
        <Box sx={{ width: "100%", pl: 2 }}>
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{ style: { backgroundColor: "#3BA5B9" } }}
            >
              <Tab
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography>Users</Typography>
                    <Chip
                      label={users && users.length ? users.length : 0}
                      size="small"
                      sx={{
                        ml: 1,
                        backgroundColor: "#EFE1DF",
                        color: "#F06548",
                      }}
                    />
                  </Box>
                }
                sx={{
                  "&.Mui-selected": {
                    color: "#3BA5B9",
                  },
                }}
                {...a11yProps(0)}
              />
              <Tab
                label={`Roles & Permissions`}
                sx={{
                  "&.Mui-selected": {
                    color: "#3BA5B9",
                  },
                }}
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Card sx={{ mt: 2 }}>
              <Box
                p={1.5}
                width={"100%"}
                display={"flex"}
                justifyContent={"end"}
              >
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: "#3BA5B9",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: 500,
                    height: "48px",
                    textTransform: "uppercase",
                    mt: 0.5,
                    display: "flex",
                  }}
                  onClick={() => {
                    handleOpen();
                  }}
                >
                  <ImUserPlus style={{ height: "20px", width: "20px" }} />{" "}
                  &nbsp; ADD USER
                </Button>
              </Box>
              <UserManagementTable users={users} setrefresh={setrefresh} />
            </Card>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Card sx={{ mt: 2 }}>
              <Box
                p={1.5}
                width={"100%"}
                display={"flex"}
                justifyContent={"end"}
              >
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: "#3BA5B9",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: 500,
                    height: "48px",
                    textTransform: "uppercase",
                    mt: 0.5,
                    display: "flex",
                  }}
                  onClick={() => {
                    handleRoleOpen();
                  }}
                >
                  <ImUserPlus style={{ height: "20px", width: "20px" }} />{" "}
                  &nbsp; ADD ROLE
                </Button>
              </Box>
              <RolesnPermission users={roleData} handleUserRoles={handleUserRoles}/>
            </Card>
          </CustomTabPanel>
        </Box>
        <AddUserDrawer
          open={open}
          handleClose={handleClose}
          handleOpen={handleOpen}
          setrefresh={setrefresh}
        />

        <AddRoleDrawer
          open={roleOpen}
          handleClose={handleRoleClose}
          handleOpen={handleRoleOpen}
        />
      </Box>
    </>
  );
};

export default SettingsIndex;
