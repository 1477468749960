import {
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrums from "../../Components/Breadcrums";
import Datefeild from "../../Components/Datefeild";
import SimpleDropdown from "../../Components/SimpleDropdown";
import { useDispatch, useSelector } from "react-redux";
import Commonservices from "../../service/Commonservices";
import mysqlservice from "../../service/mySqlservice";
import { useFormik } from "formik";
import dayjs from "dayjs";

const ClasswiseReport = () => {
  const [classviseData, setclassviseData] = useState([]);
  const [pages, setpages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState();
  const [allClass, setAllClass] = useState([]);
  const [allBatch, setAllBatch] = useState([]);

  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const userData = useSelector((state) => state.user);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      from: "",
      to: "",
      class: "",
      batch: "",
    },
    onSubmit: (value) => {
      handleClassViseReport();
    },
  });

  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    handleclassNbatch();
    handleClassViseReport();
  }, [pages]);

  const handleClassViseReport = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        institution_id: userData.institution_id,
        academic_year: userData.academic_year,
        page: pages,
        pageCount: rowsPerPage,
        from: formik.values.from,
        to: formik.values.to,
        class: formik.values.class,
        batch: formik.values.batch,
      };
      let apiResult = await mysqlservice.post("get_class_visit_details", req);
      setclassviseData(apiResult.data);
      setTotalCount(apiResult.totalCount);
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handleclassNbatch = async () => {
    try {
      Commonservices.loaderON(dispatch);
      const apiResult = await mysqlservice.get("get_batch_details");

      setAllBatch(apiResult.data.batches);
      setAllClass(apiResult.data.classes);
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };


  const handlePageChange = (event, value) => {
    setpages(value);
  };

  const handlefromDate = (date, dateString) => {
    if (date) {
      let newDate = dayjs(date);
      formik.setFieldValue("from", newDate);
    } else {
      formik.setFieldValue("from", "");
    }
  };

  const handletoDate = (date, dateString) => {
    if (date) {
      let newDate = dayjs(date);
      formik.setFieldValue("to", newDate);
    } else {
      formik.setFieldValue("to", "");
    }
  };

  const totals = classviseData.reduce(
    (acc, item) => {
      acc.totalInfectious += item.disease.infectious;
      acc.totalNonInfectious += item.disease.non_infectious;
      acc.totalBlunt += item.injury.blunt;
      acc.totalOpen += item.injury.open;
      acc.totalFainting += item.fainting;
      acc.alltotal += item.total;
      acc.refertohospitaltotal += item.referred_to_hospital;
      acc.othertotal += item.sent_to_isolation_or_home;
      return acc;
    },
    {
      totalInfectious: 0,
      totalNonInfectious: 0,
      totalBlunt: 0,
      totalOpen: 0,
      totalFainting: 0,
      alltotal: 0,
      refertohospitaltotal: 0,
      othertotal: 0,
    }
  );

  return (
    <>
      <Breadcrums
        title={[
          {
            title: "CLASSWISE CLINIC VISIT REPORT",
            icon: "",
            link: true,
            // path: "/",
          },
        ]}
      />
      <Box sx={{ p: 2 }}>
        <Card sx={{ p: 2 }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            flexWrap={"wrap"}
            gap={{ xs: 0.5, xl: 2 }}
          >
            <Datefeild
              // label={"Date"}
              placeholder={"From"}
              style={{ minWidth: "126px" }}
              value={formik.values.from}
              onChange={handlefromDate}
            />

            <Datefeild
              // label={"Date"}
              placeholder={"To"}
              style={{ minWidth: "126px" }}
              value={formik.values.to}
              onChange={handletoDate}
            />

            <FormControl size="small" sx={{ width: "126px" }}>
              <InputLabel id="demo-simple-select-label">Class</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.class}
                label="Select Class"
                onChange={(e) => {
                  formik.setFieldValue("class", e.target.value);
                }}
              >
                {allClass && allClass.length
                  ? allClass.map((data, index) => {
                      return (
                        <MenuItem value={data.class} key={index}>
                          {data.class}
                        </MenuItem>
                      );
                    })
                  : ""}
              </Select>
            </FormControl>

            <FormControl size="small" sx={{ width: "126px" }}>
              <InputLabel id="demo-simple-select-label">Batch</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.batch}
                label="Select Class"
                onChange={(e) => {
                  formik.setFieldValue("batch", e.target.value);
                }}
              >
                {allBatch && allBatch.length
                  ? allBatch.map((data, index) => {
                      return (
                        <MenuItem value={data.batch} key={index}>
                          {data.batch}
                        </MenuItem>
                      );
                    })
                  : ""}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#3BA5B9",
                color: "#fff",
                fontSize: "14px",
                fontWeight: 500,
                textTransform: "capitalize",
                mt: "2px",
                // height: "38px",
              }}
              onClick={() => formik.handleSubmit()}
            >
              Apply
            </Button>

            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                ml: { xs: "", sm: "auto" },
                gap: 1,
              }}
            >
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#3BA5B9",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: 500,
                  textTransform: "uppercase",
                }}
              >
                Generate
              </Button>
            </Box> */}
          </Box>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow
                  sx={
                    theme.palette.mode == "dark"
                      ? { backgroundColor: "#212529" }
                      : { backgroundColor: "#F3F6F9" }
                  }
                >
                  <TableCell
                    align="center"
                    rowSpan={2}
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    CLASS
                  </TableCell>
                  <TableCell
                    align="center"
                    rowSpan={2}
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    BATCH
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={2}
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    DISEASE
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={2}
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    INJURY
                  </TableCell>
                  <TableCell
                    align="center"
                    rowSpan={2}
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    FAINTING
                  </TableCell>
                  <TableCell
                    align="center"
                    rowSpan={2}
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    TOTAL
                  </TableCell>
                  <TableCell
                    align="center"
                    rowSpan={2}
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    RFFERED TO HOSPITAL
                  </TableCell>
                  <TableCell
                    align="center"
                    rowSpan={2}
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    SENT TO ISOLATION ROOM / SENT HOME
                  </TableCell>
                  <TableCell
                    align="center"
                    rowSpan={2}
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    REMARK / NOTES
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={
                    theme.palette.mode == "dark"
                      ? { backgroundColor: "#212529" }
                      : { backgroundColor: "#F3F6F9" }
                  }
                >
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    Infectious
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    Non Infectious
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    Blunt
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px solid #DFDFDF",
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                  >
                    Open
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {classviseData && classviseData.length
                  ? classviseData.map((val, index) => (
                      <TableRow key={index}>
                        <TableCell align="center" sx={{ color: "#915ECC" }}>
                          {val.class}
                        </TableCell>
                        <TableCell align="center">{val.batch}</TableCell>
                        <TableCell align="center">
                          {val.disease.infectious}
                        </TableCell>
                        <TableCell align="center">
                          {val.disease.non_infectious}
                        </TableCell>
                        <TableCell align="center">{val.injury.blunt}</TableCell>
                        <TableCell align="center">{val.injury.open}</TableCell>
                        <TableCell align="center">{val.fainting}</TableCell>
                        <TableCell align="center">{val.total}</TableCell>
                        <TableCell align="center">
                          {val.referred_to_hospital}
                        </TableCell>
                        <TableCell align="center">
                          {val.sent_to_isolation_or_home}
                        </TableCell>
                        <TableCell align="center">{val.remarks}</TableCell>
                      </TableRow>
                    ))
                  : ""}
                <TableRow>
                  <TableCell colSpan={2} sx={{ fontWeight: 600 }}>
                    TOTAL
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    {totals.totalInfectious}
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    {totals.totalNonInfectious}
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    {totals.totalBlunt}
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    {totals.totalOpen}
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    {totals.totalFainting}
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    {totals.alltotal}
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    {totals.refertohospitaltotal}
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    {totals.othertotal}
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    remarks
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            sx={{
              m: 1,
              display: "flex",
              justifyContent: "end",
              "& .Mui-selected": {
                color: "#fff",
                backgroundColor: "#3BA5B9",
              },
              "& .MuiPaginationItem-page": {
                color: "#3BA5B9",
                "&.Mui-selected": {
                  backgroundColor: "#3BA5B9",
                  color: "#fff",
                },
              },
            }}
            count={pageCount}
            page={pages}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </Card>
      </Box>
    </>
  );
};

export default ClasswiseReport;
