import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrums from "../../Components/Breadcrums";
import { useDispatch, useSelector } from "react-redux";
import APPCONSTANTS from "../../config/constant";
import { useNavigate } from "react-router-dom";
import Commonservices from "../../service/Commonservices";
import ApiService from "../../service/apiService";
import { setBatch, setClass } from "../../state";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { useTheme } from "@emotion/react";
import moment from "moment";
import { HiOutlineBellAlert } from "react-icons/hi2";
import { IoIosHourglass } from "react-icons/io";
import SimpleDropdown from "../../Components/SimpleDropdown";
import Datefeild from "../../Components/Datefeild";
import dayjs from "dayjs";
import SendAllDrawer from "./SendAllDrawer";
import { toast } from "react-toastify";
import mysqlservice from "../../service/mySqlservice";
import AlertDrawer from "./AlertDrawer";

const NotificationIndex = () => {
  useEffect(() => {
    handleclassNbatch();
  }, []);

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const classData = useSelector((state) => state.class);
  const batchData = useSelector((state) => state.batch);
  const classInRoman = APPCONSTANTS.classInRoman;
  const theme = useTheme();

  const [formData, setFormData] = useState({
    alert_type: "",
    vaccination_type: "",
    dose: "",
    class: "",
    batch: "",
    date: "",
  });

  const [generalStudentList, setGeneralStudentList] = useState([]);
  const [filterdStudentList, setfilterdStudentList] = useState([]);
  const [allClass, setAllClass] = useState([]);
  const [classNbatchData, setClassNBatchData] = useState([]);
  const [allBatch, setAllBatch] = useState([]);
  const [selectedClass, setselectedClass] = useState("");
  const [selectedBatch, setselectedBatch] = useState("");

  const [checked, setchecked] = useState([]);
  const [allchecked, setAllchecked] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [viewOpen, setViewOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");

  const alertType = [
    { id: 1, name: "None", value: "" },
    { id: 2, name: "Pre-Vaccination Alerts", value: "Pre-Vaccination Alerts" },
    {
      id: 3,
      name: "Post-Vaccination Follow-ups",
      value: "Post-Vaccination Follow-ups",
    },
  ];

  const vaccinationTypes = [
    { id: 1, name: "None", value: "" },
    { id: 2, name: "Hepatitis B vaccine", value: "Hepatitis B vaccine" },
    {
      id: 3,
      name: "MMR (Measles, Mumps, Rubella)",
      value: "MMR (Measles, Mumps, Rubella)",
    },
    {
      id: 4,
      name: "OPV Vaccine (Oral Polio Vaccine)",
      value: "OPV Vaccine (Oral Polio Vaccine)",
    },
    { id: 5, name: "DTaP-lPV Vaccine", value: "DTaP-lPV Vaccine" },
    { id: 6, name: "Varicella Vaccine", value: "Varicella Vaccine" },
    {
      id: 7,
      name: "HPV (Human Papillomavirus) Vaccine",
      value: "HPV (Human Papillomavirus) Vaccine",
    },
  ];

  const Doses = [
    { id: 1, name: "None", value: "" },
    { id: 2, name: 1, value: 1 },
    { id: 3, name: 2, value: 2 },
    { id: 4, name: 3, value: 3 },
  ];

  const handleInputChage = (feild, value) => {
    setFormData((prev) => ({
      ...prev,
      [feild]: value,
    }));
  };

  const handleDateChange = (date, dateString) => {
    if (date) {
      let newDate = dayjs(date);
      let timeStamp = newDate.valueOf();
      let formattedDate = moment(timeStamp).format("YYYY-MM-DD");
      handleInputChage("date", newDate);
    } else {
      handleInputChage("date", "");
    }
  };

  const handleclassNbatch = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        inst_id: userData.institution_id,
        acdyr: userData.academic_year,
      };

      let apiResult = await ApiService.post(
        "bm-school/Docme-UI/registration/get_student_class",
        req
      );

      const combinedClasses = {};

      apiResult.forEach((item) => {
        const foundClass = APPCONSTANTS.classInRoman.find(
          (c) => c.roman === item.class
        );

        if (foundClass) {
          const classNumber = foundClass.number;

          if (combinedClasses[classNumber]) {
            combinedClasses[classNumber].batch = [
              ...new Set([
                ...combinedClasses[classNumber].batch,
                ...item.batch,
              ]),
            ];
          } else {
            combinedClasses[classNumber] = {
              class: classNumber,
              batch: item.batch,
            };
          }
          combinedClasses[classNumber].batch =
            combinedClasses[classNumber].batch.sort();
        }
      });

      const updatedClassNbatch = Object.values(combinedClasses);

      const sortedClassNbatch = updatedClassNbatch.sort((a, b) => {
        if (a.class === "KG 1") return -1;
        if (b.class === "KG 1") return 1;
        if (a.class === "KG 2") return -1;
        if (b.class === "KG 2") return 1;
        return a.class - b.class;
      });

      const classes = sortedClassNbatch.map((data) => {
        return {
          value: data.class,
          class: data.class,
        };
      });
      setClassNBatchData(sortedClassNbatch);
      setAllClass(classes);

      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handleClassSelect = (value) => {
    setselectedClass(value);
    handleInputChage("class", value);
    dispatch(
      setClass({
        class: value,
      })
    );
    const Allbatches =
      classNbatchData &&
      classNbatchData.find((data) => data.class === value)?.batch;

    const batches = Allbatches.map((data) => {
      return {
        value: data,
        batch: data,
      };
    });

    setAllBatch(batches);
  };

  const handleBatchSelect = async (value) => {
    setselectedBatch(value);
    handleInputChage("batch", value);
    dispatch(
      setBatch({
        batch: value,
      })
    );
    await handleStudentData(value);
  };

  const handleStudentData = async (batch) => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        class: selectedClass ? selectedClass : classData,
        batch: batch ? batch : batchData,
        inst_id: userData.institution_id,
        acdyr: userData.academic_year,
      };
      const apiResult = await ApiService.post(
        "bm-school/Docme-UI/registration/get_student_data",
        req
      );

      for (let val of apiResult) {
        let parts = val && val.batch.split("/");

        let romanClass = parts[0];
        let partBatch = parts[1];

        let partClass = await classInRoman.find(
          (val) => val.roman == romanClass
        );
        val.class = partClass.number;
        val.batch_detail = partBatch;
      }

      await getNotificationData(batch, apiResult);
      // setselectedClass("");
      // setselectedBatch("");

      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      // setselectedClass("");
      // setselectedBatch("");
      Commonservices.loaderOFF(dispatch);
    }
  };

  const getNotificationData = async (batch, studentData) => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        class: classData ? classData : selectedClass,
        batch: batch ? batch : batchData,
        institution_id: userData.institution_id,
        academic_year: userData.academic_year,
        alert_type: formData.alert_type,
        vaccination_type: formData.vaccination_type,
      };
      let apiResult = await mysqlservice.post("get_notification", req);
      let res_val = apiResult && apiResult.data ? apiResult.data : [];

      let combinedData = studentData.map((data) => {
        const accDetail = res_val.find(
          (val) => val.admission_no == data.Admn_No
        );
        return {
          ...data,
          ...(accDetail
            ? {
                alert_id: accDetail.id,
                alert_type: accDetail.alert_type,
                vaccination_type: accDetail.vaccination_type,
                vaccination_date: accDetail.vaccination_date,
                notification_status: true,
                subject: accDetail.subject,
                content: accDetail.content,
                dose: accDetail.dose,
              }
            : {}),
        };
      });

      setGeneralStudentList(combinedData);
      setfilterdStudentList(combinedData);
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handleCheckboxSelect = (id, isChecked) => {
    let checkedlist = [...checked];
    if (isChecked) {
      checkedlist = [...checked, id];
    } else {
      setAllchecked(false);
      checkedlist.splice(checked.indexOf(id), 1);
    }
    setchecked(checkedlist);
  };

  const handleAllChecked = (ischecked) => {
    let checkedList = [];
    if (ischecked) {
      setAllchecked(true);
      for (let index = 0; index < filterdStudentList.length; index++) {
        let element = filterdStudentList[index];
        checkedList.push(element);
      }
    } else {
      setAllchecked(false);
    }
    setchecked(checkedList);
  };

  const handleSendAllDrawerOpen = () => {
    if (checked && checked.length) {
      setDrawerOpen(true);
    } else {
      toast.error("Have to select atleast one student to continue");
    }
  };

  const handleSendAllDrawerClose = () => {
    setDrawerOpen(false);
  };

  const HandleSendOne = (val) => {
    setDrawerOpen(true);
    let checkedlist = [...checked, val];
    setchecked(checkedlist);
  };

  const handleViewDrawerOpen = (val) => {
    if (val.notification_status) {
      setSelectedRow(val);
      setViewOpen(true);
    } else {
      toast.error('Alerts/Follow ups not send yet');
    }
  };

  const handleViewDrawerClose = () => {
    setViewOpen(false);
  };

  return (
    <div>
      <Breadcrums
        title={[
          {
            title: "Send Notification",
            icon: "",
            link: true,
            // path: "/",
          },
        ]}
      />
      <Box sx={{ p: 2 }}>
        <Card sx={{ p: 2 }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            flexWrap={"wrap"}
            gap={{ xs: 1, xl: 2 }}
            p={2}
          >
            <SimpleDropdown
              label={"Alert Type"}
              style={{ width: "150px" }}
              value={formData.alert_type}
              onChange={(value) => {
                handleInputChage("alert_type", value.target.value);
              }}
              menuitem={alertType}
            />

            <SimpleDropdown
              label={"Vaccination Type"}
              style={{ width: "150px" }}
              value={formData.vaccination_type}
              onChange={(value) => {
                handleInputChage("vaccination_type", value.target.value);
              }}
              menuitem={vaccinationTypes}
            />

            <SimpleDropdown
              label={"Select Dose"}
              style={{ width: "120px" }}
              value={formData.dose}
              onChange={(value) => {
                handleInputChage("dose", value.target.value);
              }}
              menuitem={Doses}
            />

            <Datefeild
              // label={"Date"}
              placeholder={"Select Vaccination Date"}
              value={formData.date}
              onChange={handleDateChange}
              style={{ width: "200px" }}
            />

            <FormControl size="small" sx={{ width: "148px" }}>
              <InputLabel id="demo-simple-select-label">
                Select Class
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedClass}
                label="Select Class"
                onChange={(e) => {
                  handleClassSelect(e.target.value);
                }}
              >
                {allClass && allClass.length
                  ? allClass.map((data, index) => {
                      return (
                        <MenuItem value={data.value} key={index}>
                          {data.class}
                        </MenuItem>
                      );
                    })
                  : ""}
              </Select>
            </FormControl>

            <FormControl size="small" sx={{ width: "148px" }}>
              <InputLabel id="demo-simple-select-label">
                Select Batch
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedBatch}
                label="Select Batch"
                onChange={(e) => {
                  handleBatchSelect(e.target.value);
                }}
              >
                {allBatch && allBatch.length
                  ? allBatch.map((data, index) => {
                      return (
                        <MenuItem value={data.value} key={index}>
                          {data.batch}
                        </MenuItem>
                      );
                    })
                  : ""}
              </Select>
            </FormControl>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: 1, xl: 2 },
                ml: "auto",
                flexWrap: "wrap",
                mt: { xs: 1, md: 0 },
              }}
            >
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#3BA5B9",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: 500,
                  textTransform: "capitalize",
                }}
                onClick={handleSendAllDrawerOpen}
              >
                Send All
              </Button>
            </Box>
          </Box>

          <TableContainer
            sx={{
              //   boxShadow: "0px 0px 2px black",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Table>
              <TableHead>
                <TableRow
                  sx={
                    theme.palette.mode == "dark"
                      ? { backgroundColor: "#212529" }
                      : { backgroundColor: "#F3F6F9" }
                  }
                >
                  <TableCell>
                    <Checkbox
                      size="small"
                      checked={allchecked}
                      onChange={(e) => {
                        handleAllChecked(e.target.checked);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { md: "12px", xl: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    Sl
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { md: "12px", xl: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { md: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Class
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { md: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Batch
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { md: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Parent Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { md: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Parent Email Id
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { md: "12px", xl: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    Gender
                  </TableCell>

                  <TableCell
                    sx={{
                      fontSize: { md: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Notification Status
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={
                      theme.palette.mode == "dark"
                        ? {
                            fontSize: { md: "12px", xl: "14px" },
                            fontWeight: 600,
                            position: "sticky",
                            right: 0,
                            backgroundColor: "#343a40",
                          }
                        : {
                            fontSize: { md: "12px", xl: "14px" },
                            fontWeight: 600,
                            position: "sticky",
                            right: 0,
                            backgroundColor: "#F3F6F9",
                          }
                    }
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterdStudentList && filterdStudentList.length ? (
                  filterdStudentList.map((val, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            size="small"
                            id={val.id}
                            name={val.id}
                            value={val.id}
                            checked={checked.includes(val) ? true : false}
                            onChange={(e) => {
                              handleCheckboxSelect(val, e.target.checked);
                            }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                            color: "#915ECC",
                          }}
                        >
                          {index < 9 ? 0 : ""}
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Box display={"flex"} alignItems={"center"} gap={1}>
                            <Avatar
                              src={val.profile_image}
                              sx={{ height: "32px", width: "32px" }}
                            />
                            {val.student_name}
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          Grade {val.class}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.batch_detail}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.father_name}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.father_email}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.gender ? val.gender : "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.notification_status ? "Yes" : "No"}
                        </TableCell>
                        <TableCell
                          sx={
                            theme.palette.mode == "dark"
                              ? {
                                  position: "sticky",
                                  right: 0,
                                  backgroundColor: "#343a40",
                                  width: "fit-content",
                                }
                              : {
                                  position: "sticky",
                                  right: 0,
                                  backgroundColor: "#F3F6F9",
                                  width: "fit-content",
                                }
                          }
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              width: "80px",
                            }}
                          >
                            <Tooltip title="Send Alerts/Follow ups">
                              <IconButton
                                sx={{
                                  borderRadius: "5px",
                                  backgroundColor: "#D0F2F9",
                                  width: "fit-content",
                                }}
                                onClick={() => {
                                  HandleSendOne(val);
                                }}
                              >
                                <HiOutlineBellAlert
                                  style={{ color: "#3BA5B9" }}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="View Alerts/Follow ups">
                              <IconButton
                                sx={{
                                  borderRadius: "5px",
                                  backgroundColor: "#D0F2F9",
                                }}
                                onClick={() => {
                                  handleViewDrawerOpen(val);
                                }}
                              >
                                <MdOutlineRemoveRedEye
                                  style={{ color: "#3BA5B9" }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                        {/* <TableCell align="left">
                            <IconButton
                              sx={{
                                borderRadius: "5px",
                                backgroundColor: "#D0F2F9",
                              }}
                            >
                              <MdOutlineRemoveRedEye
                                style={{ color: "#3BA5B9" }}
                              />
                            </IconButton>
                          </TableCell> */}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={13}>
                      No Data to Show
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
      <SendAllDrawer
        open={drawerOpen}
        onClose={handleSendAllDrawerClose}
        studentData={checked}
        vaccinationData={formData}
        handleStudentData={handleStudentData}
        setAllchecked={setAllchecked}
        setchecked={setchecked}
      />
      <AlertDrawer
        open={viewOpen}
        onClose={handleViewDrawerClose}
        studentData={selectedRow}
      />
    </div>
  );
};

export default NotificationIndex;
