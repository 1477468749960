import React, { Suspense, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../DashBoard/Index";
import { Box } from "@mui/material";
import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";
import VisitIndex from "../ClinicalVisit/Index";
import Index from "../Tracking/Index";
import ConsentIndex from "../Consent/ConsentIndex";
import VaccineIndex from "../Consent/VaccineIndex";
import LoginPage from "../Login/Index";
import { AuthProtected } from "./AuthProtected";
import PasswordUpdate from "../Login/PasswordUpdate";
import GeneralConsentForm from "../Consent/GeneralConsentForm";
import SettingsIndex from "../Settings/SettingsIndex";
import NotFound from "../../Components/NotFound";
import SectionWiseReport from "../Reports/SectionWiseReport";
import ClasswiseReport from "../Reports/ClasswiseReport";
import GeneralconsentReport from "../Reports/GeneralconsentReport";
import VaccinationReport from "../Reports/VaccinationReport";
import VaccineFormIndex from "../Consent/VaccineFormIndex";
import IncidentIndex from "../Incidents/Index";
import { useSelector } from "react-redux";
import CounselIndex from "../Counsel/CounselIndex";
import IncidentReport from "../Reports/IncidentReport";
import NotificationIndex from "../Notification/NotificationIndex";

const AppRoute = () => {
  const userData = useSelector((state) => state.user);
  const isAuth = localStorage.getItem("authUser");

  const [sideBarOpen, setSideBarOpen] = useState(false);

  const privilege = userData && userData.privileges ? userData.privileges : [];

  const clinical_privilege =
    privilege && privilege.find((data) => data.module == "clinical_visit");

  const student_tracking =
    privilege && privilege.find((data) => data.module == "student_tracking");

  const authProtectedRoutes = [
    { path: "/home", component: <Dashboard /> },
    {
      path: "/details/:date",
      component: <VisitIndex privilege={clinical_privilege} />,
    },
    { path: "/track", component: <Index privilege={student_tracking} /> },
    { path: "/generalconsent", component: <ConsentIndex /> },
    { path: "/vaccineconsent", component: <VaccineIndex /> },
    { path: "/settings", component: <SettingsIndex /> },
    { path: "/section_report", component: <SectionWiseReport /> },
    { path: "/class_report", component: <ClasswiseReport /> },
    { path: "/general_report", component: <GeneralconsentReport /> },
    { path: "/vaccine_report", component: <VaccinationReport /> },
    { path: "/incident_report", component: <IncidentReport /> },
    { path: "/incident", component: <IncidentIndex /> },
    { path: "/counsel_track", component: <CounselIndex /> },
    { path: "/send_notification", component: <NotificationIndex /> },
  ];

  // console.log(isAuth);

  return (
    <Routes>
      <Route path={`/reset-password/:id/:token`} element={<PasswordUpdate />} />
      <Route
        path={`/generalconsentform/:id/:token`}
        element={<GeneralConsentForm />}
      />
      <Route
        path={`/vaccineconsentform/:id/:token`}
        element={<VaccineFormIndex />}
      />
      <Route path={`*`} element={<NotFound />} />
      <Route
        path="/"
        element={
          isAuth ? (
            <AuthProtected>
              <Box display={"flex"} width={"100%"}>
                {sideBarOpen ? (
                  <Sidebar
                    sideBarOpen={sideBarOpen}
                    setSideBarOpen={setSideBarOpen}
                  />
                ) : (
                  ""
                )}
                <Box sx={{ height: "100vh", overflow: "auto", width: "100%" }}>
                  <Navbar
                    sideBarOpen={sideBarOpen}
                    setSideBarOpen={setSideBarOpen}
                    // toggleFullScreen={toggleFullScreen}
                  />
                  <Box>
                    <Dashboard />
                  </Box>
                </Box>
              </Box>
            </AuthProtected>
          ) : (
            <Box p={1}>
              <LoginPage />
            </Box>
          )
        }
      />
      {authProtectedRoutes.map((route, idx) => (
        <Route
          path={route.path}
          element={
            <AuthProtected>
              <Suspense>
                <Box display={"flex"} width={"100%"}>
                  {sideBarOpen ? (
                    <Sidebar
                      sideBarOpen={sideBarOpen}
                      setSideBarOpen={setSideBarOpen}
                    />
                  ) : (
                    ""
                  )}
                  <Box
                    sx={{ height: "100vh", overflow: "auto", width: "100%" }}
                  >
                    <Navbar
                      sideBarOpen={sideBarOpen}
                      setSideBarOpen={setSideBarOpen}
                      // toggleFullScreen={toggleFullScreen}
                    />
                    <Box>{route.component}</Box>
                  </Box>
                </Box>
              </Suspense>
            </AuthProtected>
          }
          key={idx}
          exact
        />
      ))}
    </Routes>
  );
};

export default AppRoute;
