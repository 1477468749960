import React from "react";
import { useState } from "react";
import { setBackDropOn, getBackDropOFF } from "../state";

const Commonservices = {
  loaderON: (dispatch) => {
    dispatch(
      setBackDropOn({
        back_drop: true,
      })
    );
  },
  loaderOFF: (dispatch) => {
    dispatch(
      getBackDropOFF({
        back_drop: false,
      })
    );
  },
};

export default Commonservices;
