import {
  Box,
  Button,
  Card,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrums from "../../Components/Breadcrums";
import { MdDownload } from "react-icons/md";
import IncidentDrawer from "./IncidentDrawer";
import Commonservices from "../../service/Commonservices";
import { useDispatch, useSelector } from "react-redux";
import mysqlservice from "../../service/mySqlservice";
import moment from "moment";

const Index = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);

  const [open, setopen] = useState(false);
  const [incidentData, setIncidentData] = useState([]);
  const [pages, setPages] = useState(1);
  const [rowsperPage, setRowsperPage] = useState(10);
  const [totalCount, setTotalCount] = useState("");

  const pageCount = Math.ceil(totalCount / rowsperPage);

  useEffect(() => {
    window.scrollTo(0, 0);
    HandleIncidentdata();
  }, [pages]);

  const handleOpen = () => {
    setopen(true);
  };

  const handldeClose = () => {
    setopen(false);
  };

  const HandleIncidentdata = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        institution_id: userData.institution_id,
        academic_year: userData.academic_year,
        page: pages,
        pageCount: rowsperPage,
      };
      let apiresult = await mysqlservice.post("get_incident", req);

      setIncidentData(apiresult.data);
      setTotalCount(apiresult.totalCount);
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handlePageChange = (event, value) => {
    setPages(value);
  };

  const downloadPdf = async () => {
    try {
      Commonservices.loaderON(dispatch);
      const response = await mysqlservice.post("get_report");
      const data = response.pdf;

      const pdfBase64 = data;
      const pdfBytes = atob(pdfBase64);
      const pdfArray = new Uint8Array(pdfBytes.length);

      for (let i = 0; i < pdfBytes.length; i++) {
        pdfArray[i] = pdfBytes.charCodeAt(i);
      }

      const blob = new Blob([pdfArray], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "report.pdf";
      a.click();

      URL.revokeObjectURL(url);
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  return (
    <>
      <Breadcrums
        title={[
          {
            title: "INCIDENTS",
            icon: "",
            link: true,
            // path: "/",
          },
        ]}
      />
      <Box p={2}>
        <Card>
          <Box display={"flex"} justifyContent={"end"} p={2}>
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#3BA5B9",
                color: "#fff",
                fontSize: "14px",
                fontWeight: 500,
                textTransform: "capitalize",
                //   ml: "auto",
                // height: "38px",
              }}
              onClick={handleOpen}
            >
              Add Incident
            </Button>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow
                  sx={
                    theme.palette.mode == "dark"
                      ? { backgroundColor: "#212529" }
                      : { backgroundColor: "#F3F6F9" }
                  }
                >
                  <TableCell align="center" sx={{ width: "80px" }}>
                    SL
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Grade
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Reported by
                  </TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    On Examination
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Actions Taken
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    General Condition
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Local Examination
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Informed Parents
                  </TableCell>
                  <TableCell>Remarks</TableCell>
                  {/* <TableCell
                    align="right"
                    sx={
                      theme.palette.mode == "dark"
                        ? {
                            fontSize: { lg: "12px", xl: "14px" },
                            fontWeight: 600,
                            position: "sticky",
                            right: 0,
                            backgroundColor: "#343a40",
                          }
                        : {
                            fontSize: { lg: "12px", xl: "14px" },
                            fontWeight: 600,
                            position: "sticky",
                            right: 0,
                            backgroundColor: "#F3F6F9",
                          }
                    }
                  >
                    Action
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {incidentData && incidentData.length ? (
                  incidentData.map((val, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          align="center"
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                            color: "#915ECC",
                          }}
                        >
                          {index < 9 ? 0 : ""}
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.student_name}
                        </TableCell>
                        <TableCell>
                          Grade {val.class} - {val.batch}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.reported_by}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {moment(val.date).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.on_examination}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.general_condition}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.local_examination}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.action_taken}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.informed_parents}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.remarks}
                        </TableCell>
                        {/* <TableCell
                          align="center"
                          sx={
                            theme.palette.mode == "dark"
                              ? {
                                  position: "sticky",
                                  right: 0,
                                  backgroundColor: "#343a40",
                                }
                              : {
                                  position: "sticky",
                                  right: 0,
                                  backgroundColor: "#F3F6F9",
                                }
                          }
                        >
                          <IconButton
                            sx={{
                              borderRadius: "5px",
                              backgroundColor: "#D0F2F9",
                              width: "fit-content",
                            }}
                            onClick={() => {
                              downloadPdf();
                            }}
                          >
                            <MdDownload style={{ color: "#3BA5B9" }} />
                          </IconButton>
                        </TableCell> */}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      No Data to Show
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            sx={{
              m: 1,
              display: "flex",
              justifyContent: "end",
              "& .Mui-selected": {
                color: "#fff",
                backgroundColor: "#3BA5B9",
              },
              "& .MuiPaginationItem-page": {
                color: "#3BA5B9",
                "&.Mui-selected": {
                  backgroundColor: "#3BA5B9",
                  color: "#fff",
                },
              },
            }}
            count={pageCount}
            page={pages}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </Card>
      </Box>
      <IncidentDrawer
        open={open}
        handledrawerClose={handldeClose}
        HandleIncidentdata={HandleIncidentdata}
      />
    </>
  );
};

export default Index;
