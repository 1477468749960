import React from "react";
import { Bar } from "react-chartjs-2";

import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const Chartcomp = ({ visitData }) => {
  const data = {
    labels: ["Disease", "Injury", "Fainting"],
    datasets: [
      {
        label: "Infectious",
        data: [
          visitData &&
            visitData.visit_case &&
            visitData.visit_case.disease.Infectious,
          null,
          null,
        ],
        backgroundColor: "#FD6B6B",
      },
      {
        label: "Non Infectious",
        data: [
          visitData &&
            visitData.visit_case &&
            visitData.visit_case.disease["Non-Infectious"],
          ,
          null,
          null,
        ],
        backgroundColor: "#ADD8FF",
      },
      {
        label: "Open",
        data: [
          null,
          visitData && visitData.visit_case && visitData.visit_case.injury.Open,
          null,
        ],
        backgroundColor: "#FD6B6B",
      },
      {
        label: "Blunt",
        data: [
          null,
          visitData &&
            visitData.visit_case &&
            visitData.visit_case.injury.Blunt,
          null,
        ],
        backgroundColor: "#ADD8FF",
      },
      {
        label: "Fainting",
        data: [
          null,
          null,
          visitData &&
            visitData.visit_case &&
            visitData.visit_case.fainting.Fainting,
        ],
        backgroundColor: "#FD6B6B",
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  return (
    <div>
      <Bar data={data} options={options} width={"100%"} height={"550px"} />
    </div>
  );
};

export default Chartcomp;
