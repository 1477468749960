import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { PiVirus, PiSpiralBold, PiBandaidsLight } from "react-icons/pi";
import Commonservices from "../../service/Commonservices";
import { useDispatch, useSelector } from "react-redux";
import mysqlservice from "../../service/mySqlservice";
import { toast } from "react-toastify";
import Textfield from "../../Components/Textfield";
import { useFormik } from "formik";

const AlertDrawer = ({ open, onClose, studentData }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const userData = useSelector((state) => state.user);

  console.log(studentData);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      sx={
        theme.palette.mode == "dark"
          ? {
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { xs: "100%", md: "598px" },
                backgroundColor: "#212529",
              },
            }
          : {
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { xs: "100%", md: "598px" },
                backgroundColor: "#EFEFEF",
              },
            }
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            p: 2,
            borderBottom: "1px solid #ddd",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Notification Status
            </Typography>
          </Box>
          <IconButton sx={{ ml: "auto" }} onClick={onClose}>
            <CloseIcon sx={{ height: "28px", width: "28px" }} />
          </IconButton>
        </Box>

        {/* Content */}
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <Box sx={{ display: "flex", gap: 1, alignItems: "center", p: 2 }}>
            <Avatar src={studentData.profile_image} />
            <Box>
              <Typography>{studentData.student_name}</Typography>
              <Typography>
                Grade {studentData.class} - {studentData.batch_detail}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ mt: 1 }} />

          <Box sx={{ p: 3 }}>
            <Typography sx={{ fontSize: "14px" }}>
              Vaccination : {studentData.vaccination_type}
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              Dose : {studentData.dose}
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              Date : {studentData.vaccination_date}
            </Typography>
          </Box>
          <Divider sx={{ mt: 1 }} />

          <Box sx={{ p: 3 }}>
            <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
              Subject :
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {studentData.subject}
            </Typography>
          </Box>
          <Box sx={{ p: 3 }}>
            <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
              Content :
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {studentData.content}
            </Typography>
          </Box>
        </Box>

        {/* Footer */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "auto",
            width: "100%",
          }}
        >
          <Divider />
          <Box p={2} display="flex" justifyContent="end" gap={2}>
            <Button sx={{ color: "#3BA5B9" }} onClick={onClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default AlertDrawer;
