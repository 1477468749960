import { DatePicker } from "antd";
import React from "react";

const Datefeild = ({ label, value, onChange, placeholder, style }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
    >
      {label ? (
        <span style={{ fontSize: "13px", fontWeight: 500 }}>{label}</span>
      ) : (
        ""
      )}

      <DatePicker
        value={value}
        placeholder={placeholder}
        style={{
          height: "36px",
          padding: "4px",
          border: "1px solid #DFDFDF",
          borderRadius: "5px",
          ...style,
        }}
        onChange={onChange}
      />
    </div>
  );
};

export default Datefeild;
