import { Breadcrumbs, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Breadcrums = (props) => {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        // width: "100%",
        borderBottom: "1px solid #DEDEDE",
        p: 1,
        pl:2
      }}
    >
      {props && props.title
        ? props.title.map((val, index) => {
            return (
              <Typography
                key={index}
                color="text.primary"
                sx={{ fontSize: "15px", fontWeight: 700 }}
              >
                {val.title}
              </Typography>
            );
          })
        : ""}
    </Breadcrumbs>
  );
};

export default Breadcrums;
