import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import Textfield from "../../../Components/Textfield";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Datefeild from "../../../Components/Datefeild";
import dayjs from "dayjs";

const PersonalDetailpage = ({ studentData }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const personalDetaillist = [
    { id: 1, title: "Student Full Name", value: "student_full_name" },
    { id: 2, title: "Gender", value: "gender" },
    { id: 3, title: "Emirates ID", value: "emirates_id" },
    { id: 4, title: "DOB", value: "dob" },
    { id: 6, title: "School Name", value: "school_name" },
    { id: 5, title: "Grade", value: "grade" },
    { id: 7, title: "Year", value: "year" },
  ];

  const studentDetaillist = [
    {
      id: 1,
      title:
        "Has the student had a serious reaction to a vaccine in the past? If yes, please specify.",
      value: "reaction_for_vaccine",
      comment: "reaction_for_vaccine_comment",
    },
    {
      id: 2,
      title:
        "Does the student have any allergies to medication or food (e.g. Eggs, yeast, etc:) or others? If yes, please specify.",
      value: "have_any_allergy",
      comment: "have_any_allergy_comment",
    },
    {
      id: 3,
      title:
        "Does the student suffer from any health conditions that weaken the immune system like cancer, organ transplant, etc.? If yes, specify",
      value: "have_health_condition",
      comment: "have_health_condition_comment",
    },
    {
      id: 4,
      title:
        "Has the student taken steroids, chemotherapy, or radiotherapy in the past 3 months? If yes, specify the date",
      value: "have_taken_steroids",
      comment: "have_taken_steroids_comment",
    },
    {
      id: 5,
      title:
        "Does the student receive any medication that affects blood coagulation? if yes, specify",
      value: "have_medication",
      comment: "have_medication_comment",
    },
    {
      id: 6,
      title:
        "Did the student receive any blood transfusion, antibodies, or plasma within the past year? If yes, specify the date",
      value: "have_blood_transfusion",
      comment: "have_blood_transfusion_comment",
    },
    {
      id: 7,
      title:
        "Does the student currently suffer from any illness/infection disease? If yes, specify",
      value: "have_infection",
      comment: "have_infection_comment",
    },
  ];

  const parentDetail = [
    { id: 1, title: "Parent / Legal Guardian Name", value: "parent_name" },
    { id: 2, title: "Relationship", value: "relationship" },
    // {
    //   id: 3,
    //   title: "Parent / Legal Guardian Signature",
    //   value: "parent_signature",
    // },
  ];

  const onChange = (date, dateString) => {
    let newDate = dayjs(date)
    studentData.setFieldValue("declare_Date", newDate);
  };

  return (
    <Box>
      <Box>
        <Typography
          sx={{
            fontSize: { md: "12px", lg: "13px", xl: "14px" },
            fontWeight: 600,
          }}
        >
          Section A: This section should be filled by school Doctor / Nurse
        </Typography>
      </Box>

      <Grid container spacing={1} mt={1}>
        {personalDetaillist.map((val, ind) => (
          <Grid item xs={12} md={3.5} lg={2.4} key={ind}>
            <Textfield
              label={val.title}
              isRequired={true}
              value={studentData.values[val.value]}
              onChange={(e) => {
                studentData.setFieldValue(val.value, e.target.value);
              }}
              placeholder={"Enter"}
              // helperText={studentData.errors.school_name}
              error={studentData.errors[val.value]}
            />
          </Grid>
        ))}
      </Grid>

      <Box mt={4}>
        <Typography
          sx={{
            fontSize: { md: "12px", lg: "13px", xl: "14px" },
            fontWeight: 600,
          }}
        >
          Section B: This part to be filled & signed by parents or guardians
        </Typography>
        <Box mt={2}>
          <Typography
            sx={{
              fontSize: { md: "12px", lg: "13px", xl: "14px" },
              fontWeight: 500,
            }}
          >
            <FiberManualRecordIcon sx={{ height: "8px", width: "8px" }} /> To
            ensure safe vaccination, the school clinic requires information
            about the student's health status. Please review and complete the
            following checklist and return it to the school prior to
            administering the vaccination.
          </Typography>

          <Typography
            sx={{
              fontSize: { md: "12px", lg: "13px", xl: "14px" },
              fontWeight: 500,
              mt: 1,
            }}
          >
            <FiberManualRecordIcon sx={{ height: "8px", width: "8px" }} />{" "}
            Please inform the school clinic of any change in the student's
            health status during the academic year, as it might affect the
            vaccine dose.
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={2} mt={2}>
        {studentDetaillist.map((val, index) => (
          <Grid item xs={12} md={3} lg={2.4} key={index}>
            <Box height={{ xs: "auto", md: "130px", xl: "110px" }}>
              <Typography
                sx={{ fontSize: { md: "12px", lg: "13px", xl: "14px" } }}
              >
                {val.title}
              </Typography>
              <Box display={"flex"}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={studentData.values[val.value]}
                      onChange={() => {
                        studentData.setFieldValue(val.value, true);
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={!studentData.values[val.value]}
                      onChange={(value) => {
                        studentData.setFieldValue(val.value, false);
                      }}
                    />
                  }
                  label="No"
                />
              </Box>
            </Box>
            <TextField
              id={`${index}-enquiry`}
              placeholder="Add Comment"
              sx={{
                backgroundColor: "#fff",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#DFDFDF",
                  },
                  "&:hover fieldset": {
                    borderColor: "#DFDFDF",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#DFDFDF",
                  },
                  "& input::placeholder": {
                    color: "#71717A",
                  },
                },
              }}
              value={studentData.values[val.comment]}
              variant="outlined"
              fullWidth
              onChange={(e) => {
                studentData.setFieldValue(val.comment, e.target.value);
              }}
            />
          </Grid>
        ))}

        <Grid item xs={12} md={3} lg={2.4}>
          <Box>
            <Typography
              sx={{ fontSize: { md: "12px", lg: "13px", xl: "14px" } }}
            >
              Last vaccine given to my son/daughter? If yes, specify name of the
              vaccine taken and date of administration.
            </Typography>
            <Box display={"flex"}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={studentData.values.have_vaccince}
                    onChange={() => {
                      studentData.setFieldValue("have_vaccince", true);
                    }}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={!studentData.values.have_vaccince}
                    onChange={(value) => {
                      studentData.setFieldValue("have_vaccince", false);
                    }}
                  />
                }
                label="No"
              />
            </Box>
          </Box>
          <TextField
            id={`-enquiry`}
            placeholder="Enter vaccine name "
            sx={{
              backgroundColor: "#fff",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#DFDFDF",
                },
                "&:hover fieldset": {
                  borderColor: "#DFDFDF",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#DFDFDF",
                },
                "& input::placeholder": {
                  color: "#71717A",
                },
              },
            }}
            value={studentData.values.prev_vaccine_name}
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => {
              studentData.setFieldValue("vaccine_name", e.target.value);
            }}
          />
          <TextField
            id={`-enquiry`}
            placeholder="Enter date"
            sx={{
              backgroundColor: "#fff",
              mt: 1,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#DFDFDF",
                },
                "&:hover fieldset": {
                  borderColor: "#DFDFDF",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#DFDFDF",
                },
                "& input::placeholder": {
                  color: "#71717A",
                },
              },
            }}
            value={studentData.values.prev_vaccine_date}
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => {
              studentData.setFieldValue("vaccine_date", e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} md={3} lg={2.4}>
          <Box>
            <Typography
              sx={{ fontSize: { md: "12px", lg: "13px", xl: "14px" } }}
            >
              Has the student had a seizure in the past?
            </Typography>
            <Box display={"flex"}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={studentData.values.have_seizure}
                    onChange={() => {
                      studentData.setFieldValue("have_seizure", true);
                    }}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={!studentData.values.have_seizure}
                    onChange={(value) => {
                      studentData.setFieldValue("have_seizure", false);
                    }}
                  />
                }
                label="No"
              />
            </Box>
          </Box>

          <Box mt={1}>
            <Typography
              sx={{ fontSize: { md: "12px", lg: "13px", xl: "14px" } }}
            >
              Does any household member/ relative living in the same house have
              cancer, leukemia, organ transplant or any other immune system
              problem?
            </Typography>
            <Box display={"flex"}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={studentData.values.family_disesase}
                    onChange={() => {
                      studentData.setFieldValue("family_disesase", true);
                    }}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={!studentData.values.family_disesase}
                    onChange={(value) => {
                      studentData.setFieldValue("family_disesase", false);
                    }}
                  />
                }
                label="No"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={1} mt={2}>
        {parentDetail.map((val, ind) => (
          <Grid item xs={12} md={3.5} lg={2.4} key={ind}>
            <Textfield
              label={val.title}
              value={studentData.values[val.value]}
              onChange={(e) => {
                studentData.setFieldValue(val.value, e.target.value);
              }}
              placeholder={"Enter"}
            />
          </Grid>
        ))}
        <Grid item xs={12} md={3.5} lg={2.4}>
          <Datefeild
            label={"Date"}
            onChange={onChange}
            placeholder={"Select"}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PersonalDetailpage;
