import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { MdOutlineEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import AddUserDrawer from "./AddUserDrawer";

const UserManagementTable = ({ users, setrefresh }) => {
  const theme = useTheme();

  const [editOpen, seteditOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const handleOpen = (val) => {
    seteditOpen(true);
    setSelectedData(val);
  };

  const handleClose = () => {
    seteditOpen(false);
  };
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={
                theme.palette.mode == "dark"
                  ? { backgroundColor: "#212529" }
                  : { backgroundColor: "#F3F6F9" }
              }
            >
              {/* <TableCell>
                <Checkbox size="small" />
              </TableCell> */}
              <TableCell sx={{ fontSize: "13px", fontWeight: 600 }}>
                Sl
              </TableCell>
              <TableCell sx={{ fontSize: "13px", fontWeight: 600 }}>
                Name
              </TableCell>
              <TableCell sx={{ fontSize: "13px", fontWeight: 600 }}>
                Email ID
              </TableCell>
              <TableCell sx={{ fontSize: "13px", fontWeight: 600 }}>
                Role
              </TableCell>
              <TableCell sx={{ fontSize: "13px", fontWeight: 600 }}>
                Status
              </TableCell>
              <TableCell
                align="right"
                sx={
                  theme.palette.mode == "dark"
                    ? {
                        fontSize: "13px",
                        fontWeight: 600,
                        position: "sticky",
                        right: 0,
                        backgroundColor: "#343a40",
                        width: "92px",
                      }
                    : {
                        fontSize: "13px",
                        fontWeight: 600,
                        position: "sticky",
                        right: 0,
                        backgroundColor: "#F3F6F9",
                        width: "92px",
                      }
                }
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users && users.length ? (
              users.map((val, index) => {
                return (
                  <TableRow key={index}>
                    {/* <TableCell>
                      <Checkbox size="small" />
                    </TableCell> */}
                    <TableCell>
                      {index < 9 ? 0 : ""}
                      {index + 1}
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Avatar
                          sx={{ height: "32px", width: "32px" }}
                          src={val && val.profile_pic ? val.profile_pic : ""}
                        />
                        <Typography
                          sx={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ fontSize: "14px" }}>{val.email}</TableCell>
                    <TableCell sx={{ fontSize: "14px" }}>
                      {val.role == 10
                        ? "Admin"
                        : val.role == 20
                        ? "Counselor"
                        : val.role == 30
                        ? "Doctor"
                        : "Nurse"}
                    </TableCell>
                    <TableCell sx={{ fontSize: "14px" }}>
                      <Chip
                        size="small"
                        sx={
                          // val.status === "active" || val.status === "Active"
                          //   ?
                          {
                            backgroundColor: "#E7FFE1",
                            color: "#116D02",
                            borderRadius: "4px",
                          }
                          // : {
                          //     backgroundColor: "#EFE1DF",
                          //     color: "#F06548",
                          //     borderRadius: "4px",
                          //   }
                        }
                        // label={val.status}
                        label={"Active"}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={
                        theme.palette.mode == "dark"
                          ? {
                              position: "sticky",
                              right: 0,
                              backgroundColor: "#343a40",
                            }
                          : {
                              position: "sticky",
                              right: 0,
                              backgroundColor: "#F3F6F9",
                            }
                      }
                    >
                      {/* <Box sx={{ display: "flex", gap: 1 }}> */}
                        <IconButton
                          sx={{
                            borderRadius: "5px",
                            backgroundColor: "#D0F2F9",
                          }}
                          onClick={() => handleOpen(val)}
                        >
                          <MdOutlineEdit style={{ color: "#3BA5B9" }} />
                        </IconButton>
                        {/* <IconButton
                          sx={{
                            borderRadius: "5px",
                            backgroundColor: "#D0F2F9",
                          }}
                        >
                          <MdOutlineRemoveRedEye style={{ color: "#3BA5B9" }} />
                        </IconButton> */}
                      {/* </Box> */}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell align="center" sx={{ fontSize: "14px" }} colSpan={7}>
                  No Data to Show
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <AddUserDrawer
        open={editOpen}
        handleOpen={handleOpen}
        handleClose={handleClose}
        selectedData={selectedData}
        setrefresh={setrefresh}
      />
    </>
  );
};

export default UserManagementTable;
