import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Breadcrums from "../../Components/Breadcrums";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import Commonservices from "../../service/Commonservices";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../../service/apiService";
import mysqlservice from "../../service/mySqlservice";
import moment from "moment";
import APPCONSTANTS from "../../config/constant";
import { setBatch, setClass } from "../../state";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import Emailresend2 from "../../Asset/resend.png";
import RemarksDrawer from "./RemarksDrawer";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const ConsentIndex = () => {
  useEffect(() => {
    handleclassNbatch();
  }, []);

  const theme = useTheme();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const classData = useSelector((state) => state.class);
  const batchData = useSelector((state) => state.batch);
  const classInRoman = APPCONSTANTS.classInRoman;
  const navigate = useNavigate();
  const containerRef = useRef();

  const [generalStudentList, setGeneralStudentList] = useState([]);
  const [filterdStudentList, setfilterdStudentList] = useState([]);
  const [allClass, setAllClass] = useState([]);
  const [classNbatchData, setClassNBatchData] = useState([]);
  const [allBatch, setAllBatch] = useState([]);
  const [selectedClass, setselectedClass] = useState("");
  const [selectedBatch, setselectedBatch] = useState("");

  const [checked, setchecked] = useState([]);
  const [allchecked, setAllchecked] = useState(false);
  const [remarksOpen, setRemarksOpen] = useState(false);
  const [selectedStudent, setselectedStudent] = useState("");

  const [scrollPosition, setscrollpostioin] = useState(0);

  const genderType = [
    { value: "", name: "None" },
    { value: "M", name: "Male" },
    { value: "F", name: "Female" },
  ];

  const acknowledgeType = [
    { value: "", name: "None" },
    { value: "Yes", name: "Acknowledged" },
    { value: "No", name: "Not Acknowledged" },
  ];

  const [formData, setFormData] = useState({
    gender: "",
    acknowledged: "",
    date: "",
  });

  const handleInputChage = (feild, e) => {
    setFormData((prev) => ({
      ...prev,
      [feild]: e,
    }));
  };

  const handleDateChange = (date, dateString) => {
    if (date) {
      let newDate = dayjs(date);
      let timeStamp = newDate.valueOf();
      let formattedDate = moment(timeStamp).format("YYYY-MM-DD");
      handleInputChage("date", newDate);
    } else {
      handleInputChage("date", "");
    }
  };

  const handleRemarkClose = () => {
    setRemarksOpen(false);
  };

  // const handleclassNbatch = async () => {
  //   try {
  //     Commonservices.loaderON(dispatch);
  //     const apiResult = await mysqlservice.get("get_batch_details");

  //     setAllBatch(apiResult.data.batches);
  //     setAllClass(apiResult.data.classes);
  //     Commonservices.loaderOFF(dispatch);
  //   } catch (error) {
  //     Commonservices.loaderON(dispatch);
  //     console.log(error);
  //     Commonservices.loaderOFF(dispatch);
  //   }
  // };

  const handleclassNbatch = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        inst_id: userData.institution_id,
        acdyr: userData.academic_year,
      };

      let apiResult = await ApiService.post(
        "bm-school/Docme-UI/registration/get_student_class",
        req
      );

      const combinedClasses = {};

      apiResult.forEach((item) => {
        const foundClass = APPCONSTANTS.classInRoman.find(
          (c) => c.roman === item.class
        );

        if (foundClass) {
          const classNumber = foundClass.number;

          if (combinedClasses[classNumber]) {
            combinedClasses[classNumber].batch = [
              ...new Set([
                ...combinedClasses[classNumber].batch,
                ...item.batch,
              ]),
            ];
          } else {
            combinedClasses[classNumber] = {
              class: classNumber,
              batch: item.batch,
            };
          }
          combinedClasses[classNumber].batch =
            combinedClasses[classNumber].batch.sort();
        }
      });

      const updatedClassNbatch = Object.values(combinedClasses);

      const sortedClassNbatch = updatedClassNbatch.sort((a, b) => {
        if (a.class === "KG 1") return -1;
        if (b.class === "KG 1") return 1;
        if (a.class === "KG 2") return -1;
        if (b.class === "KG 2") return 1;
        return a.class - b.class;
      });

      const classes = sortedClassNbatch.map((data) => {
        return {
          value: data.class,
          class: data.class,
        };
      });
      setClassNBatchData(sortedClassNbatch);
      setAllClass(classes);

      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handleClassSelect = (value) => {
    setselectedClass(value);
    dispatch(
      setClass({
        class: value,
      })
    );
    const Allbatches =
      classNbatchData &&
      classNbatchData.find((data) => data.class === value)?.batch;

    const batches = Allbatches.map((data) => {
      return {
        value: data,
        batch: data,
      };
    });

    setAllBatch(batches);
  };

  // const handleClassSelect = (value) => {
  //   setselectedClass(value);
  //   dispatch(
  //     setClass({
  //       class: value,
  //     })
  //   );
  // };

  const handleBatchSelect = async (value) => {
    setselectedBatch(value);
    dispatch(
      setBatch({
        batch: value,
      })
    );
    await handleStudentData(value);
  };

  const handleStudentData = async (batch) => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        class: selectedClass ? selectedClass : classData,
        batch: batch ? batch : batchData,
        inst_id: userData.institution_id,
        acdyr: userData.academic_year,
      };
      const apiResult = await ApiService.post(
        "bm-school/Docme-UI/registration/get_student_data",
        req
      );

      for (let val of apiResult) {
        let parts = val && val.batch.split("/");

        let romanClass = parts[0];
        let partBatch = parts[1];

        let partClass = await classInRoman.find(
          (val) => val.roman == romanClass
        );
        val.class = partClass.number;
        val.batch_detail = partBatch;
      }

      await getAcknowledgmentData(batch, apiResult);
      setselectedClass("");
      setselectedBatch("");
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      setselectedClass("");
      setselectedBatch("");
      Commonservices.loaderOFF(dispatch);
    }
  };

  const getAcknowledgmentData = async (batch, studentData) => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        class: selectedClass ? selectedClass : classData,
        batch: batch ? batch : batchData,
        inst_id: userData.institution_id,
        acdyr: userData.academic_year,
        consent_type: "general_consent",
      };
      let apiResult = await mysqlservice.post("get_acknowledgement", req);
      let res_val = apiResult && apiResult.data ? apiResult.data : [];

      let combinedData = studentData.map((data) => {
        const accDetail = res_val.find(
          (val) => val.admission_no == data.Admn_No
        );

        return {
          ...data,
          ...(accDetail
            ? {
                consent_id: accDetail.id,
                acknowledge_status: accDetail.acknowledge_status,
                consent_status: accDetail.consent_status,
                acknowledge_date: accDetail.acknowledge_date,
                no_of_notification: accDetail.no_of_notification,
                remarks: accDetail.remarks,
              }
            : {}),
        };
      });

      setGeneralStudentList(combinedData);
      setfilterdStudentList(combinedData);
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      // Commonservices.loaderON(dispatch);
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  console.log(generalStudentList);

  const handleSentAcknowledgment = async (value) => {
    let parts = value.batch.split("/");

    let romanClass = parts[0];
    let partBatch = parts[1];

    let partClass = await classInRoman.find((val) => val.roman == romanClass);

    let req = {};
    req = value;
    req.class = partClass.number;
    req.batch_detail = partBatch;
    req.consent_type = "general_consent";
    req.academic_year = userData.academic_year;

    try {
      Commonservices.loaderON(dispatch);
      let apiRequest = await mysqlservice.post("send_acknowledgement", req);

      await handleStudentData();
      if (apiRequest.status == 200) {
        toast.success(`General Consent Send to ${value.father_name}`);
      }
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      Commonservices.loaderON(dispatch);
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handlefilter = () => {
    let filterdData = generalStudentList;
    if (formData.gender) {
      filterdData = generalStudentList.filter(
        (val) => val.gender == formData.gender
      );
    }
    if (formData.date) {
      let ogDate = formData.date.valueOf();
      let formate = moment(ogDate).format("YYYY-MM-DD");
      filterdData = generalStudentList.filter(
        (val) => val.acknowledge_date == formate
      );
      // console.log(filterdData);
    }
    if (formData.acknowledged) {
      filterdData = generalStudentList.filter(
        (val) => val.acknowledge_status == formData.acknowledged
      );
    }

    setfilterdStudentList(filterdData);
  };

  const handleCheckboxSelect = (id, isChecked) => {
    let checkedlist = [...checked];
    if (isChecked) {
      checkedlist = [...checked, id];
    } else {
      setAllchecked(false);
      checkedlist.splice(checked.indexOf(id), 1);
    }
    setchecked(checkedlist);
  };

  const handleAllChecked = (ischecked) => {
    let checkedList = [];
    if (ischecked) {
      setAllchecked(true);
      for (let index = 0; index < filterdStudentList.length; index++) {
        let element = filterdStudentList[index];
        checkedList.push(element);
      }
    } else {
      setAllchecked(false);
    }
    setchecked(checkedList);
  };

  const handleSendall = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {};
      req.data = checked;
      req.consent_type = "general_consent";
      req.academic_year = userData.academic_year;
      req.institution_id = userData.institution_id;

      let apiResult = await mysqlservice.post("sendall_acknowledgement", req);
      await handleStudentData();
      setchecked([]);
      setAllchecked("");
      if (apiResult.status == 200) {
        toast.success(`General Consent Send to All`);
      }
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };

  const handleredirectForm = (id) => {
    if (id) {
      navigate(`/generalconsentform/${id}/${null}`);
    } else {
      toast.error("Form not send yet");
    }
  };

  const handleScrolll = (scrollAmt) => {
    const newScroll = scrollPosition + scrollAmt;
    setscrollpostioin(newScroll);
    containerRef.current.scrollLeft = newScroll;
  };

  return (
    <>
      <Breadcrums
        title={[
          {
            title: "GENERAL CONSENT",
            icon: "",
            link: true,
            // path: "/",
          },
        ]}
      />
      <Box p={2}>
        <Card>
          <Box
            display={"flex"}
            alignItems={"center"}
            flexWrap={"wrap"}
            gap={{ xs: 1, xl: 2 }}
            p={2}
          >
            <FormControl size="small" sx={{ width: "148px" }}>
              <InputLabel id="demo-simple-select-label">
                Select Class
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedClass}
                label="Select Class"
                onChange={(e) => {
                  handleClassSelect(e.target.value);
                }}
              >
                {allClass && allClass.length
                  ? allClass.map((data, index) => {
                      return (
                        <MenuItem value={data.value} key={index}>
                          {data.class}
                        </MenuItem>
                      );
                    })
                  : ""}
              </Select>
            </FormControl>

            <FormControl size="small" sx={{ width: "148px" }}>
              <InputLabel id="demo-simple-select-label">
                Select Batch
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedBatch}
                label="Select Batch"
                onChange={(e) => {
                  handleBatchSelect(e.target.value);
                }}
              >
                {allBatch && allBatch.length
                  ? allBatch.map((data, index) => {
                      return (
                        <MenuItem value={data.value} key={index}>
                          {data.batch}
                        </MenuItem>
                      );
                    })
                  : ""}
              </Select>
            </FormControl>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: 1, xl: 2 },
                ml: "auto",
                flexWrap: "wrap",
                mt: { xs: 1, md: 0 },
              }}
            >
              {/* <SimpleDropdown
                label={"Select Gender"}
                style={{ width: "148px" }}
                menuitem={genderType}
                value={formData.gender}
                onChange={(e) => {
                  handleInputChage("gender", e.target.value);
                }}
              /> */}

              {/* <Datefeild
                style={{ width: "148px" }}
                value={formData.date}
                onChange={handleDateChange}
              /> */}

              {/* <SimpleDropdown
                label={"Select Acknowledged/ Not Acknowledged"}
                style={{ width: { xs: "148px", md: "335px" } }}
                menuitem={acknowledgeType}
                value={formData.acknowledged}
                onChange={(e) => {
                  handleInputChage("acknowledged", e.target.value);
                }}
              /> */}

              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#3BA5B9",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: 500,
                  textTransform: "capitalize",
                  // mt: "22px",
                  // height: "38px",
                }}
                onClick={handleSendall}
              >
                Send All
              </Button>
            </Box>
          </Box>
          <TableContainer
            sx={{
              //   boxShadow: "0px 0px 2px black",
              display: "flex",
              flexDirection: "column",
            }}
            ref={containerRef}
          >
            <Table>
              <TableHead>
                <TableRow
                  sx={
                    theme.palette.mode == "dark"
                      ? { backgroundColor: "#212529" }
                      : { backgroundColor: "#F3F6F9" }
                  }
                >
                  <TableCell>
                    <Checkbox
                      size="small"
                      checked={allchecked}
                      onChange={(e) => {
                        handleAllChecked(e.target.checked);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { md: "12px", xl: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    Sl
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { md: "12px", xl: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { md: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Class & Batch
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { md: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Parent Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { md: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Parent Email Id
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { md: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Parent Mobile No
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { md: "12px", xl: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    Gender
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { md: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Acknowledged
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { md: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Agreed
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { md: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Acknowledged Date
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { md: "12px", xl: "14px" },
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    No of Notifications Send
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { md: "12px", xl: "14px" },
                      fontWeight: 600,
                    }}
                  >
                    Remarks
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={
                      theme.palette.mode == "dark"
                        ? {
                            fontSize: { md: "12px", xl: "14px" },
                            fontWeight: 600,
                            position: "sticky",
                            right: 0,
                            backgroundColor: "#343a40",
                          }
                        : {
                            fontSize: { md: "12px", xl: "14px" },
                            fontWeight: 600,
                            position: "sticky",
                            right: 0,
                            backgroundColor: "#F3F6F9",
                          }
                    }
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterdStudentList && filterdStudentList.length ? (
                  filterdStudentList.map((val, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            size="small"
                            id={val.id}
                            name={val.id}
                            value={val.id}
                            checked={checked.includes(val) ? true : false}
                            onChange={(e) => {
                              handleCheckboxSelect(val, e.target.checked);
                            }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                            color: "#915ECC",
                          }}
                        >
                          {index < 9 ? 0 : ""}
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Box display={"flex"} alignItems={"center"} gap={1}>
                            <Avatar
                              src={val.profile_image}
                              sx={{ height: "32px", width: "32px" }}
                            />
                            {val.student_name}
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          Grade {val.class} - {val.batch_detail}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.father_name}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.father_email}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.father_phone}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.gender ? val.gender : "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.acknowledge_status
                            ? val.acknowledge_status
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.consent_status ? val.consent_status : "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.acknowledge_date
                            ? moment
                                .utc(val.acknowledge_date)
                                .format("DD MMM YYYY")
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          {val.no_of_notification
                            ? val.no_of_notification
                            : "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { md: "12px", xl: "14px" },
                            whiteSpace: "nowrap",
                            width: "150px",
                          }}
                        >
                          {val.remarks ? (
                            val.remarks
                          ) : (
                            <Box
                              sx={{
                                textDecoration: "underline",
                                color: "#3BA5B9",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setRemarksOpen(true);
                                setselectedStudent(val.consent_id);
                              }}
                            >
                              Enter Remarks
                            </Box>
                          )}
                        </TableCell>
                        <TableCell
                          sx={
                            theme.palette.mode == "dark"
                              ? {
                                  position: "sticky",
                                  right: 0,
                                  backgroundColor: "#343a40",
                                  width: "fit-content",
                                }
                              : {
                                  position: "sticky",
                                  right: 0,
                                  backgroundColor: "#F3F6F9",
                                  width: "fit-content",
                                }
                          }
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              width: "80px",
                            }}
                          >
                            <Tooltip title="Scroll left" placement="top">
                              <IconButton
                                sx={{
                                  position: "absolute",
                                  zIndex: 1,
                                  left: -8,
                                  top: 15,
                                  backgroundColor: "#fff",
                                  border: "1px solid gray",
                                  height: "15px",
                                  width: "15px",
                                }}
                                onClick={() => handleScrolll(400)}
                              >
                                <KeyboardArrowRightIcon
                                  style={{ fontSize: "20px" }}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Scroll Right">
                              <IconButton
                                sx={{
                                  position: "absolute",
                                  zIndex: 1,
                                  left: -8,
                                  top: 35,
                                  backgroundColor: "#fff",
                                  border: "1px solid gray",
                                  height: "15px",
                                  width: "15px",
                                }}
                                onClick={() => handleScrolll(-400)}
                              >
                                <KeyboardArrowLeftIcon
                                  style={{ fontSize: "20px" }}
                                />
                              </IconButton>
                            </Tooltip>
                            {val.no_of_notification ? (
                              <Tooltip title="Resend Consent Form">
                                <img
                                  src={Emailresend2}
                                  alt=""
                                  style={{ height: "40px", width: "40px" }}
                                  onClick={() => {
                                    handleSentAcknowledgment(val);
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Send Consent Form">
                                <IconButton
                                  sx={{
                                    borderRadius: "5px",
                                    backgroundColor: "#D0F2F9",
                                    width: "fit-content",
                                  }}
                                  onClick={() => {
                                    handleSentAcknowledgment(val);
                                  }}
                                >
                                  <ForwardToInboxIcon
                                    style={{ color: "#3BA5B9" }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            <Tooltip title="View Consent Form">
                              <IconButton
                                sx={{
                                  borderRadius: "5px",
                                  backgroundColor: "#D0F2F9",
                                  width: "fit-content",
                                }}
                                onClick={() =>
                                  handleredirectForm(val.consent_id)
                                }
                              >
                                <MdOutlineRemoveRedEye
                                  style={{ color: "#3BA5B9" }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                        {/* <TableCell align="left">
                            <IconButton
                              sx={{
                                borderRadius: "5px",
                                backgroundColor: "#D0F2F9",
                              }}
                            >
                              <MdOutlineRemoveRedEye
                                style={{ color: "#3BA5B9" }}
                              />
                            </IconButton>
                          </TableCell> */}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={13}>
                      No Data to Show
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <Pagination
            sx={{
              m: 1,
              display: "flex",
              justifyContent: "end",
              "& .Mui-selected": {
                color: "#fff",
                backgroundColor: "#3BA5B9",
              },
              "& .MuiPaginationItem-page": {
                color: "#3BA5B9",
                "&.Mui-selected": {
                  backgroundColor: "#3BA5B9",
                  color: "#fff",
                },
              },
            }}
            // count={pageCount}
            // page={pages}
            // onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          /> */}
        </Card>
      </Box>
      <RemarksDrawer
        open={remarksOpen}
        handledrawerClose={handleRemarkClose}
        handleStudentData={handleStudentData}
        selectedStudent={selectedStudent}
      />
    </>
  );
};

export default ConsentIndex;
