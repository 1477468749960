import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";

const Cardcomp = ({ title, count, icon }) => {
  return (
    <Card>
      <CardContent>
        <Typography
          sx={{ fontSize: "14px", fontWeight: 500 }}
          color="text.secondary"
          gutterBottom
        >
          {title}
        </Typography>
        <Typography sx={{ fontSize: "22px", fontWeight: 500 }}>
          {count}
        </Typography>
      </CardContent>
      <Box sx={{ display: "flex", justifyContent: "end", pb: 2, pr: 2 }}>
        {/* <Button
          size="small"
          variant="contained"
          sx={{
            height: "48px",
            width: "48px",
            backgroundColor: "#3BA5B9",
          }}
        >
          <img src={icon} alt="" />
        </Button> */}
        <Box
          sx={{
            height: "48px",
            width: "48px",
            backgroundColor: "#3BA5B9",
            borderRadius: "4px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={icon} alt="" />
        </Box>
      </Box>
    </Card>
  );
};

export default Cardcomp;
