import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Textfield from "../../Components/Textfield";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import Brand from "../../Asset/brand.png";
import { IoCheckmarkCircle } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Commonservices from "../../service/Commonservices";
import { useDispatch } from "react-redux";
import mysqlservice from "../../service/mySqlservice";
import NotFound from "../../Components/NotFound";

const PasswordUpdate = () => {
  const [forgetPass, setForgetPass] = useState(1);
  const [isExpire, setIsExpire] = useState(false);

  const [isPassword, setIsPassword] = useState(false);
  const [isCnfPassword, setIsCnfPassword] = useState(false);
  const [passwordInput, setPasswordInput] = useState({
    password: "",
    confirmPass: "",
  });

  const navigate = useNavigate();
  const url_params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    handleTokenExpired();
  }, []);

  const handleTokenExpired = async () => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {
        id: url_params.id,
        token: url_params.token,
      };

      let apiResult = await mysqlservice.post("reset_timeout", req);
      if (apiResult.status === 200) {
        setIsExpire(false);
      } else {
        setIsExpire(true);
        toast.error("Link Expired");
      }
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
    }
  };

  const [error, setError] = useState({
    oneLowercase: false,
    oneUppercase: false,
    oneSpecialCharacter: false,
    minLength8: false,
    oneNumber: false,
  });

  const handleInput = (feild, e) => {
    setPasswordInput((prev) => ({
      ...prev,
      [feild]: e.target.value,
    }));
    if (/[a-z]/.test(e.target.value)) {
      setError((prev) => ({
        ...prev,
        oneLowercase: true,
      }));
    }
    if (/[A-Z]/.test(e.target.value)) {
      setError((prev) => ({
        ...prev,
        oneUppercase: true,
      }));
    }

    if (/[!@#$%^&*(),.?":{}|<>]/.test(e.target.value)) {
      setError((prev) => ({
        ...prev,
        oneSpecialCharacter: true,
      }));
    }

    if (/[0-9]/.test(e.target.value)) {
      setError((prev) => ({
        ...prev,
        oneNumber: true,
      }));
    }

    if (e.target.value.length >= 8) {
      setError((prev) => ({
        ...prev,
        minLength8: true,
      }));
    }
  };

  const handleShowPass = () => {
    setIsPassword(!isPassword);
  };

  const handleShowCnfPass = () => {
    setIsCnfPassword(!isCnfPassword);
  };

  const handleUpdatePass = async () => {
    Commonservices.loaderON(dispatch);
    try {
      if (passwordInput.password.length < 8) {
        toast.error("Password must conrtain minimum 8 characters");
      } else if (!/[a-z]/.test(passwordInput.password)) {
        toast.error("Password must contain atleat one small letter");
      } else if (!/[A-Z]/.test(passwordInput.password)) {
        toast.error("Password must contain atleat one capital letter");
      } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(passwordInput.password)) {
        toast.error("Password must contain special characters");
      } else if (!/[0-9]/.test(passwordInput.password)) {
        toast.error("Password must contain atleat one number");
      } else if (passwordInput.password !== passwordInput.confirmPass) {
        toast.error("both password is not the same");
      } else {
        let req = {
          password: passwordInput.password,
        };

        const apiResult = await mysqlservice.post(
          `reset-password/${url_params.id}/${url_params.token}`,
          req
        );
        if (apiResult.status === 200) {
          toast.success(apiResult.message);
          setForgetPass(2);
        } else {
          toast.error("Link Expired");
        }
      }

      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {isExpire ? (
        <NotFound/>
      ) : (
        <Grid container>
          <Grid item xs={12} lg={7} sx={{ backgroundColor: "#fff" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                p: { xs: 2, md: 0 },
              }}
            >
              {forgetPass === 1 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ textAlign: "center" }}>
                    <Typography sx={{ fontSize: "36px", fontWeight: 600 }}>
                      Choose new password
                    </Typography>
                    <Typography fontSize={"16px"} sx={{ width: "360px" }}>
                      Almost done. Enter you’re new password and you’re all set
                    </Typography>
                  </Box>
                  <Box mt={3}>
                    <Textfield
                      label={"Enter new password"}
                      value={passwordInput.password}
                      style={{
                        width: { xs: "100%", md: "360px" },
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#344054",
                      }}
                      type={!isPassword ? "password" : "text"}
                      onEndClick={handleShowPass}
                      onChange={(e) => {
                        handleInput("password", e);
                      }}
                      endAdornment={
                        <MdOutlineRemoveRedEye
                          style={
                            isPassword
                              ? {
                                  height: "20px",
                                  width: "20px",
                                  color: "#3BA5B9",
                                }
                              : { height: "20px", width: "20px" }
                          }
                        />
                      }
                    />
                    <br />
                    <Textfield
                      label={"Confirm new password"}
                      value={passwordInput.confirmPass}
                      style={{
                        width: { xs: "100%", md: "360px" },
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#344054",
                      }}
                      type={!isCnfPassword ? "password" : "text"}
                      onEndClick={handleShowCnfPass}
                      onChange={(e) => {
                        handleInput("confirmPass", e);
                      }}
                      endAdornment={
                        <MdOutlineRemoveRedEye
                          style={
                            isCnfPassword
                              ? {
                                  height: "20px",
                                  width: "20px",
                                  color: "#3BA5B9",
                                }
                              : { height: "20px", width: "20px" }
                          }
                        />
                      }
                    />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 2,
                        gap: 1,
                      }}
                    >
                      <IoCheckmarkCircle
                        style={
                          error.oneLowercase
                            ? { height: "18px", width: "18px", color: "green" }
                            : { height: "18px", width: "18px", color: "gray" }
                        }
                      />
                      <Typography sx={{ fontSize: "14px", color: "#7F7D83" }}>
                        one lowercase character
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 1,
                        gap: 1,
                      }}
                    >
                      <IoCheckmarkCircle
                        style={
                          error.oneUppercase
                            ? { height: "18px", width: "18px", color: "green" }
                            : { height: "18px", width: "18px", color: "gray" }
                        }
                      />
                      <Typography sx={{ fontSize: "14px", color: "#7F7D83" }}>
                        one uppercase character
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 1,
                        gap: 1,
                      }}
                    >
                      <IoCheckmarkCircle
                        style={
                          error.oneSpecialCharacter
                            ? { height: "18px", width: "18px", color: "green" }
                            : { height: "18px", width: "18px", color: "gray" }
                        }
                      />
                      <Typography sx={{ fontSize: "14px", color: "#7F7D83" }}>
                        one special character
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 1,
                        gap: 1,
                      }}
                    >
                      <IoCheckmarkCircle
                        style={
                          error.minLength8
                            ? { height: "18px", width: "18px", color: "green" }
                            : { height: "18px", width: "18px", color: "gray" }
                        }
                      />
                      <Typography sx={{ fontSize: "14px", color: "#7F7D83" }}>
                        8 character minimum
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 1,
                        gap: 1,
                      }}
                    >
                      <IoCheckmarkCircle
                        style={
                          error.oneNumber
                            ? { height: "18px", width: "18px", color: "green" }
                            : { height: "18px", width: "18px", color: "gray" }
                        }
                      />
                      <Typography sx={{ fontSize: "14px", color: "#7F7D83" }}>
                        one number
                      </Typography>
                    </Box>
                    <Box mt={3}>
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          backgroundColor: "#3BA5B9",
                          width: "100%",
                          mt: 2,
                          color: "#fff",
                          fontSize: "16px",
                          fontWeight: 600,
                          textTransform: "capitalize",
                          "&:hover": {
                            backgroundColor: "#3BA5B9",
                          },
                        }}
                        onClick={(e) => {
                          handleUpdatePass(e);
                        }}
                      >
                        Sign in
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          backgroundColor: "#fff",
                          width: "100%",
                          mt: 2,
                          color: "#3BA5B9",
                          fontSize: "14px",
                          fontWeight: 600,
                          textTransform: "capitalize",
                          display: "flex",
                          gap: 2,
                          boxShadow: 0,
                          border: "1px solid #D0D5DD",
                          borderRadius: "4px",
                          "&:hover": {
                            backgroundColor: "#fff",
                          },
                        }}
                        onClick={() => navigate("/")}
                      >
                        Back to login
                      </Button>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "360px",
                  }}
                >
                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      sx={{
                        fontSize: { xs: "26px", md: "30px" },
                        fontWeight: 600,
                      }}
                    >
                      Reset complete!
                    </Typography>
                    <Typography fontSize={{ xs: "13px", md: "14px" }}>
                      All done! We have sent an email to a*******@example.com to
                      confirm{" "}
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      backgroundColor: "#fff",
                      width: "100%",
                      mt: 2,
                      color: "#3BA5B9",
                      fontSize: "14px",
                      fontWeight: 600,
                      textTransform: "capitalize",
                      display: "flex",
                      gap: 2,
                      boxShadow: 0,
                      "&:hover": {
                        backgroundColor: "#fff",
                        boxShadow: 0,
                      },
                    }}
                    onClick={() => navigate("/")}
                  >
                    Back to login
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <img
              src={Brand}
              alt=""
              style={{ height: "100vh", width: "100%" }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PasswordUpdate;
