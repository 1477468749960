import React from "react";
import APPCONSTANTS from "../../config/constant";
import { setLogin, setLogout } from "../../state";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import Commonservices from "../../service/Commonservices";
import mysqlservice from "../../service/mySqlservice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Googlelogin = () => {
  const G_CLIENT_ID = APPCONSTANTS.G_CLIENT_ID;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const parseJwt = (token) => {
    try {
      const user = token.profileObj;
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace("-", "+").replace("_", "/");
      return JSON.parse(window.atob(base64));
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  const clearSession = () => {
    localStorage.removeItem("authUser");
    dispatch(setLogout());
  };

  const handleGoogleLogin = async (val) => {
    try {
      Commonservices.loaderON(dispatch);
      let req = {};
      req.email = val.email;
      let apiresult = await mysqlservice.post("oauth_login", req);
      if (apiresult && apiresult.status === 200) {
        toast.success("Logged in Successfully");
        dispatch(
          setLogin({
            user: apiresult.data,
            token: apiresult.data.email,
          })
        );
        navigate("/home");
      } else {
        toast.error(apiresult.message);
      }
      Commonservices.loaderOFF(dispatch);
    } catch (error) {
      console.log(error);
      Commonservices.loaderOFF(dispatch);
    }
  };
  return (
    <GoogleOAuthProvider clientId={G_CLIENT_ID}>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          let parsedItems = parseJwt(credentialResponse.credential);
          if (parsedItems) {
            clearSession();
            handleGoogleLogin(parsedItems);
          } else {
            console.log("Login Failed");
          }
        }}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </GoogleOAuthProvider>
  );
};

export default Googlelogin;
